/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const QUICKSETTINGS_REFRESH_ID = "quickSettingsRefreshTimer";
export const CLEARPAGE_ID = "clearPageTimer";
export const WORKSHEET_DRAWDAY_ID = "worksheetDrawDayTimer";
export const WORKSHEET_FINISHDRAW_ID = "worksheetFinishDrawTimer";
export const WORKSHEET_TODAY_CHECK_ID = "worksheetTodayCheckTimer";
export const RECALCULATE_TOTALS_ID = "recalculateTotalsTimer";
export const LISTEN_OUTSIDE_DELAY_ID = "listenOutsideDelayTimer";
export const SPINNER_REPEAT_ID = "spinnerRepeatTimer";
export const ANIM_DEBOUNCE_ID = "animDebounceTimer";

export const AUTOREPEAT_RATE = 700;
export const WORKSHEET_TODAY_CHECK_MS = 60000;
export const RECALCULATETOTALS_DELAY = 600;
export const LISTENOUTSIDE_DELAY = 1000;

export const QUICKSETTINGS_REFRESH_DELAY = 600;

export const SPINNERREPEAT_RATE = 300;
export const SPINNERREPEAT_ACCEL = 1.04;

export const ANIM_DEBOUNCE_MS = 300;

export const WEBDAV_SYNC_STEP_DELAY_MS = 250;
