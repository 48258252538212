/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";

export const TURNOVER = "Turnover";
export const PROFIT = "Profit";
export const LOSS = "Loss";
export const EXPENDITURE = "Money out";
export const INCOME = "Money in";
export const HOURSWORKED = "Time (hours:mins)";
export const DAYSWORKED = "Time (days)";
export const TOTAL = "Total";
export const SUFFIX_TOTALS = " totals";
export const DAY = "day";
export const CLIENTID_PREFIX = "C";
export const JOBID_PREFIX = "J";
export const CLIENT_UI = "client";
export const JOB_UI = "job";
export const DAYS = "days";
export const CLIENTS = "clients";
export const JOBS = "jobs";
export const PREFS = "prefs";
export const CLIENTSORJOBS_LASTUSED = "isLastUsed";
export const CLIENTSORJOBS_USED = "used";
export const CLIENT_SELECT_PLACEHOLDER = "select client";
export const JOB_SELECT_PLACEHOLDER = "select job";
export const JOBNOTES_PLACEHOLDER = "enter job notes";
export const MONEYNOTES_PLACEHOLDER = "enter money notes";
export const SEPARATOR_CASH = ".";
export const SEPARATOR_TIME = ":";
export const TODAY = "today";
export const WEEK_ENDING = "w/e ";

export const REPLACE = "replace";
export const MERGE = "merge";

export const CLOSE = "Close";

export const HELP_DEMO_PREFIX = "Watch: ";

export const HELPITEMS_HIDDEN = "helpItemsHidden";

export const YEAR_STARTDATE = "Year start date ";

export const REPORT_STARTDATE = "From ";
export const REPORT_ENDDATE = "To ";
export const REPORT_STARTDATE_HEADING = "Report start:";
export const REPORT_ENDDATE_HEADING = "Report end:";
export const REPORT_NORESULTS =
  "No matches - try different dates, jobs/clients, or entry types";
export const REPORT_BADDATES =
  "<em>From</em> date can't be after <em>To</em> date!";
export const REPORT_NOENTRIES =
  "Nothing has been added to the worksheet yet. Add some entries to the worksheet before creating reports.";
export const REPORT_ALLCLIENTS = "All clients, ";
export const REPORT_ALLJOBS = "All jobs, ";

export const STORAGE_UNSUPPORTED =
  "Sorry &mdash; storage is not supported on this device or browser";
export const LOADFILE_CONFIRM = {
  title: "Choose which items to merge or replace",
  mergeInfo:
    "<ul><li><strong>Keep</strong> current data</li><li><strong>Add</strong> file contents</li></ul>",
  replaceInfo:
    "<ul><li><strong>Delete</strong> current data</li><li><strong>Replace with</strong> file contents</li>",
  buttonImport: "Import File",
  buttonCancel: "Cancel",
};

export const DELETEDATA_CONFIRM = {
  title: "Choose which items to delete",
  buttonConfirm: "DELETE",
  buttonCancel: "Cancel",
};

export const DELETE_UNUSED = {
  title: "Delete unused?",
  message:
    "All $CONTENTTYPE which aren't used in the worksheet will be deleted",
  buttonDelete: "Delete",
  buttonCancel: "Cancel",
};

export const IMPORT_BADFILE = {
  title: "Bad file",
  message:
    "The file can't be read. It may be corrupt, or the wrong type of file.",
  buttonClose: "Close",
};

export const WEBDAV_SYNC_POPOVER_TITLE = "Syncing with WebDAV...";

export const PAD_DOUBLEDIGIT = "00";
export const PAD_LONGSPACEDNUM = "    ";

export const STORAGE_OBJECTPREFIX = "_";

// `\r\n` acts as line break when combined with CSS `white-space: pre-line`
//export const LINEBREAK_REPLACEMENT = "\r\n";

export const NUMBERS_EMPTY = "00:00";

export const DELETE_JOBORCLIENT_CONFIRM =
  "This $ITEM_TYPE is used $USED_COUNT time$PLURAL in the current worksheet, really delete it?";

export const PARTIAL_WEEK_NEWYEAR =
  "<em>Week&nbsp;continued from&nbsp;$YEAR</em>";
export const PARTIAL_WEEK_OLDYEAR =
  "<em>Week&nbsp;continues into&nbsp;$YEAR</em>";

export const ADDITEMBTN_LABEL = "Add an entry to this day";

export const WEEKTOTALS_HEADING = "Week $WEEKNUM, $WEEKYEAR totals";

export const REPORT_DATERANGE_CHOOSER_HEADING = "Date range";
export const REPORT_JOBCLIENT_CHOOSER_HEADING = "Job/client";
export const REPORT_TIMEMONEY_CHECKBOXES_HEADING = "Entry types";
export const REPORT_SHOW_TIME = "Hours/time";
export const REPORT_SHOW_MONEY = "Money in/out";

export const WORKSHEET_WEEK = "Worksheet: Week";
export const WORKSHEET_MONTH = "Worksheet: Month";
export const WORKSHEET_YEAR = "Worksheet: Year";
export const WORKSHEETHEADER_WEEK = "$WEEKNUM of $WEEKYEAR";
export const WORKSHEETHEADER_MONTH = "$MONTH, $YEAR";
export const WORKSHEETHEADER_YEAR = "$YEAR";
export const WORKSHEETHEADER_YEAR_SPAN = "$YEAR1&ndash;$YEAR2";
export const WORKSHEETSUMMARY_WEEK =
  "Worksheet summary: Week $WEEKNUM of $WEEKYEAR";
export const WORKSHEETSUMMARY_MONTH =
  "Worksheet summary: Month of $MONTH, $YEAR";
export const WORKSHEETSUMMARY_YEAR =
  "Worksheet summary: Year $YEAR<br>$STARTDATE&ndash;$ENDDATE";
export const WORKSHEETSUMMARY_YEAR_SPAN =
  "Worksheet summary: Year $YEAR1&ndash;$YEAR2";
export const STARTDATE_TO_ENDDATE = "$STARTDATE&ndash;$ENDDATE";

export const JSON_NOTES_HEADING = "ABOUT_THIS_FILE";

export const INLINE_ICON_DELIM_BEGIN = "[[";
export const INLINE_ICON_DELIM_END = "]]";
export const NOT_INKMODE =
  "body:not(." + CLASSNAMES.COLORSCHEME_INK_MODE + ") ";

export const HELP_DONT_SHOW_AGAIN = "Don't show this again";

export const EVERYTHING = "Everything";
export const EVERYTHING_DESCRIPTION =
  "<ul><li><strong>Delete all of your data</strong></li></ul>";
export const ENTRIES_ONLY = "Entries only";
export const ENTRIES_ONLY_DESCRIPTION =
  "<ul><li><strong>Keep clients, jobs and preferences</strong></li><li>Only delete day entries</li></ul>";

export const HELPITEM_LINK_TO_GUIDE =
  "Click the bird (or <a href='/#guide'>click here</a>) to view the guide";

export const HELPDEMO_ADD_MONEY_ENTRY = "Add a money entry";
export const HELPDEMO_ADD_TIME_ENTRY = "Add a time entry";
export const HELPDEMO_CREATE_REPORT = "Create a report";
export const HELPDEMO_ADD_JOBCLIENT = "Add a job or client";
