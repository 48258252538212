/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Collection of functions for creating HTML elements
---------------------------------------------------------
*/

import * as STRINGS from "./DDD/STRINGS.js";

import {Button} from "./Button.js";
import {CheckboxGroup} from "./CheckboxGroup.js";
import {ColorPicker} from "./ColorPicker.js";
import {DatePicker} from "./DatePicker.js";
import {DDD} from "./DDD/CONST.js";
import {Form} from "./Form.js";
import {HelpItem} from "./HelpItem.js";
import {Popover} from "./Popover.js";
import {LinkingLines} from "./LinkingLines.js";
import {List} from "./List.js";
import {NumberInput} from "./NumberInput.js";
import {RadioButtonGroup} from "./RadioButtonGroup.js";
import {Select} from "./Select.js";
import {Storage} from "./Storage.js";

import {
  createElementWithId,
  getElementFromElementOrID,
  getFunctionFromString,
  registerEventHandler,
  __
} from "./utils.js";

export var fillHTMLFromOb = function (_ob) {
  var prop;
  for (prop in _ob) {
    if (document.getElementById(prop)) {
      document.getElementById(prop).innerHTML = _ob[prop];
    }
  }
};

export var createBasicElementFromOb = function (_ob) {
  var el,
    elType,
    parent_el = getElementFromElementOrID(_ob.parent);

  switch (_ob.type) {
    case DDD.GUI_TYPES.COL:
    case DDD.GUI_TYPES.ROW:
    case DDD.GUI_TYPES.SPAN:
      elType = "span";
      break;
    case DDD.GUI_TYPES.H1:
      elType = "h1";
      break;
    case DDD.GUI_TYPES.H2:
      elType = "h2";
      break;
    case DDD.GUI_TYPES.H3:
      elType = "h3";
      break;
    case DDD.GUI_TYPES.H4:
      elType = "h4";
      break;
    case DDD.GUI_TYPES.PARA:
      elType = "p";
      break;
    case DDD.GUI_TYPES.SECTION:
      elType = "section";
      break;
    case DDD.GUI_TYPES.HEADING:
      elType = "h" + _ob.heirarchy;
      break;
    case DDD.GUI_TYPES.VIDEO:
      elType = "video";
      break;
    case DDD.GUI_TYPES.SPACER:
      elType = "hr";
      break;
    case DDD.GUI_TYPES.TABLE:
      elType = "table";
      break;
    case DDD.GUI_TYPES.CHECKBOX:
      elType = "input";
      break;
    case DDD.GUI_TYPES.FILEINPUT:
      elType = "input";
      break;
    default:
      elType = "div";
      break;
  }

  if (_ob.id) {
    el = createElementWithId(elType, _ob.id);
  } else {
    el = document.createElement(elType);
  }
  addClasses(el, _ob.class);
  if (_ob.text) {
    el.innerHTML = _ob.text;
  }

  parent_el.appendChild(el);
  return el;
};

// drawGUIFromAr pre-fills fields if necessary
export var drawGUIFromAr = function (_ar) {
	//__("_ar: " + JSON.stringify(_ar));
  var i, j, ob, eventToAdd_ob, tmp_el, parent_el, label_el, span_el, input_el;
  for (i = 0; i < _ar.length; i++) {
    ob = _ar[i];
    //__("ob: " + JSON.stringify(ob));
    switch (ob.type) {
      case DDD.GUI_TYPES.BTN:
        tmp_el = new Button();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.FORM:
        tmp_el = new Form();
        tmp_el.init(ob);
        addClasses(tmp_el, ob.class);
        break;
      case DDD.GUI_TYPES.TEXTINPUT:
        parent_el = getElementFromElementOrID(ob.parent);
        tmp_el = document.createElement("input");
        tmp_el.type = "text";
        parent_el.appendChild(tmp_el);
        if (ob.id) {
          tmp_el.id = ob.id;
        }
        if (Storage.getPref(ob.id)) {
          tmp_el.value = Storage.getPref(ob.id);
        }
        break;
      case DDD.GUI_TYPES.FILEINPUT:
        tmp_el = createBasicElementFromOb(ob);
        tmp_el.ob = ob;
        tmp_el.type = "file";
        tmp_el.name = ob.id;
        label_el = document.createElement("label");
        tmp_el.parentNode.appendChild(label_el);
        label_el.htmlFor = ob.id;
        addClasses(label_el, ob.class);
        span_el = document.createElement("span");
        label_el.appendChild(span_el);
        label_el.id = ob.id + "-label";
        label_el.setAttribute("title", ob.label);
        span_el.textContent = ob.label;
        break;
      case DDD.GUI_TYPES.HELP_DEMO:
        tmp_el = new Button();
        ob.class = [DDD.CLASSNAMES.BTNNAV, DDD.CLASSNAMES.HELPDEMO_BTN];
        ob.label = STRINGS.HELP_DEMO_PREFIX + ob.label;
        tmp_el.init(ob);
        tmp_el.setAttribute('src', ob.source);
        registerEventHandler(tmp_el, "click", function () {
          Popover.show({
            title: this.ob.label.substr(STRINGS.HELP_DEMO_PREFIX.length),
            button_ar: [
              {
                label: STRINGS.CLOSE,
                func: Popover.hide
              }
            ],
            helpDemo: {
              source: this.ob.source
            }
          });
        });
        break;
      case DDD.GUI_TYPES.VIDEO:
        tmp_el = createBasicElementFromOb(ob);
        tmp_el.ob = ob;
        tmp_el.setAttribute("type", "video/mp4");
        tmp_el.setAttribute("src", ob.source);
        tmp_el.setAttribute("controls", "");

        input_el = document.createElement("input");
        input_el.setAttribute("type", "checkbox");
        tmp_el.parentNode.appendChild(input_el);
        input_el.textContent = ob.caption;
        break;
      case DDD.GUI_TYPES.NUMBER_INPUT:
        tmp_el = new NumberInput();
        tmp_el.init(ob);
        if (Storage.getPref(ob.id)) {
          tmp_el.value = Storage.getPref(ob.id);
        }
        break;
      case DDD.GUI_TYPES.CHECKBOX:
        label_el = document.createElement("label");
        label_el.htmlFor = ob.id;
        label_el.innerHTML = ob.label;
        label_el.id = ob.id + "-label";
        if (ob.class) {
          // the label acts as the button in this instance
          label_el.classList.add(ob.class);
          // so don't apply class to checkbox
          ob.class = undefined;
        }
        tmp_el = createBasicElementFromOb(ob);
        tmp_el.ob = ob;
        tmp_el.type = "checkbox";
        if (Storage.getPref(ob.id)) {
          tmp_el.checked = Storage.getPref(ob.id);
        } else if (ob.checked) {
          tmp_el.checked = ob.checked;
        }
        __(
          "drawGUIFromAr::Storage.getPref('" +
            ob.id +
            "')" +
            Storage.getPref(ob.id),
          DDD.LOG_FORMAT.DOM
        );
        tmp_el.name = ob.id;
        tmp_el.parentNode.appendChild(label_el);
        break;
      case DDD.GUI_TYPES.DATEPICKER:
        tmp_el = new DatePicker();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.LINKINGLINES:
        tmp_el = new LinkingLines();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.SELECT:
        tmp_el = new Select();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.CLIENTCHOOSER:
        tmp_el = new Select();
        tmp_el.init({
          contentType: DDD.TYPES.CONTENT_CLIENTS,
          placeholderText: DDD.STRINGS.CLIENT_SELECT_PLACEHOLDER,
          options: Storage.getObj(DDD.TYPES.CONTENT_CLIENTS),
          parent: ob.parent
        });
        break;
      case DDD.GUI_TYPES.JOBCHOOSER:
        tmp_el = new Select();
        tmp_el.init({
          contentType: DDD.TYPES.CONTENT_JOBS,
          placeholderText: DDD.STRINGS.JOB_SELECT_PLACEHOLDER,
          options: Storage.getObj(DDD.TYPES.CONTENT_JOBS),
          parent: ob.parent
        });
        break;
      case DDD.GUI_TYPES.RADIOBTN:
        ob.checkIfMatched = Storage.getPref(ob.id);
        tmp_el = new RadioButtonGroup();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.CHECKBOXGROUP:
        ob.values = Storage.getPref(ob.id);
        tmp_el = new CheckboxGroup();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.UL:
        tmp_el = new List();
        ob.transformationFunction = replaceInlineIcons;
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.PARA: // intentional rollthrough
      case DDD.GUI_TYPES.SECTION: // intentional rollthrough
      case DDD.GUI_TYPES.COL: // intentional rollthrough
      case DDD.GUI_TYPES.ROW: // intentional rollthrough
      case DDD.GUI_TYPES.HEADING: // intentional rollthrough
      case DDD.GUI_TYPES.SPAN:
      case DDD.GUI_TYPES.SPACER:
      case DDD.GUI_TYPES.TABLE:
      case DDD.GUI_TYPES.H1:
      case DDD.GUI_TYPES.H2:
      case DDD.GUI_TYPES.H3:
      case DDD.GUI_TYPES.H4:
        tmp_el = createBasicElementFromOb(ob);
        break;
      case DDD.GUI_TYPES.COLORPICKER:
        tmp_el = new ColorPicker();
        tmp_el.init(ob);
        break;
      case DDD.GUI_TYPES.METHODCALL:
        callMethodFromOb(ob);
        break;
      case DDD.GUI_TYPES.HELP:
        tmp_el = new HelpItem();
        tmp_el.init(ob);
        break;
      default:
        break;
    }

    if (ob.event_ar) {
      // contains an array of objects
      for (j = 0; j < ob.event_ar.length; j++) {
        eventToAdd_ob = ob.event_ar[j];
        if (eventToAdd_ob.eventType) {
          registerEventHandler(
            tmp_el,
            eventToAdd_ob.eventType,
            callMethodsFromObOnElement
          );
        }
      }
    }
  }
};

export var replaceInlineIcons = function (_str) {
  var i = 0,
    stringStart,
    stringEnd,
    matchString,
    stripped,
    delimBeginLen = DDD.STRINGS.INLINE_ICON_DELIM_BEGIN.length,
    delimEndLen = DDD.STRINGS.INLINE_ICON_DELIM_END.length;

  // find beginning delimiters of replaceable strings
  while (i !== -1) {
    i = _str.indexOf(DDD.STRINGS.INLINE_ICON_DELIM_BEGIN, i);
    if (i !== -1) {
      stringStart = i;
      i = _str.indexOf(DDD.STRINGS.INLINE_ICON_DELIM_END, i);
      if (i !== -1) {
        stringEnd = i + delimEndLen;
        matchString = _str.substr(stringStart, stringEnd - stringStart);
        stripped = matchString.substr(
          delimBeginLen,
          matchString.length - (delimBeginLen + delimEndLen)
        );
        _str = _str.replace(
          matchString,
          "<span class='" + DDD.CLASSNAMES[stripped] + "'></span>"
        );
        i++;
      }
    }
  }
  return _str;
};

// attaches an `event_ar` defining event handlers to an element
// and sets up a generic handler `callMethodsFromObOnElement` for each element
// the handler, when called will traverse the array looking for matching events
export var attachEventArrayToElement = function (_el, _eventData_ob) {
  var i;
  if (!_el.ob) {
    _el.ob = {};
  }
  if (!_el.ob.event_ar) {
    _el.ob.event_ar = [];
  }
  if (_eventData_ob && _eventData_ob.scopeID) {
    _el.ob.scopeID = _eventData_ob.scopeID;
  } else if (_eventData_ob && _eventData_ob.scope) {
    _el.ob.scope = _eventData_ob.scope;
  }

  for (i = 0; i < _eventData_ob.event_ar.length; i++) {
    _el.ob.event_ar.push({
      eventType: _eventData_ob.event_ar[i],
      methodPathStr: _eventData_ob.methodPathStr,
      args: _eventData_ob.args,
      scope: _eventData_ob.scope,
      scopeID: _eventData_ob.scopeID
    });
    if (_eventData_ob.cancelable) {
      registerEventHandler(
        _el,
        _eventData_ob.event_ar[i],
        callMethodsFromObOnElement,
        true
      );
    } else {
      registerEventHandler(_el, _eventData_ob.event_ar[i], callMethodsFromObOnElement);
    }
  }
};

// calls all methods of a certain type (eg. click) which have been
// attached to an element previously via its ob.event_ar
// bubbles up through parents if 'ob' data object isn't found
export var callMethodsFromObOnElement = function (_event) {
  var i,
    event_ar,
    el = _event.target;

  // make sure this loop cant get infinite
  while (!el.ob && el !== document.body) {
    el = el.parentNode;
  }

  event_ar = el.ob.event_ar;
  if (event_ar) {
    for (i = 0; i < event_ar.length; i++) {
      // call events fron `event_ar` matching whichever event triggered this function
      if (event_ar[i].eventType === _event.type) {
        callMethodFromOb(event_ar[i], _event);
      }
    }
  }
};

// calls a method based on a data object which defines
// the method name, scope and other arguments
// (with optional event passthrough to the called method)
export var callMethodFromOb = function (_event_ob, _event) {
  var scope;
  //console.group("callMethodFromOb()");
  //__("_event_ob: " + JSON.stringify(_event_ob), DDD.LOG_FORMAT.DOM);
  //__("_event: " + _event, DDD.LOG_FORMAT.DOM);
  if (_event_ob && _event_ob.scopeID) {
    // _event_ob.scope is an ID string
    scope = document.getElementById(_event_ob.scopeID);
  } else if (_event_ob && _event_ob.scope) {
    // _event_ob.scope is an element
    scope = _event_ob.scope;
  } else {
    scope = window;
  }

  if (_event_ob.eventType) {
    if (_event_ob.args) {
      _event_ob.args.push(_event);
    } else {
      _event_ob.args = [_event];
    }
  }
  getFunctionFromString(_event_ob.methodPathStr).apply(scope, _event_ob.args);
  //console.groupEnd();
};

export var addClasses = function (_el, _classes) {
  if (_classes) {
    if (Array.isArray(_classes)) {
      _el.classList.add(..._classes);
    } else {
      _el.classList.add(_classes);
    }
  }
};
