/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  List:
  Custom `ul` element, pre-filled with data

---------------------------------------------------------
*/

import {__, getElementFromElementOrID} from "./utils.js";

class List extends HTMLElement {
  init(_ob) {
    var i,
      li_el,
      heading_el,
      parent_el = getElementFromElementOrID(_ob.parent);

    if (_ob.id) {
      this.id = _ob.id;
    }
    if (_ob.class) {
      this.classList.add(_ob.class);
    }

    if (_ob.heading) {
      heading_el = document.createElement("h4");
      if (_ob.transformationFunction) {
        _ob.heading = _ob.transformationFunction(_ob.heading);
      }
      heading_el.innerHTML = _ob.heading;
      this.appendChild(heading_el);
    }

    if (_ob.ar) {
      for (i = 0; i < _ob.ar.length; i++) {
        li_el = document.createElement("li");
        if (_ob.transformationFunction) {
          _ob.ar[i] = _ob.transformationFunction(_ob.ar[i]);
        }
        li_el.innerHTML = _ob.ar[i];
        this.appendChild(li_el);
        if (_ob.class) {
          li_el.classList.add(_ob.class);
        }
      }
    }

    parent_el.appendChild(this);
  }
}

customElements.define("fg-list", List);
export {List};
