/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Page:
  Manage pages (eg 'worksheets', 'settings' etc)

---------------------------------------------------------
*/

import {ColorPicker} from "./ColorPicker.js";
import {ColorScheme} from "./ColorScheme.js";
import {DDD} from "./DDD/CONST.js";
import {drawGUIFromAr} from "./DOM.js";
import {Elements} from "./Elements.js";
import {LinkingLines} from "./LinkingLines.js";
import {Storage} from "./Storage.js";
import {Timers} from "./Timers.js";
import {manualEvent, __} from "./utils.js";
import {Worksheet} from "./Worksheet.js";

class Page {}

Page.select = function (_pagetype) {
	Storage.setPref(DDD.PREF_IDS.PAGETYPE, _pagetype);
	location.hash = _pagetype;
	Page.refresh();
};

Page.refresh = function () {
	manualEvent(document, "pageLoadStarted");
	Page.clear();
	Timers.clearByID(DDD.TIMERS.CLEARPAGE_ID);
	Timers.setByID(DDD.TIMERS.CLEARPAGE_ID, Page.draw, 0); // HACK on timer to force reflow before draw
};

Page.clear = function () {
	Timers.clearByID(DDD.TIMERS.BTNEVENT_AUTOREPEAT_ID);
	Timers.clearByID(DDD.TIMERS.WORKSHEET_DRAWDAY_ID);
	Timers.clearByID(DDD.TIMERS.RECALCULATE_TOTALS_ID);

	document.body.className = "";
	Elements.mainContainer.innerHTML = "";

	ColorPicker.dispose();
	LinkingLines.dispose();
	Worksheet.dispose();
};

Page.draw = function () {
	switch (Storage.getPref(DDD.PREF_IDS.PAGETYPE)) {
		case DDD.PAGETYPES.GUIDE:
			document.body.className = DDD.CLASSNAMES.BODY_GUIDE;
			drawGUIFromAr(DDD.GUIDATA_GUIDE);
			manualEvent(document, "pageLoaded");
			break;
		case DDD.PAGETYPES.WORKSHEET:
			document.body.className = DDD.CLASSNAMES.BODY_WORKSHEET;
			drawGUIFromAr(DDD.GUIDATA_WORKSHEET);
      // don't fire a pageLoaded event here because the page does its own loading
      // and will fire it when ready
			break;
		case DDD.PAGETYPES.REPORTS:
			document.body.className = DDD.CLASSNAMES.BODY_REPORTS;
			drawGUIFromAr(DDD.GUIDATA_REPORTS);
			manualEvent(document, "pageLoaded");
			break;
		case DDD.PAGETYPES.CONFIG:
			document.body.className = DDD.CLASSNAMES.BODY_CONFIG;
			__(DDD.GUIDATA_CONFIG);
			drawGUIFromAr(DDD.GUIDATA_CONFIG);
			manualEvent(document, "pageLoaded");
			break;
		case DDD.PAGETYPES.JOBSANDCLIENTS:
			document.body.className = DDD.CLASSNAMES.BODY_JOBSANDCLIENTS;
			drawGUIFromAr(DDD.GUIDATA_JOBSANDCLIENTS);
			manualEvent(document, "pageLoaded");
			break;
		default:
			break;
	}

  ColorScheme.setInkModeClass();
};

export {Page};
