/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Custom `select` element

---------------------------------------------------------
*/

import {debug, getElementFromElementOrID, manualEvent} from "./utils.js";

import {Options} from "./Options.js";

import {DDD} from "./DDD/CONST.js";

class Select extends HTMLElement {
  init(_ob) {
    var id, label_el, clientOrJob_ob, parent_el;

    this._options_ = new Options();
    this.isOpen = false;
    this.appendChild(this._options_);

    parent_el = getElementFromElementOrID(_ob.parent);

    this.setAttribute("tabindex", 0);

    if (_ob.id) {
      if (_ob.label) {
        label_el = document.createElement("label");
        label_el.innerHTML = _ob.label;
        parent_el.appendChild(label_el);
        label_el.htmlFor = _ob.id;
      }
      this.name = _ob.id;
      this.id = _ob.id;
    }
    if (_ob.class) {
      this.classList.add(_ob.class);
    }
    parent_el.appendChild(this);
    if (_ob.placeholderText) {
      this._options_.add({
        options: this._options_,
        name: _ob.placeholderText,
        disabled: true,
        selected: true,
        hidden: true
      });
    }

    if (_ob.contentType) {
      // clients / jobs options get treated differently to normal options
      if (_ob.contentType === DDD.TYPES.CONTENT_CLIENTS) {
        this.classList.add(DDD.CLASSNAMES.CLIENTSELECT);
      } else if (_ob.contentType === DDD.TYPES.CONTENT_JOBS) {
        this.classList.add(DDD.CLASSNAMES.JOBSELECT);
      }
      this.contentType = _ob.contentType;
      for (id in _ob.options) {
        clientOrJob_ob = _ob.options[id];
        this._options_.add({
          options: this._options_,
          name: clientOrJob_ob.name,
          color: clientOrJob_ob.color,
          bgcolor: clientOrJob_ob.bgcolor,
          id: id,
          value: id,
          used: clientOrJob_ob.used
        });
      }
    } else {
      // normal options
      for (id in _ob.options) {
        this._options_.add({
          options: this._options_,
          name: _ob.options[id].name,
          id: _ob.options[id].id,
          value: _ob.options[id].value
        });
      }
    }
  }

  onFocus() {
    debug(this.parentNode.id + " Select::onFocus");
    this.open();
  }

  onBlur() {
    debug(this.parentNode.id + " Select::onBlur");
    manualEvent(this, "change");
    this.close();
  }

  // Used to go back to previous option when 'ESC' is pressed
  revert() {
    if (this.selectedWhenOpened) {
      this._options_.select(this.selectedWhenOpened);
      this.value = this._options_.selected.id;
    }
  }

  selectNext() {
    this._options_.selectNext();
    this.value = this._options_.selected.id;
  }

  selectPrevious() {
    this._options_.selectPrevious();
    this.value = this._options_.selected.id;
  }

  onArrowUp() {
    this.selectPrevious();
  }

  onArrowDown() {
    this.selectNext();
  }

  open() {
    debug("Select::open");
    if (!this.isOpen) {
      this.selectedWhenOpened = this._options_.selected;
      this.isOpen = true;
      this.setAttribute("state", "open");
    }
  }

  close() {
    debug("Select::close");
    if (this.isOpen) {
      this.isOpen = false;
      this.removeAttribute("state");
    }
  }

  onClick(_event) {
    debug("Select::onClick");
    debug("this " + this.nodeName);
    debug("target " + _event.target.nodeName);
    if (_event.target.nodeName === "FG-OPTION") {
      if (_event.target !== this._options_.selected) {
        this._options_.select(_event.target);
        this.value = this._options_.selected && this._options_.selected.id;
        if (this.value) {
          if (this.contentType === DDD.TYPES.CONTENT_CLIENTS) {
            this.className = "";
            this.classList.add(DDD.CLASSNAMES.CLIENTSELECT);
            this.classList.add(this.value);
          } else if (this.contentType === DDD.TYPES.CONTENT_JOBS) {
            this.className = "";
            this.classList.add(DDD.CLASSNAMES.JOBSELECT);
            this.classList.add(this.value);
          }
        }
        manualEvent(this, "change");
      }
      this.blur();
      _event.preventDefault();
      _event.stopPropagation();
    }
  }

  get options() {
    return this._options_.ar;
  }
  set options(_ar) {
    this._options_.ar = _ar;
  }
  get selectedIndex() {
    return this._options_.selectedIndex;
  }
  set selectedIndex(_n) {
    this._options_.selectedIndex = _n;
    this.value = this._options_.selected.id;
  }
}

customElements.define("fg-select", Select);
export {Select};
