/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";
import * as EL_IDS from "./EL_IDS.js";
import * as GUI_TYPES from "./GUI_TYPES.js";
import * as HELPITEM_IDS from "./HELPITEM_IDS.js";
import * as LABELS from "./LABELS.js";
import * as PAGETYPES from "./PAGETYPES.js";
import * as PREF_IDS from "./PREF_IDS.js";

import {HELPITEM_DATA} from "./HELPITEM_DATA.js";
import {PREF_OPTS} from "./PREF_OPTS.js";

export const GUIDATA_WORKSHEET = [
  {
    type: GUI_TYPES.FORM,
    id: "nav-worksheet",
    class: CLASSNAMES.HIDDEN,
    parent: "main"
  },
  {
    type: GUI_TYPES.BTN,
    id: EL_IDS.WEEKPREVBTN,
    class: CLASSNAMES.BTNNAV,
    label: LABELS.WEEKPREV,
    event_ar: [
      {
        eventType: "mousedown",
        methodPathStr: "DontDillyDally.WorksheetNav.jumpWeekPrev"
      }
    ],
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.PARA,
    text: "Week",
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.BTN,
    id: EL_IDS.WEEKNEXTBTN,
    class: CLASSNAMES.BTNNAV,
    label: LABELS.WEEKNEXT,
    event_ar: [
      {
        eventType: "mousedown",
        methodPathStr: "DontDillyDally.WorksheetNav.jumpWeekNext"
      }
    ],
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.BTN,
    id: EL_IDS.MONTHPREVBTN,
    class: CLASSNAMES.BTNNAV,
    label: LABELS.MONTHPREV,
    event_ar: [
      {
        eventType: "mousedown",
        methodPathStr: "DontDillyDally.WorksheetNav.jumpMonthPrev"
      }
    ],
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.PARA,
    text: "Month",
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.BTN,
    id: EL_IDS.MONTHNEXTBTN,
    class: CLASSNAMES.BTNNAV,
    label: LABELS.MONTHNEXT,
    event_ar: [
      {
        eventType: "mousedown",
        methodPathStr: "DontDillyDally.WorksheetNav.jumpMonthNext"
      }
    ],
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.PARA,
    text: "Today",
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.BTN,
    id: EL_IDS.TODAYJUMPBTN,
    class: CLASSNAMES.BTNNAV,
    label: LABELS.TODAYJUMPBTN,
    event_ar: [
      {
        eventType: "mousedown",
        methodPathStr: "DontDillyDally.WorksheetNav.todayClick"
      }
    ],
    parent: "nav-worksheet"
  },
  {
    type: GUI_TYPES.UL,
    id: EL_IDS.WORKSHEETCONTAINER,
    class: CLASSNAMES.SHEET,
    parent: "main"
  },
  {
    type: GUI_TYPES.FORM,
    id: EL_IDS.WORKSHEETFOOTER,
    parent: "main"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.TIMESPAN,
    label: PREF_OPTS.INTROS[PREF_IDS.TIMESPAN],
    parent: "worksheet-footer",
    options: PREF_OPTS[PREF_IDS.TIMESPAN]
  },
  {
    type: GUI_TYPES.SPAN,
    id: "quicksettings-group-totals",
    parent: "worksheet-footer"
  },
  {
    type: GUI_TYPES.H2,
    parent: "quicksettings-group-totals",
    text: "Show totals"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.SHOW_WEEKTOTALS,
    label: PREF_OPTS.INTROS[PREF_IDS.SHOW_WEEKTOTALS],
    parent: "quicksettings-group-totals"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.SHOW_MONTHTOTALS,
    label: PREF_OPTS.INTROS[PREF_IDS.SHOW_MONTHTOTALS],
    parent: "quicksettings-group-totals"
  },
  {
    type: GUI_TYPES.BTN,
    class: [CLASSNAMES.TEXT_BUTTON, CLASSNAMES.BTNNAV],
    label: "More Settings ",
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.navClick",
        args: [PAGETYPES.CONFIG],
        scopeID: "main"
      }
    ],
    parent: "worksheet-footer"
  },
  HELPITEM_DATA[HELPITEM_IDS.WORKSHEET_MAIN],
  {
    type: GUI_TYPES.METHODCALL,
    methodPathStr: "DontDillyDally.Worksheet.drawPage",
    scopeID: "main"
  }
];
