/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const DATA_JOB = 'dataTypeJob';
export const DATA_CLIENT = 'dataTypeClient';

export const ITEM_TIME = 'T';
export const ITEM_MONEY = 'M';
export const ITEM_DAYLENGTH = 'entryTypeDaylength';

// CONTENT_* are used as key prefixes for data storage
// eg DDD_clients, DDD_jobs
export const CONTENT_CLIENTS = 'clients';
export const CONTENT_JOBS = 'jobs';
export const CONTENT_DAYS = 'days';
export const CONTENT_PREFS = 'prefs';
export const CONTENT_HELPITEMSHIDDEN = "helpItemsHidden";

export const UNIT_BIG = 'unitTypeBig';
export const UNIT_SMALL = 'unitTypeSmall';
