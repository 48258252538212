/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as GUI_TYPES from "./GUI_TYPES.js";
import * as CLASSNAMES from "./CLASSNAMES.js";
import * as LABELS from "./LABELS.js";
import * as STRINGS from "./STRINGS.js";
import * as EL_IDS from "./EL_IDS.js";
import * as PAGETYPES from "./PAGETYPES.js";

import {HELPITEM_DATA} from "./HELPITEM_DATA.js";
import {PAGEDATA} from "./PAGEDATA.js";

export const GUIDATA_GUIDE = [
	{
		type: GUI_TYPES.COL,
		id: "guide-intro-col",
		class: [CLASSNAMES.SHEET, CLASSNAMES.ATTENTION],
		parent: "main"
	},
	{
		type: GUI_TYPES.H1,
		parent: "guide-intro-col",
		text: "Tap the bird to view this guide at any time"
	},
	{
		type: GUI_TYPES.COL,
		id: "guide-about-col",
		class: [CLASSNAMES.SHEET, CLASSNAMES.ATTENTION],
		parent: "main"
	},
	{
		type: GUI_TYPES.H1,
		parent: "guide-about-col",
		text: PAGEDATA[PAGETYPES.GUIDE].intro
	},
	{
		type: GUI_TYPES.UL,
		parent: "guide-about-col",
		ar: [
			"Worksheet fills with colour as you work (empty week shows you didn't get much done)",
			"Report time spent on a job or client (in hours, days or half-days)",
			"See money earned/spent on a job or client",
			"Calculate profits/losses"
		]
	},
	{
		type: GUI_TYPES.COL,
		id: "guide-repo-col",
		class: [CLASSNAMES.SHEET, CLASSNAMES.ATTENTION],
		parent: "main"
	},
	{
		type: GUI_TYPES.H1,
		parent: "guide-repo-col",
		text: "This is free, open-source software"
	},
	{
		type: GUI_TYPES.UL,
		parent: "guide-repo-col",
		ar: [
			"The source code is available at <a href='https://codeberg.org/mm-dev/dontdillydally'>codeberg.org/mm-dev/dontdillydally</a>"
		]
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol2",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol2",
		id: "icon-heading-worksheet",
		text: LABELS.WORKSHEET
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol2",
		heading: PAGEDATA[PAGETYPES.WORKSHEET].intro
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol2",
		heading: "Tap a date [[INLINE_ICON_ADDBNT]] to add an entry"
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol2",
		heading: "Enter hours worked [[INLINE_ICON_CLOCK]]"
	},
	{
		type: GUI_TYPES.HELP_DEMO,
		parent: "guideCol2",
		source: HELPITEM_DATA.DEMOSOURCE_ADDTIME,
		label: STRINGS.HELPDEMO_ADD_TIME_ENTRY
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol2",
		heading: "To enter <em>Money</em> instead, tap the clock",
		ar: ["[[INLINE_ICON_CLOCK]] becomes [[INLINE_ICON_MONEYPOSITIVE]]"]
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol2",
		heading: "For <em>Spent</em> money, enter a negative amount",
		ar: ["'-10.50' means you spent £10.50"]
	},
	{
		type: GUI_TYPES.HELP_DEMO,
		parent: "guideCol2",
		source: HELPITEM_DATA.DEMOSOURCE_ADDMONEY,
		label: STRINGS.HELPDEMO_ADD_MONEY_ENTRY
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol3",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		id: "icon-heading-reports",
		parent: "guideCol3",
		text: LABELS.REPORTS
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol3",
		heading: PAGEDATA[PAGETYPES.REPORTS].intro
	},
	{
		type: GUI_TYPES.HELP_DEMO,
		parent: "guideCol3",
		source: HELPITEM_DATA.DEMOSOURCE_CREATEREPORT,
		label: STRINGS.HELPDEMO_CREATE_REPORT
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol4",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol4",
		id: "icon-heading-jobs-and-clients",
		text: LABELS.JOBS_AND_CLIENTS
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol4",
		heading: PAGEDATA[PAGETYPES.JOBSANDCLIENTS].intro
	},
	{
		type: GUI_TYPES.HELP_DEMO,
		parent: "guideCol4",
		source: HELPITEM_DATA.DEMOSOURCE_ADDJOBCLIENT,
		label: STRINGS.HELPDEMO_ADD_JOBCLIENT
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol5",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol5",
		id: "icon-heading-settings",
		text: LABELS.SETTINGS
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol5",
		heading: PAGEDATA[PAGETYPES.CONFIG].intro,
		ar: [
			"Colour scheme <em>Dark mode, black and white etc</em>",
			"How dates are displayed",
			"When the year starts <em>eg the start of your tax year</em>",
			"How your working days are rounded <em>eg up to nearest full day, half day etc</em>",
			"Whether the worksheet shows a week, month, or year",
			"<em>and more...</em>"
		]
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol7",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol7",
		id: "icon-heading-minimise",
		text: LABELS.TOGGLE_MENU
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol7",
		heading: "Make the menus smaller, for a cleaner look and to save space",
		ar: ["Tap again to return to the original size"]
	},
	{
		type: GUI_TYPES.COL,
		id: "guideColSaveWarning",
		class: [CLASSNAMES.SHEET, CLASSNAMES.ATTENTION],
		parent: "main"
	},
	{
		type: GUI_TYPES.H1,
		parent: "guideColSaveWarning",
		text: "Save to a file regularly to be sure you have a copy of your data"
	},
  
  {
		type: GUI_TYPES.COL,
		id: "guideCol8",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol8",
		id: "icon-heading-sync",
		text: LABELS.SYNC_DATA
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol8",
		heading:
			"Sync to WebDAV server",
		ar: [
			"EXPERIMENTAL FEATURE",
			"FEATURE IS DISABLED UNTIL YOU FILL IN THE WEBDAV SECTION ON THE '" + LABELS.SETTINGS + "' PAGE"
    ]
	},
	
  {
		type: GUI_TYPES.COL,
		id: "guideCol9",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol9",
		id: "icon-heading-saving-your-work",
		text: LABELS.SAVE_FILE
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol9",
		heading:
			"Although your data is saved automatically, sometimes your browser may wipe it &mdash; save a file so you don't lose it",
		ar: ["The file will be saved to your usual Downloads folder"]
	},
	
  {
		type: GUI_TYPES.COL,
		id: "guideCol10",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol10",
		id: "icon-heading-import",
		text: LABELS.LOAD_FILE
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol10",
		heading: "Load a previously-saved file"
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol11",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol11",
		id: "icon-heading-mail-data",
		text: LABELS.MAIL_DATA
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol11",
		heading: "Email your data with the '" + LABELS.MAIL_DATA + "' button",
		ar: [
			"EXPERIMENTAL FEATURE",
			"ONLY WORKS IF mailto: LINKS ARE SET UP CORRECTLY ON YOUR SYSTEM",
			"BUTTON IS DISABLED UNTIL YOU SET THE EMAIL ADDRESS YOU WANT TO SEND TO ON THE '" + LABELS.SETTINGS + "' PAGE"
		]
	},
	{
		type: GUI_TYPES.COL,
		id: "guideCol12",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guideCol12",
		id: "icon-heading-wipe-data",
		text: LABELS.CLEAR_DATA
	},
	{
		type: GUI_TYPES.UL,
		parent: "guideCol12",
		heading: "Delete your data with the '" + LABELS.CLEAR_DATA + "' button",
		ar: [
			"Your data will be lost, so if you want a backup remember to save it to a file first!"
		]
	},
	{
		type: GUI_TYPES.COL,
		id: "guide-col-technical",
		class: CLASSNAMES.SHEET,
		parent: "main"
	},
	{
		type: GUI_TYPES.H2,
		parent: "guide-col-technical",
		text: "Technical information"
	},
	{
		type: GUI_TYPES.UL,
		parent: "guide-col-technical",
		ar: [
			"Data is stored using the localStorage API",
			"Files are saved as plain, unencrypted text in JSON format",
			"You can view currently stored data by using the developer tools built into eg. desktop versions of Firefox or Chrome",
			"If you clear your browser cache, depending on your settings localStorage may be cleared &mdash; in which case you'd lose any unsaved data",
			"Your data is never sent across the internet or stored on any servers",
			"No 3rd-party scripts are loaded on this site",
			"No tracking or advertising is carried out"
		]
	},
	{
		type: GUI_TYPES.LINKINGLINES,
		containerID: "main",
		idPairs_ar: [
			["icon-heading-worksheet", EL_IDS.UI_WORKSHEET],
			["icon-heading-reports", EL_IDS.UI_REPORTS],
			["icon-heading-jobs-and-clients", EL_IDS.UI_JOBSANDCLIENTS],
			["icon-heading-settings", EL_IDS.UI_CONFIG],
			["icon-heading-minimise", EL_IDS.UI_MENU_MINIMISE + "-label"],
			["icon-heading-saving-your-work", EL_IDS.UI_EXPORTFILE],
			["icon-heading-sync", EL_IDS.UI_SYNCDATA],
			["icon-heading-import", EL_IDS.UI_IMPORTFILE + "-label"],
			["icon-heading-mail-data", EL_IDS.UI_MAILDATA],
			["icon-heading-wipe-data", EL_IDS.UI_CLEARDATA]
		]
	}
];
