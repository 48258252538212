/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Form:
  Custom `form` element

---------------------------------------------------------
*/


class Form extends HTMLElement {
  init(_ob) {
    var parent_el = document.getElementById(_ob.parent);

    if (_ob.id) {
      this.id = _ob.id;
    }
    parent_el.appendChild(this);

    if (_ob.title) {
      this.innerHTML = '<h2>' + _ob.title + '</h2>';
    }
    if (_ob.hidden) {
      this.style.display = 'none';
    }

    this.ob = _ob;
  }
}

customElements.define('fg-form', Form);
export {Form};
