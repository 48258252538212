/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const MAIN_CONTAINER = 'main';
export const SIDEBAR_MAIN = 'sidebar-main';

export const COLHEADING = 'column-headings';

export const JOBNAMEIN = 'job-name-in';
export const CLIENTNAMEIN = 'client-name-in';
export const CLIENTSAVEBTN = 'save-new-client-btn';

export const SELECTCLIENT = 'select-client';
export const SELECTJOB = 'select-job';

export const JOBSAVEBTN = 'save-new-job-btn';
export const WEEKNEXTBTN = 'week-next-btn';
export const WEEKPREVBTN = 'week-prev-btn';
export const MONTHNEXTBTN = 'month-next-btn';
export const MONTHPREVBTN = 'month-prev-btn';
export const TODAYJUMPBTN = 'today-jump-btn';

export const CLIENTSEXISTING = 'clients-existing';
export const JOBSEXISTING = 'jobs-existing';

export const CONFIGFORM = 'config-form';
export const REPORTFORM = 'report-form';

export const REPORT_DATERANGE_CHOOSER = 'report-date-range-chooser';
export const REPORT_JOBCLIENT_CHOOSER = 'report-job-client-chooser';
export const REPORT_STARTDATE_WRAPPER = 'report-start-date-wrapper';
export const REPORT_ENDDATE_WRAPPER = 'report-end-date-wrapper';
export const REPORT_TIMEMONEY_CHECKBOXES = 'report-timemoney-checkboxes';
export const REPORT_STARTDATE = 'report-start-date';
export const REPORT_ENDDATE = 'report-end-date';
export const REPORT_RESULTS = 'report-results';
export const REPORT_SHOW_TIME = 'report-show-time';
export const REPORT_SHOW_MONEY = 'report-show-money';

export const WORKSHEETFOOTER = 'worksheet-footer';
export const WORKSHEETCONTAINER = 'worksheet-container';
export const LOADINGINDICATOR = 'loading-indicator';

export const DATA_BUTTONS = "data-buttons";
export const LOGO_MAIN = 'logo-main';
export const NAV_MAIN = 'nav-main';
export const NAV_WORKSHEET = 'nav-worksheet';

export const UI_GUIDE = 'ui-guide';
export const UI_WORKSHEET = 'ui-worksheet';
export const UI_CONFIG = 'ui-config';
export const UI_JOBSANDCLIENTS = 'ui-jobs-and-clients';
export const UI_REPORTS = 'ui-reports';
export const UI_MENU_MINIMISE = 'ui-menu-minimise';

export const UI_CLEARDATA = "ui-clear-data";
export const UI_IMPORTFILE = "ui-import-file";
export const UI_EXPORTFILE = "ui-export-file";
export const UI_MAILDATA = "ui-mail-data";
export const UI_SYNCDATA = "ui-sync-data";

export const COLORPICKER_IMG = 'color-picker-img';
export const COLORPICKER_CANVAS = 'color-picker-canvas';

export const LINKINGLINES_CANVAS = 'linking-lines-canvas';

export const THEME_LINK = 'theme-link';

export const DATEPICKER_DAYS = 'datepicker-days';
