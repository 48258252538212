/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const DAYSINWEEK = 7;
export const DAYSINMONTH = 31; // set to maxiumum possible (only used for display purposes)
export const DAYSINYEAR = 365;

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
