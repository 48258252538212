/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Custom `option` element
  To be used with custom 'select' element

---------------------------------------------------------
*/

import {
  cloneObject
} from './utils.js';

class Option extends HTMLElement {
  init(_ob) {
    // clone properties from `_ob` into `this`, `false` for a shallow clone
    cloneObject(_ob, this, false);
    this.options.appendChild(this);
    /*if(_ob.selected) {
      this.options.select(this);
    }*/
  }

  get id() {
    return this.className;
  }
  set id(_val) {
    this.classList.add(_val);
  }

  get name() {
    return this.textContent;
  }
  set name(_val) {
    this.textContent = _val;
  }

  get selected() {
    return this.isSelected;
  }
  set selected(_val) {
    if (_val === true) {
      this.setAttribute('selected', '');
    }
  }
}

customElements.define('fg-option', Option);
export {Option};
