/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as PAGETYPES from "./PAGETYPES.js";
import * as PREF_IDS from "./PREF_IDS.js";
import {PREF_OPTS} from "./PREF_OPTS.js";
import * as TYPES from "./TYPES.js";
import {getShortISO} from "./../utils.js";

export const DEFAULTS = {
  CLIENTS: [
    {
      name: "Me",
      bgcolor: "#040f11",
      color: "#e7e2c5"
    },
    {
      name: "ACME Company",
      bgcolor: "#144334",
      color: "#d7d1ef"
    },
    {
      name: "General Corporation",
      bgcolor: "#2a46c0",
      color: "#b1f1ee"
    },
    {
      name: "Government",
      bgcolor: "#3f8792",
      color: "#d4fafc"
    }
  ],
  JOBS: [
    {
      name: "Emailing",
      bgcolor: "#f5f4ef",
      color: "#3c162f"
    },
    {
      name: "Administration",
      bgcolor: "#bed3da",
      color: "#323c1a"
    },
    {
      name: "Phone call",
      bgcolor: "#ecfef9",
      color: "#49160d"
    },
    {
      name: "Invoicing",
      bgcolor: "#faf9fa",
      color: "#1d1736"
    },
    {
      name: "Hardware maintenance",
      bgcolor: "#cef8d9",
      color: "#204011"
    },
    {
      name: "Software maintenance",
      bgcolor: "#f6f3f4",
      color: "#15423b"
    }
  ],

  ENTRY_TYPE: TYPES.ITEM_TIME,

  [PREF_IDS.PAGETYPE]: PAGETYPES.GUIDE,

  [PREF_IDS.DATE_FORMAT]: PREF_OPTS.DATEFORMAT_DDMMYY,
  [PREF_IDS.COLORSCHEME]: PREF_OPTS.COLORSCHEME_AUTO,
  [PREF_IDS.TIMESPAN]: PREF_OPTS.TIMESPAN_MONTH,
  [PREF_IDS.MINUTE_INCREMENTS]: PREF_OPTS.MINUTEINCREMENTS_15,
  [PREF_IDS.DAY_LENGTH_HOURS]: 8,
  [PREF_IDS.DAY_INCREMENTS]: PREF_OPTS.DAYINCREMENTS_HALF,
  [PREF_IDS.DAY_ROUNDING_TYPE]: PREF_OPTS.DAYSROUND_UP,
  [PREF_IDS.ADD_ENTRY_BEFOREORAFTER]: PREF_OPTS.ADD_ENTRY_BEFORE,
  [PREF_IDS.WEEK_STARTDAY]: PREF_OPTS.WEEKSTART_MONDAY,
  [PREF_IDS.COMPACTMENUS]: false,
  [PREF_IDS.YEAR_STARTDATE]: new Date(Date.now()).getShortISO(),
  [PREF_IDS.SELECTED_DATE]: "",
  [PREF_IDS.SHOW_WEEKTOTALS]: true,
  [PREF_IDS.SHOW_MONTHTOTALS]: false,
  [PREF_IDS.ANIMATION_ENABLED]: true,
  [PREF_IDS.PRESELECT_LASTUSED]: true,
  [PREF_IDS.MAILDATA_ADDRESS]: "",
  [PREF_IDS.WEBDAV_ADDRESS]: "",
  [PREF_IDS.WEBDAV_USER]: "",
  [PREF_IDS.WEBDAV_PASS]: "",
  // binary string representing checkbox checked/unchecked
  // in same order as PREF_OPTS[PREF_IDS.WEEKEND_DAYS]
  [PREF_IDS.WEEKEND_DAYS]: "0000011",
  [PREF_IDS.IMPORTTYPE_DAYS]: PREF_OPTS.DAYS_MERGE,
  [PREF_IDS.IMPORTTYPE_CLIENTS]: PREF_OPTS.CLIENTS_MERGE,
  [PREF_IDS.IMPORTTYPE_JOBS]: PREF_OPTS.JOBS_MERGE
};
