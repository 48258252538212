/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const PARA = 'GUITypePara';
export const SPAN = 'GUITypeSpan';
export const H1 = 'GUITypeH1';
export const H2 = 'GUITypeH2';
export const H3 = 'GUITypeH3';
export const H4 = 'GUITypeH4';
export const COL = 'GUITypeCol';
export const ROW = 'GUITypeRow';
export const BTN = 'GUITypeBtn';
export const HEADING = 'GUITypeHeading';
export const COLORPICKER = 'GUITypeColorPicker';
export const NUMBER_INPUT = 'GUITypeNumberInput';
export const SPINNER = 'GUITypeSpinner';
export const VIDEO = 'GUITypeVideo';
export const HELP_DEMO = 'GUITypeHelpDemo';
export const RADIOBTN = 'GUITypeRadioBtn';
export const CHECKBOX = 'GUITypeCheckbox';
export const CHECKBOXGROUP = 'GUITypeCheckboxGroup';
export const TEXTINPUT = 'GUITypeTextInput';
export const FILEINPUT = 'GUITypeFileInput';
export const DATEPICKER = 'GUITypeDatePicker';
export const SELECT = 'GUITypeSelect';
export const CLIENTCHOOSER = 'GUITypeClientChooser';
export const JOBCHOOSER = 'GUITypeJobChooser';
export const FORM = 'GUITypeForm';
export const SECTION = 'GUITypeSection';
export const METHODCALL = 'GUITypeMethodCall';
export const UL = 'GUITypeUL';
export const HELP = 'GUITypeHelp';
export const SPACER = 'GUITypeSpacer';
export const TABLE = 'GUITypeTable';
export const LINKINGLINES = 'GUITypeLinkingLines';

export const INLINE_ICON_CLOCK = 'GUITypeInlineIconClock';
export const INLINE_ICON_ADDBNT = 'GUITypeInlineIconAddBtn';
export const INLINE_ICON_REMOVEBTN = 'GUITypeInlineIconRemoveBtn';
export const INLINE_ICON_MONEYPOSITIVE = 'GUITypeInlineIconMoneyPositive';
export const INLINE_ICON_MONEYNEGATIVE = 'GUITypeInlineIconMoneyNegative';
export const INLINE_ICON_NUMBER_INPUT = 'GUITypeInlineIconNumberInput';
