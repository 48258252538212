/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Options:
  Contains options for a custom `select` component

---------------------------------------------------------
*/

import {Option} from './Option.js';

class Options extends HTMLElement {
  add(_ob) {
    var option_el = new Option();
    if (!this.ar) {
      this.ar = [];
    }
    option_el.init(_ob);
    this.ar.push(option_el);
  }

  select(_option) {
    var i, currentOption;
    for (i = 0; i < this.ar.length; i++) {
      currentOption = this.ar[i];
      if (_option === currentOption) {
        // the `option` has already selected itself, so all we need to do here is
        // make sure other options are unselected
        this._selectedIndex_ = i;
        currentOption.setAttribute('selected', ''); // empty value means the attribute gets added without a value for cleaner markup
      } else {
        currentOption.removeAttribute('selected');
      }
    }
  }

  selectPrevious() {
    var currentSelectedIndex = this.selectedIndex,
      newSelectedIndex = currentSelectedIndex - 1;
    if (!newSelectedIndex || newSelectedIndex < 1) {
      newSelectedIndex = this.ar.length - 1;
    }
    this.selectedIndex = newSelectedIndex;
  }

  selectNext() {
    var currentSelectedIndex = this.selectedIndex,
      newSelectedIndex = currentSelectedIndex + 1;
    if (!newSelectedIndex || newSelectedIndex > this.ar.length - 1) {
      newSelectedIndex = 1;
    }
    this.selectedIndex = newSelectedIndex;
  }

  get selected() {
    return this.ar[this._selectedIndex_];
  }

  get selectedIndex() {
    return this._selectedIndex_;
  }
  set selectedIndex(_n) {
    this.select(this.ar[_n]);
  }
}

customElements.define('fg-options', Options);
export {Options};
