/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Elements:
  Used to access HTML elements which may or may not 
  be present in the DOM during initialisation of the app

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";

class Elements {
  static get clientsExisting() {
    return document.getElementById(DDD.EL_IDS.CLIENTSEXISTING);
  }

  static get jobsExisting() {
    return document.getElementById(DDD.EL_IDS.JOBSEXISTING);
  }

  static get reportForm() {
    return document.getElementById(DDD.EL_IDS.REPORTFORM);
  }

  static get reportStartDatePicker() {
    return document.getElementById(DDD.EL_IDS.REPORT_STARTDATE);
  }

  static get reportEndDatePicker() {
    return document.getElementById(DDD.EL_IDS.REPORT_ENDDATE);
  }

  static get reportShowTimeCheckbox() {
    return document.getElementById(DDD.EL_IDS.REPORT_SHOW_TIME);
  }

  static get reportShowMoneyCheckbox() {
    return document.getElementById(DDD.EL_IDS.REPORT_SHOW_MONEY);
  }

  static get reportClientChooser() {
    return document.getElementsByClassName(DDD.CLASSNAMES.CLIENTSELECT)[0];
  }

  static get reportJobChooser() {
    return document.getElementsByClassName(DDD.CLASSNAMES.JOBSELECT)[0];
  }

  static get reportResults() {
    return document.getElementById(DDD.EL_IDS.REPORT_RESULTS);
  }

  static get reportTotals() {
    return document.getElementsByClassName(DDD.CLASSNAMES.TOTALSCONTAINER)[0];
  }

  static get popoverWrapper() {
    return document.getElementsByClassName(DDD.CLASSNAMES.POPOVER_CONTAINER)[0];
  }

  static get worksheet() {
    return document.getElementById(DDD.EL_IDS.WORKSHEETCONTAINER);
  }

  static get worksheetFooter() {
    return document.getElementById(DDD.EL_IDS.WORKSHEETFOOTER);
  }

  static get loadingIndicator() {
    return document.getElementById(DDD.EL_IDS.LOADINGINDICATOR);
  }

  static get fileChooserBtn() {
    return document.getElementById(DDD.EL_IDS.UI_IMPORTFILE);
  }

  static get fileSaveBtn() {
    return document.getElementById(DDD.EL_IDS.UI_EXPORTFILE);
  }

  static get clearDataBtn() {
    return document.getElementById(DDD.EL_IDS.UI_IMPORTFILE);
  }

  static get syncDataBtn() {
    return document.getElementById(DDD.EL_IDS.UI_SYNCDATA);
  }

  static get mailDataBtn() {
    return document.getElementById(DDD.EL_IDS.UI_MAILDATA);
  }

  static get menuMinimiseBtn() {
    return document.getElementById(DDD.EL_IDS.UI_MENU_MINIMISE);
  }

  static get logoMain() {
    return document.getElementById(DDD.EL_IDS.LOGO_MAIN);
  }

  static get navMain() {
    return document.getElementById(DDD.EL_IDS.NAV_MAIN);
  }

  static get navWorksheet() {
    return document.getElementById(DDD.EL_IDS.NAV_WORKSHEET);
  }

  static get todayJumpButton() {
    return document.getElementById(DDD.EL_IDS.TODAYJUMPBTN);
  }

  static get themeLink() {
    return document.getElementById(DDD.EL_IDS.THEME_LINK);
  }

  static get toMinimiseOnMinimise_ar() {
    return [Elements.navMain, Elements.navWorksheet, Elements.logoMain];
  }

  static get toMaximiseOnMinimise_ar() {
    return [
      Elements.mainContainer,
      Elements.loadingIndicator,
      //Elements.datePickerWrapper
    ];
  }
}

Elements.mainContainer = document.getElementById(DDD.EL_IDS.MAIN_CONTAINER);
Elements.workingIndicator = document.getElementById(DDD.EL_IDS.LOGO_MAIN);

export {Elements};
