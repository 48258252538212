/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Spinner:
  Spinner input element

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";
import {Timers} from "./Timers.js";
import {manualEvent, getElementFromElementOrID} from "./utils.js";

class Spinner extends HTMLElement {
  init(_ob) {
    var prop,
      up_el,
      down_el,
      wrapper_el = document.createElement("div"),
      parent_el = getElementFromElementOrID(_ob.parent);

    if (_ob.class) {
      this.classList.add(_ob.class);
    }
    wrapper_el.classList.add(DDD.CLASSNAMES.SPINNER_CLASSNAME);

    this.spin_ob = {};
    for (prop in _ob.attributes) {
      this.spin_ob[prop] = _ob.attributes[prop];
    }
    this.unitSize = _ob.unitSize;

    up_el = document.createElement("button");
    up_el.classList.add(DDD.CLASSNAMES.SPINNER_UPBTN_CLASSNAME);
    up_el.innerHTML = "&#x25B2;";
    up_el.setAttribute("tabindex", -1);

    down_el = document.createElement("button");
    down_el.classList.add(DDD.CLASSNAMES.SPINNER_DOWNBTN_CLASSNAME);
    down_el.innerHTML = "&#x25BC;";
    down_el.setAttribute("tabindex", -1);

    parent_el.appendChild(wrapper_el);
    wrapper_el.appendChild(this);
    wrapper_el.appendChild(up_el);
    wrapper_el.appendChild(down_el);
  }

  onIncreaseMouseDown(_event) {
    this.onStart(1);
    _event.preventDefault();
    _event.stopPropagation();
    _event.stopImmediatePropagation();
  }

  onDecreaseMouseDown(_event) {
    this.onStart(-1);
    _event.preventDefault();
    _event.stopPropagation();
    _event.stopImmediatePropagation();
  }

  onStart(_dir) {
    this.isChanging = true;
    this.curRepeatRate = DDD.TIMERS.SPINNERREPEAT_RATE;
    this.doSpinStep(_dir);
  }

  onMouseUp() {
    this.isChanging = false;

    Timers.clearByID(DDD.TIMERS.SPINNER_REPEAT_ID);
  }

  onMouseOut() {
    this.isChanging = false;

    Timers.clearByID(DDD.TIMERS.SPINNER_REPEAT_ID);
  }

  doSpinStep(_dir) {
    manualEvent(this, "change", {
      direction: _dir,
      unitSize: this.unitSize
    });

    Timers.clearByID(DDD.TIMERS.SPINNER_REPEAT_ID);
    if (this.isChanging) {
      this.curRepeatRate /= DDD.TIMERS.SPINNERREPEAT_ACCEL;
      Timers.setByID(
        DDD.TIMERS.SPINNER_REPEAT_ID,
        this.doSpinStep.bind(this),
        this.curRepeatRate,
        _dir
      );
    }
  }

  get value() {
    return this.innerText;
  }
  set value(_val) {
    this.innerText = _val;
  }
}

customElements.define("fg-spinner", Spinner);
export {Spinner};
