/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as GUI_TYPES from "./GUI_TYPES.js";
import * as CLASSNAMES from "./CLASSNAMES.js";
import * as LABELS from "./LABELS.js";
import * as PAGETYPES from "./PAGETYPES.js";
import * as EL_IDS from "./EL_IDS.js";

export const GUIDATA_NAVMAIN = [
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_WORKSHEET,
    label: LABELS.WORKSHEET,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.navClick",
        args: [PAGETYPES.WORKSHEET],
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_REPORTS,
    label: LABELS.REPORTS,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.navClick",
        args: [PAGETYPES.REPORTS],
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_JOBSANDCLIENTS,
    label: LABELS.JOBS_AND_CLIENTS,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.navClick",
        args: [PAGETYPES.JOBSANDCLIENTS],
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.SPACER,
    class: CLASSNAMES.SPACER_EXPAND_VERT,
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_CONFIG,
    label: LABELS.SETTINGS,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.navClick",
        args: [PAGETYPES.CONFIG],
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.SPACER,
    class: CLASSNAMES.SPACER_EXPAND_VERT,
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_MENU_MINIMISE,
    label: LABELS.TOGGLE_MENU,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.menuMinimiseToggle",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.SPACER,
    class: CLASSNAMES.SPACER_EXPAND_VERT,
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_SYNCDATA,
    disabled: true,
    label: LABELS.SYNC_DATA,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.onSyncBtnClick",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_EXPORTFILE,
    label: LABELS.SAVE_FILE,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.onSaveBtnClick",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.FILEINPUT,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_IMPORTFILE,
    name: EL_IDS.UI_IMPORTFILE,
    label: LABELS.LOAD_FILE,
    event_ar: [
      {
        eventType: "change",
        methodPathStr: "DontDillyDally.AppUI.onImportFileSelected",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_MAILDATA,
    disabled: true,
    label: LABELS.MAIL_DATA,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.onMailDataBtnClick",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.BTNNAV,
    id: EL_IDS.UI_CLEARDATA,
    label: LABELS.CLEAR_DATA,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.AppUI.onClearDataBtnClick",
        scopeID: "main"
      }
    ],
    parent: "nav-main"
  },
  {
    type: GUI_TYPES.SPACER,
    class: CLASSNAMES.SPACER_EXPAND_VERT,
    parent: "nav-main"
  }
];
