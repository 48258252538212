/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export const BODY_GUIDE = 'guide';
export const BODY_WORKSHEET = 'worksheet';
export const BODY_CONFIG = 'config';
export const BODY_JOBSANDCLIENTS = 'jobs-and-clients';
export const BODY_REPORTS = 'reports';

export const SHEET = 'sheet';
export const BTNNAV = 'btn-nav';
export const BTNMININAV = 'btn-mini-nav';
export const FORMMAIN = 'form-main';
export const CLIENTSELECT = 'select-client';
export const JOBSELECT = 'select-job';
export const DATE = 'date';
export const DAY = 'day';
export const TODAY = 'today';
export const SPECIAL_DAY = 'special-day';
export const SPINNER = 'spinner';
export const SPINNER_UNITBIG = 'unit-big';
export const SPINNER_UNITSMALL = 'unit-small';
export const NOTES = 'notes';
export const ADDITEMBTN = 'add-item-btn';
export const REMOVEITEMBTN = 'remove-item-btn';
export const MONEY = 'money';
export const HOURS = 'hrs';
export const MONEYTASK_CHECKBOX = 'money-task-chk';
export const SWITCH = 'switch';
export const ANIM_ATTRACT = 'attract';
export const ANIM_WORKING = 'working';
export const YEARSTART = 'year-start';
export const WEEKSTART = 'week-start';
export const MONTHSTART = 'month-start';
export const TOTALSWEEK = 'totals-week';
export const TOTALSCONTAINER = 'totals-container';
export const COLORPICKER = 'color-picker';
export const TOTALSMONTH = 'totals-month';
export const TOTALSYEAR = 'totals-year';
export const SUMMARY = 'summary';
export const SPACER_EXPAND_VERT = 'expand-vert';
export const TOTALINCOME = 'total-income';
export const TOTALSPEND = 'total-spend';
export const TOTALHRSWORKED = 'total-hoursworked';
export const TOTALDAYSWORKED = 'total-daysworked';
export const TOTALPROFIT = 'total-profit';
export const TOTAL_LABEL = 'total-label';
export const TOTAL_RESULTS_HEADING = 'total-results-heading';

export const RESULTS_SPACER = 'results-spacer';
export const RESULTS_SPACER_SMALL = 'results-spacer-small';

export const DATE_INITIAL = 'date-initial';
export const DATE_REPEAT = 'date-repeat';

export const HIDDEN = 'hidden';
export const INVISIBLE = "invisible";
export const CHECKED = 'checked';
export const NEGATIVE = 'negative';
export const POSITIVE = 'positive';
export const SHOW = 'show';
export const HIDE = 'hide';
export const VOID = 'void';
export const EMPTY = 'empty';
export const ATTENTION = 'attention';
export const PLACEHOLDER = 'placeholder';
export const OPEN = 'open';
export const MINIMISED = 'minimised';
export const MAXIMISED = 'maximised';
export const DIMMED = 'dimmed';
export const WEEKEND = 'weekend';
export const MONTHLY = 'monthly';
export const WEEKLY = 'weekly';

export const SPINNER_CLASSNAME = 'spinner';
export const SPINNER_UPBTN_CLASSNAME = 'spin-button-up';
export const SPINNER_DOWNBTN_CLASSNAME = 'spin-button-down';

export const COLORPICKER_EDITING = 'color-picker-editing';
export const COLORPICKER_SELECTED = 'color-picker-selected';

export const HELPDEMO_BTN = 'help-demo-btn';
export const TEXT_BUTTON = 'text-btn';
export const OK_BUTTON = 'ok-btn';
export const CANCEL_BUTTON = 'cancel-btn';

export const GUIDE_VISIBLE = 'guide-visible';

export const INLINE_ICON_CLOCK = 'inline-icon-clock';
export const INLINE_ICON_ADDBNT = 'inline-icon-add-btn';
export const INLINE_ICON_REMOVEBTN = 'inline-icon-remove-icon';
export const INLINE_ICON_MONEYPOSITIVE = 'inline-icon-money-positive';
export const INLINE_ICON_MONEYNEGATIVE = 'inline-icon-money-negative';
export const INLINE_ICON_NUMBER_INPUT = 'inline-icon-number-input';

export const COLORSCHEME_INK_MODE = 'ink-mode';

export const DATEPICKER_TARGET_INPUT = 'date-picker-target-input';
export const DATEPICKER_WRAPPER = 'picker-wrap';
export const DATEPICKER = 'picker';
export const DATEPICKER_MONTH = 'datepicker-month';
export const DATEPICKER_YEAR = 'datepicker-year';
export const DATEPICKER_DAY = 'datepicker-day';

export const HELP_ITEM = 'help-item';
export const HELP_DEMO = 'help-demo';

export const POPOVER_CONTAINER = "popover-container";
export const POPOVER = "popover";
export const POPOVER_BUTTON_CONTAINER = "popover-button-container";
export const POPOVER_FORM_2COL = "popover-form-2col";
export const POPOVER_FORM_3COL = "popover-form-3col";

export const CLIENTS = "clients";
export const JOBS = "jobs";
