/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/* ---------------------------------------------------------------------------
    dontdillydally

    Main Application:
    - Handles all general logic
	--------------------------------------------------------------------------- */

// this module imports itself
// so later on it can be added to the `window` object for global access by other modules
// previously, rollup.js was used to bundle this project and allowed this main/entry point module
// to be given a name, which was imported into the global scope
// now esbuild is being used, which requires plugins to achieve the same thing
// this technique is a hack to achieve the same thing
import * as dontDillyDally from './main.js';

import {DDD} from "./DDD/CONST.js";

import {AppUI} from "./AppUI.js";
import {ColorScheme} from "./ColorScheme.js";
import {drawGUIFromAr} from "./DOM.js";
import {GlobalEvents} from "./GlobalEvents.js";
import {JobClientItem} from "./JobClientItem.js";
import {JobsClients} from "./JobsClients.js";
import {Page} from "./Page.js";
import {Popover} from "./Popover.js";
import {Reports} from "./Reports.js";
import {Storage} from "./Storage.js";
import {Worksheet} from "./Worksheet.js";
import {WorksheetNav} from "./WorksheetNav.js";

import {registerEventHandler, __} from "./utils.js";

var start, customStylesheet;

start = function () {
	var selectedColorScheme,
		style = document.createElement("style");

	document.head.appendChild(style); // must append before you can access sheet property
	customStylesheet = style.sheet;

	GlobalEvents.startListening();

	if (Storage.storageIsPossible()) {
		Storage.initialiseData();
		drawGUIFromAr(DDD.GUIDATA_NAVMAIN);

		AppUI.setMinimiseState();

		selectedColorScheme = Storage.getPref(DDD.PREF_IDS.COLORSCHEME);
		__({selectedColorScheme});
		if (selectedColorScheme) {
			ColorScheme.select(selectedColorScheme);
		}

		if (location.hash) {
			Page.select(decodeURIComponent(location.hash.substring(1)));
		} else {
			Page.select(Storage.getPref(DDD.PREF_IDS.PAGETYPE));
		}
	}
  
  Popover.init({parent_el: document.body});
};


/* ---------------------------------------------------------------------------
    BEGIN...
    Immediately Invoked Function Expression starts the app
	--------------------------------------------------------------------------- */
(function () {
  window.DontDillyDally = dontDillyDally;
	registerEventHandler(window, "load", start);
})();


/* ---------------------------------------------------------------------------

    - This entry point module (`main.js`) is globally scoped (as 'DontDillyDally'),
      because:

      - The GUI definition files (`DDD/GUIDATA_*`) assign yet-to-be-created
        methods to yet-to-be-created elements, using strings to define their
        path eg "DontDillyDally.doSetup"

      - When an appropriate event fires, the path in the string is converted
        to a scope chain and method via the `callMethodsFromObOnElement` and
        `callMethodFromOb` functions (in `utils.js`)

      - Sometimes (in the GUI definition files) we need to call methods in
        modules that have been imported into `main` but don't exist in global
        scope. To make these methods available, we export their modules below,
        so eg `AppUI` becomes `DontDillyDally.AppUI`

--------------------------------------------------------------------------- */

export {AppUI};
export {Worksheet};
export {WorksheetNav};
export {JobsClients};
export {JobClientItem};
export {Reports};
export {GlobalEvents};
export {customStylesheet};

