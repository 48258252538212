/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  WorksheetEntry:
  An entry in the worksheet
  Each day can contain multiple entries
  An entry can decribe money in/out, or hours spent
  on a job

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";
import {PREF_OPTS} from "./DDD/PREF_OPTS.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as DATA_INDICES from "./DDD/DATA_INDICES.js";
import * as LABELS from "./DDD/LABELS.js";
import * as PAGETYPES from "./DDD/PAGETYPES.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import {Button} from "./Button.js";
import {attachEventArrayToElement} from "./DOM.js";
import {JobClientItem} from "./JobClientItem.js";
import {NumberInput} from "./NumberInput.js";
import {Select} from "./Select.js";
import {Storage} from "./Storage.js";
import {
  changeSelectByOption, createElementWithId, manualEvent, shortID, __
} from "./utils.js";

class WorksheetEntry {}

WorksheetEntry.create = function (_entries_el, _entryID, _entryData_ob) {
  var tmp_el,
    tmpBtn_el,
    entry_el,
    clientID,
    jobID,
    notesClassname,
    unitSmallStep,
    isNewEntry = false,
    // first char of value is T for time or M for money
    entryType =
      _entryData_ob &&
      WorksheetEntry.getTypeFromCombinedString(
        _entryData_ob[DATA_INDICES.COMBINED_VALUE_STR]
      ),
    insertPlacePref = Storage.getPref(PREF_IDS.ADD_ENTRY_BEFOREORAFTER),
    minutePref = Storage.getPref(PREF_IDS.MINUTE_INCREMENTS),
    isMoneyEntry = _entryData_ob && entryType === TYPES.ITEM_MONEY;

  if (_entryID === undefined) {
    _entryID = shortID(DDD.UID_LENGTH);
    isNewEntry = true;
  }
  entry_el = createElementWithId("li", _entryID);

  // insert new entry before or after existing entries depending on preference
  if (insertPlacePref === PREF_OPTS.ADD_ENTRY_BEFORE || !_entries_el.firstChild) {
    _entries_el.insertBefore(entry_el, _entries_el.firstChild);
  } else {
    _entries_el.insertBefore(entry_el, _entries_el.lastChild.nextSibling);
  }

  //////////
  // job select/dropdown
  tmp_el = new Select();
  tmp_el.init({
    contentType: TYPES.CONTENT_JOBS,
    placeholderText: STRINGS.JOB_SELECT_PLACEHOLDER,
    options: Storage.getObj(TYPES.CONTENT_JOBS),
    parent: entry_el
  });
  jobID = _entryData_ob && _entryData_ob[DATA_INDICES.JOB_ID];
  if (!jobID && isNewEntry && Storage.getPref(PREF_IDS.PRESELECT_LASTUSED)) {
    jobID = WorksheetEntry.getRecentJobOrClientID(TYPES.CONTENT_JOBS);
  }
  if (jobID && jobID.length > 0) {
    changeSelectByOption(tmp_el, jobID);
    tmp_el.classList.add(jobID);
    notesClassname = jobID;
    if (!isNewEntry) {
      JobClientItem.incrementUsedCount(TYPES.CONTENT_JOBS, jobID, 1);
      manualEvent(tmp_el, "change");
    }
  }

  //////////
  // link to 'edit job/client' page
  tmpBtn_el = new Button();
  tmpBtn_el.init({
    class: CLASSNAMES.BTNNAV,
    label: LABELS.EDIT_JOBSCLIENTS,
    parent: tmp_el
  });
  attachEventArrayToElement(tmpBtn_el, {
    event_ar: ["mousedown", "touchstart"],
    methodPathStr: "DontDillyDally.AppUI.navClick",
    args: [PAGETYPES.JOBSANDCLIENTS],
    scopeID: "main"
  });

  //////////
  // client select/dropdown
  tmp_el = new Select();
  tmp_el.init({
    contentType: TYPES.CONTENT_CLIENTS,
    placeholderText: STRINGS.CLIENT_SELECT_PLACEHOLDER,
    options: Storage.getObj(TYPES.CONTENT_CLIENTS),
    parent: entry_el
  });
  clientID = _entryData_ob && _entryData_ob[DATA_INDICES.CLIENT_ID];
  if (!clientID && isNewEntry && Storage.getPref(PREF_IDS.PRESELECT_LASTUSED)) {
    clientID = WorksheetEntry.getRecentJobOrClientID(TYPES.CONTENT_CLIENTS);
  }
  if (clientID && clientID.length > 0) {
    changeSelectByOption(tmp_el, clientID);
    tmp_el.classList.add(clientID);
    if (!notesClassname) {
      notesClassname = clientID;
    }
    if (!isNewEntry) {
      JobClientItem.incrementUsedCount(TYPES.CONTENT_CLIENTS, clientID, 1);
      manualEvent(tmp_el, "change");
    }
  }

  //////////
  // link to 'edit job/client' page
  tmpBtn_el = new Button();
  tmpBtn_el.init({
    class: CLASSNAMES.BTNNAV,
    label: LABELS.EDIT_JOBSCLIENTS,
    parent: tmp_el
  });
  attachEventArrayToElement(tmpBtn_el, {
    event_ar: ["mousedown", "touchstart"],
    methodPathStr: "DontDillyDally.AppUI.navClick",
    args: [PAGETYPES.JOBSANDCLIENTS],
    scopeID: "main"
  });

  //////////
  // job/money notes
  tmp_el = document.createElement("textarea");
  if (isMoneyEntry) {
    tmp_el.placeholder = STRINGS.MONEYNOTES_PLACEHOLDER;
  } else {
    tmp_el.placeholder = STRINGS.JOBNOTES_PLACEHOLDER;
  }
  tmp_el.entry_el = entry_el;
  tmp_el.value = (_entryData_ob && _entryData_ob[DATA_INDICES.NOTES]) || "";
  tmp_el.classList.add(CLASSNAMES.NOTES);
  if (tmp_el.value !== "") {
    tmp_el.classList.add(notesClassname);
  } else {
    tmp_el.classList.add(CLASSNAMES.PLACEHOLDER);
  }
  tmp_el.setAttribute("maxlength", DDD.MAXLENGTH_INPUT_NOTES);
  entry_el.appendChild(tmp_el);

  //////////
  // number input field for time or money entry
  if (entryType === TYPES.ITEM_MONEY) {
    unitSmallStep = 1;
  } else {
    unitSmallStep = PREF_OPTS[PREF_IDS.MINUTE_INCREMENTS][minutePref].minutes;
  }
  tmp_el = new NumberInput();
  tmp_el.init({
    parent: entry_el,
    class: CLASSNAMES.PLACEHOLDER,
    entry_el: entry_el,
    entryType: entryType,
    unitSmallStep: unitSmallStep,
    value:
      _entryData_ob &&
      WorksheetEntry.getValueFromCombinedString(
        _entryData_ob[DATA_INDICES.COMBINED_VALUE_STR]
      )
  });

  //////////
  // 'money/task' checkbox
  tmp_el = document.createElement("input");
  tmp_el.type = "checkbox";
  tmp_el.entry_el = entry_el;
  tmp_el.classList.add(CLASSNAMES.SWITCH);
  tmp_el.classList.add(CLASSNAMES.MONEYTASK_CHECKBOX);
  tmp_el.id = _entryID + "_chk";
  entry_el.appendChild(tmp_el);
  attachEventArrayToElement(tmp_el, {
    event_ar: ["change"],
    methodPathStr: "DontDillyDally.Worksheet.onIsMoneyTaskChkChange",
    scopeID: _entryID
  });
  if (isMoneyEntry) {
    tmp_el.checked = true;
    tmp_el.classList.add(CLASSNAMES.CHECKED);
    entry_el.classList.add(CLASSNAMES.MONEY);
  } else {
    entry_el.classList.add(CLASSNAMES.HOURS);
  }
  tmp_el = document.createElement("label");
  tmp_el.htmlFor = _entryID + "_chk";
  tmp_el.setAttribute("tabindex", 0);
  entry_el.appendChild(tmp_el);

  //////////
  // 'remove entry' button
  tmp_el = new Button();
  tmp_el.entry_el = entry_el;
  tmp_el.init({
    class: CLASSNAMES.REMOVEITEMBTN,
    label: LABELS.REMOVEITEM,
    parent: entry_el
  });
  attachEventArrayToElement(tmp_el, {
    event_ar: ["mousedown"],
    methodPathStr: "DontDillyDally.Worksheet.onRemoveEntryBtnClick",
    scopeID: _entryID
  });
};

WorksheetEntry.getRecentJobOrClientID = function (_itemType) {
  var id, recentlyUsedID, items = Storage.getObj(_itemType);
  console.group("WorksheetEntry.getRecentJobOrClientID()");
  __("PRE-SELECTING RECENT (" + _itemType + ")");
  for (id in items) {
    if (items.hasOwnProperty(id)) {
      if (items[id].hasOwnProperty(STRINGS.CLIENTSORJOBS_LASTUSED)) {
        recentlyUsedID = id;
      }
    }
  }
  console.groupEnd();

  return recentlyUsedID;
}

WorksheetEntry.remove = function (_el) {
  var entry_ob,
    previouslySelectedClientID,
    previouslySelectedJobID,
    days_ar = Storage.getObj(STRINGS.DAYS),
    dayID = _el.parentNode.parentNode.id,
    day_ob = days_ar[dayID];

  if (day_ob) {
    entry_ob = day_ob[_el.id];
    if (entry_ob) {
      previouslySelectedClientID = entry_ob[DATA_INDICES.CLIENT_ID];
      previouslySelectedJobID = entry_ob[DATA_INDICES.JOB_ID];
      if (previouslySelectedClientID) {
        JobClientItem.incrementUsedCount(
          TYPES.CONTENT_CLIENTS,
          previouslySelectedClientID,
          -1
        );
      }
      if (previouslySelectedJobID) {
        JobClientItem.incrementUsedCount(TYPES.CONTENT_JOBS, previouslySelectedJobID, -1);
      }
    }
    delete day_ob[_el.id];

    //  if this was the only entry for this day, delete this day
    if (days_ar[dayID] && Object.keys(days_ar[dayID]).length === 0) {
      delete days_ar[dayID];
    }
  }

  _el.parentNode.removeChild(_el);
  Storage.setObj(STRINGS.DAYS, days_ar);
};

// A value string starts with a T for time or M for money
// followed by the value as floating point
WorksheetEntry.getCombinedValueString = function (_type, _value) {
  return _type + _value;
};

WorksheetEntry.getValueFromCombinedString = function (_str) {
  return parseInt(_str.substr(1), 10);
};

WorksheetEntry.getTypeFromCombinedString = function (_str) {
  return _str.substr(0, 1);
};

export {WorksheetEntry};
