/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------

  General Purpose Utility Functions

  A lot of these are based on common answers on eg stackoverflow

---------------------------------------------------------
*/

/* -------------------------------------------------------------------------------
    extend some global objects
  ---------------------------------------------------------------------------------- */

String.prototype.isEmpty = function () {
  return !this || /^\s*$/.test(this);
};

Number.isInteger =
  Number.isInteger ||
  function (value) {
    return typeof value === "number" && isFinite(value) && Math.floor(value) === value;
  };

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

/*
  http://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  Because of short-circuit evaluation, getObject() will immediately return null
  if key is not in Storage. It also will not throw a SyntaxError exception if
  value is "" (the empty string; JSON.parse() cannot handle that). */
Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

// http://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
// DO NOT use UTC methods here as it returns wrong results (not sure why)
Date.prototype.monthDays = function () {
  var d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
  return d.getDate();
};

// http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
Date.prototype.isLeapYear = function () {
  var year = this.getUTCFullYear();
  if ((year & 3) !== 0) {
    return false;
  }
  return year % 100 !== 0 || year % 400 === 0;
};

//http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
// Get Day of Year
Date.prototype.getDOY = function () {
  var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    mn = this.getUTCMonth(),
    dn = this.getUTCDate(),
    dayOfYear = dayCount[mn] + dn;

  if (mn > 1 && this.isLeapYear()) {
    dayOfYear++;
  }
  return dayOfYear;
};

Date.prototype.getShortISO = function () {
  return (
    this.getFullYear() +
    "-" +
    padString("" + (this.getMonth() + 1), "00") +
    "-" +
    padString("" + this.getDate(), "00")
  );
};

Date.prototype.setMidnight = function () {
  this.setHours(0, 0, 0, 0);
  return this;
};

Date.prototype.getWeekDay = function (length) {
  var ret,
    weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

  ret = weekday[this.getDay()];
  if (length > 0) {
    ret = ret.substring(0, length);
  }

  return ret;
};

// https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
/*Date.prototype.getWeekNumber = function() {
  var yearStart,
    d = new Date(Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate())),
    dayNum = d.getUTCDay() || 7;

  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};*/

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
Date.prototype.getWeekNumber = function () {
  // Copy date so don't modify original
  var d = new Date(
    Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate())
  );
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
};

// Clones source_ob into target_ob, either deep or not(/shallow)
export var cloneObject = function (source_ob, target_ob, deep) {
  // objects are passed by reference, so this function changes the original target_ob
  if (deep) {
    target_ob = JSON.parse(JSON.stringify(source_ob));
  } else {
    target_ob = Object.assign(target_ob, source_ob);
  }
};

export var createElementWithText = function (type, str) {
  var el, txt;
  el = document.createElement(type);
  txt = document.createTextNode(str);
  el.appendChild(txt);
  return el;
};

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
export var supportsPassive = function () {
  // Test via a getter in the options object to see if the passive property is accessed
  var supportsPassive = false;
  try {
    var opts = Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      }
    });
    window.addEventListener("testPassive", null, opts);
    window.removeEventListener("testPassive", null, opts);
  } catch (e) {}
  return supportsPassive;
};

// `addEventListener` third argument is messy as it's handled differently
// in different browsers and default is different in modern vs legacy browsers
export var convertThirdListenerArgument = function (arg) {
  var returnArg;
  if (arg === undefined || arg === false) {
    if (supportsPassive()) {
      returnArg = {passive: true};
    } else {
      returnArg = false;
    }
  } else {
    returnArg = arg;
  }
  return returnArg;
};

export var registerEventHandler = function (node, event, handler, useCapture) {
  if (typeof node.addEventListener === "function") {
    node.addEventListener(event, handler, convertThirdListenerArgument(useCapture));
  } else {
    node.attachEvent("on" + event, handler);
  }
};

export var unregisterEventHandler = function (node, event, handler, useCapture) {
  if (typeof node.addEventListener === "function") {
    node.removeEventListener(event, handler, convertThirdListenerArgument(useCapture));
  } else {
    node.detachEvent("on" + event, handler);
  }
};

export var registerMultipleEventsHandler = function (
  node,
  event_ar,
  handler,
  useCapture
) {
  var i;
  for (i = 0; i < event_ar.length; i++) {
    registerEventHandler(
      node,
      event_ar[i],
      handler,
      convertThirdListenerArgument(useCapture)
    );
  }
};

export var unregisterMultipleEventsHandler = function (
  node,
  event_ar,
  handler,
  useCapture
) {
  var i;
  for (i = 0; i < event_ar.length; i++) {
    unregisterEventHandler(
      node,
      event_ar[i],
      handler,
      convertThirdListenerArgument(useCapture)
    );
  }
};

export var addCSSRule = function (selector, property, newValue, customSheet) {
  //__('utils::addCSSRule()');
  //__("\tselector: " + selector);
  //__("\tproperty: " + property);
  //__("\tnewValue: " + newValue);
  //__("\tcustomSheet: " + customSheet);
  var i,
    curStyleSheet,
    totalStyleSheets = document.styleSheets.length,
    newStyle = property + ": " + newValue;

  if (customSheet) {
    //__('\t' + customSheet + ' EXISTS');
    try {
      customSheet.insertRule(
        selector + " {" + newStyle + "}",
        customSheet.cssRules.length
      );
    } catch (err1) {
      try {
        customSheet.addRule(selector, newStyle);
      } catch (err2) {}
    }
  } else {
    //__('\tCREATE ' + customSheet);
    for (i = 0; i < totalStyleSheets; i++) {
      curStyleSheet = document.styleSheets[i];
    }
    try {
      curStyleSheet.insertRule(
        selector + " {" + newStyle + "}",
        curStyleSheet.cssRules.length
      );
    } catch (err1) {
      try {
        //curStyleSheet.addRule(selector, newStyle);
        curStyleSheet.insertRule(`${selector}{${newStyle}}`);
      } catch (err2) {}
    }
  }
};

export var shuffle = function (ar) {
  var tmp,
    randomIndex,
    i = ar.length;

  while (0 !== i) {
    randomIndex = Math.floor(Math.random() * i);
    i -= 1;
    tmp = ar[i];
    ar[i] = ar[randomIndex];
    ar[randomIndex] = tmp;
  }

  return ar;
};

export var manualEvent = function (el, eventName, detail_ob) {
  var evt = new CustomEvent(eventName, {
    bubbles: true,
    detail: detail_ob
  });
  el.dispatchEvent(evt);
};

export var debug = function (msg) {
  __("%c***debug() DISABLED in `utils.js`", "background-color: red; color: white;");
  return;

  if (!window.debug_el) {
    window.debug_el = document.createElement("div");
    document.body.appendChild(window.debug_el);
    window.debug_el.classList.add("debug");
    addCSSRule(".debug", "pointer-events", "none");
    addCSSRule(".debug", "background-color", "rgba(255, 255, 255, 0.6)");
    addCSSRule(".debug", "color", "#880000");
    addCSSRule(".debug", "overflow", "auto");
    addCSSRule(".debug", "z-index", "999999");
    addCSSRule(".debug", "position", "fixed");
    addCSSRule(".debug", "top", "50vh");
    addCSSRule(".debug", "left", "calc(100vw - 25em)");
    addCSSRule(".debug", "bottom", "0");
    addCSSRule(".debug", "padding-right", "1em");
    addCSSRule(".debug", "padding-bottom", "1em");
    addCSSRule(".debug", "right", "0");
    addCSSRule(".debug em", "font-style", "normal");
    addCSSRule(".debug em", "font-weight", "bold");
    addCSSRule(".debug em", "color", "maroon");
  }
  window.debug_el.innerHTML = debug_el.innerHTML + "<br>" + msg;
  //window.debug_el.scrollTo(0, window.debug_el.scrollHeight);
  window.debug_el.scrollTop = window.debug_el.scrollHeight;
};

export var __ = function (msg, format) {
  if (format) {
    console.log("%c" + msg, format);
  } else {
    console.log(msg);
  }
  //debug(msg);
};

export var createElementWithId = function (elType, id) {
  var el = document.createElement(elType);
  el.id = id;
  return el;
};

/* -------------------------------------------------------------------------------
    general helpers
  ---------------------------------------------------------------------------------- */

export var makeLocal = function (toClass, fromClass) {
  var key;
  for (key in fromClass) {
    toClass[key] = fromClass[key];
  }
};

// prevent bubbling/propagation/default events (image drag and drop etc)
// also when showing another image on click we don't want the event to bubble
// up to its container, as a click on the container closes the overlay
export var stopPropagation = function (e) {
  if (e.preventDefault) {
    e.preventDefault();
  }
  if (e.stopPropagation) {
    e.stopPropagation();
  }
  e.cancelBubble = true;
  e.returnValue = false;
  return false;
};

export var rgbStyleStringToHex = function (str) {
  var vals = str.substring(str.indexOf("(") + 1, str.length - 1).split(", ");
  return rgbToHex(vals[0], vals[1], vals[2]);
};

export const toHex = (x) => {
  const hex = Math.round(x).toString(16);
  //__('x: ' + x);
  //__('hex: ' + hex);
  return hex.length === 1 ? "0" + hex : hex;
};

export var rgbToHex = function (r, g, b) {
  return "#" + toHex(r) + toHex(g) + toHex(b);
};

export var hexOpacityToRGBA = function (hexColor, opacity) {
  var r, g, b;
  r = parseInt(hexColor.substring(0, 2), 16);
  g = parseInt(hexColor.substring(2, 4), 16);
  b = parseInt(hexColor.substring(4, 6), 16);
  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
};

export var hexToRGB_ar = function (hex) {
  var bigint;

  // trim leading hash
  if (hex.substr(0, 1) === "#") {
    hex = hex.substr(1);
  }

  bigint = parseInt(hex, 16);

  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

export var getBrightnessFromRGBAr = function (ar) {
  //return ((299 * ar[0]) + (587 * ar[1]) + (114 * ar[2])) / 1000;
  return (ar[0] + ar[0] + ar[2] + ar[1] + ar[1] + ar[1]) / 6;
};

export var hslToHex = function (h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export var getRandomHSLColorArray = function (tone) {
  var saturation,
    lightness,
    hue = Math.floor(Math.random() * 360);
  if (tone === undefined) {
    saturation = Math.floor(Math.random() * 100);
    lightness = Math.floor(Math.random() * 100);
  } else if (tone.toUpperCase() === "LIGHT") {
    saturation = Math.floor(Math.random() * 100);
    lightness = 80 + Math.floor(Math.random() * 20);
  } else if (tone.toUpperCase() === "DARK") {
    saturation = 30 + Math.floor(Math.random() * 40);
    lightness = Math.floor(Math.random() * 50);
  }
  return [hue, saturation, lightness];
};

export var getRandomHexColor = function (tone) {
  var hsl_ar = getRandomHSLColorArray(tone);
  return hslToHex.apply(null, hsl_ar);
};

export var getRandomContrastingHexColor = function (hexColor, minContrast) {
  var brightness1,
    brightness2,
    c1RGB_ar,
    hexContrasting,
    c2RGB_ar = hexToRGB_ar(hexColor),
    brightness2 = getBrightnessFromRGBAr(c2RGB_ar);
  do {
    hexContrasting = getRandomHexColor();
    c1RGB_ar = hexToRGB_ar(hexContrasting);
    brightness1 = getBrightnessFromRGBAr(c1RGB_ar);
    //__("brightness1: " + brightness1);
    //__((brightness1 + 0.05) / brightness2 + 0.05);
  } while (Math.abs(brightness1 - brightness2) < minContrast);

  //__("hexContrasting: " + hexContrasting);
  return hexContrasting;
};

export var getNewStylesheet = function () {
  var style = document.createElement("style");
  style.appendChild(document.createTextNode("")); // needed for webkit
  document.head.appendChild(style);
  return style.sheet;
};

export var padString = function (str, pad) {
  var return_str = str;
  if (str.length < pad.length) {
    return_str = pad.substr(0, pad.length - str.length) + str;
  }
  ///__(pad + " || " + str + " --> " + return_str);
  return return_str;
};

export var getFormattedMoney = function (smallUnits, separator) {
  // largeUnits is eg pounds/dollars
  // smallUnits is eg pennies/cents
  // if value is negative, store '-' in the return_str for later
  // then treat number as unsigned/abs value
  var return_str = smallUnits < 0 ? "-" : "",
    str = padString("" + Math.abs(smallUnits), "000"),
    small = str.substr(-2),
    big = str.substr(0, str.length - 2);

  return_str += big + separator + small;
  return return_str;
};

export var prettyMoney = function (smallUnits, separator, symbol) {
  // largeUnits is eg pounds/dollars
  // smallUnits is eg pennies/cents
  // if value is negative, store '-' in the return_str for later
  // then treat number as unsigned/abs value
  var return_str = smallUnits < 0 ? "-" : "",
    str = padString("" + Math.abs(smallUnits), "000"),
    small = str.substr(-2),
    // add eg commas for UK/US
    big = parseInt(str.substr(0, str.length - 2)).toLocaleString();

  return_str += symbol + big + separator + small;
  return return_str;
};

export var prettyDays = function (ob) {
  var fullDays,
    halfDays,
    returnDays,
    halfString = "<em>&frac12;</em>",
    minsInDay = ob.hoursInDay * 60,
    remainderMins = ob.timeInMins % minsInDay;

  if (ob.dayIncrements === "fullDay") {
    if (ob.dayRoundingType === "daysRoundUp") {
      returnDays = Math.ceil(ob.timeInMins / minsInDay);
    } else if (ob.dayRoundingType === "daysRoundDown") {
      returnDays = Math.floor(ob.timeInMins / minsInDay);
    } else if (ob.dayRoundingType === "daysRoundNearest") {
      returnDays = Math.round(ob.timeInMins / minsInDay);
    }
  } else if (ob.dayIncrements === "halfDay") {
    fullDays = Math.floor(ob.timeInMins / minsInDay);
    if (ob.dayRoundingType === "daysRoundUp") {
      halfDays = Math.ceil(remainderMins / (minsInDay / 2));
    } else if (ob.dayRoundingType === "daysRoundDown") {
      halfDays = Math.floor(remainderMins / (minsInDay / 2));
    } else if (ob.dayRoundingType === "daysRoundNearest") {
      halfDays = Math.round(remainderMins / (minsInDay / 2));
    }

    if (halfDays === 2) {
      returnDays = fullDays + 1;
    } else if (halfDays === 1) {
      if (fullDays > 0) {
        returnDays = "" + fullDays + halfString;
      } else {
        returnDays = halfString;
      }
    } else {
      returnDays = fullDays;
    }
  }

  //__("ob.timeInMins: " + ob.timeInMins);
  //__("ob.hoursInDay: " + ob.hoursInDay);
  //__("ob.dayIncrements: " + ob.dayIncrements);
  //__("ob.dayRoundingType: " + ob.dayRoundingType);
  //__("fullDays: " + fullDays);
  //__("halfDays: " + halfDays);
  //__("returnDays: " + returnDays);
  //__("minsInDay: " + minsInDay);
  //__("remainderMins: " + remainderMins);

  return returnDays;
};

export var getFunctionFromString = function (str) {
  var i,
    scope = window,
    chain_ar = str.split("."),
    chainLength = chain_ar.length - 1;

  for (i = 0; i < chainLength; i++) {
    scope = scope[chain_ar[i]];
    if (scope === undefined) {
      return;
    }
  }

  return scope[chain_ar[chainLength]];
};

// https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
export var daysInOneIndexedMonth = function (month, year) {
  console.group("daysInOneIndexedMonth()");
  //__("month: " + month);
  //__("year: " + year);
  console.groupEnd();
  return new Date(year, month, 0).getDate();
};

export var getFormattedDate = function (date, format) {
  format = format.replace("yy", date.getFullYear().toString().substr(-2));
  format = format.replace("dd", ("0" + date.getDate()).slice(-2));
  format = format.replace("mm", ("0" + (date.getMonth() + 1)).slice(-2));
  return format;
};

export var getFormattedDateLong = function (date, format) {
  format = format.replace("yy", date.getUTCFullYear().toString());
  format = format.replace("dd", ("0" + date.getUTCDate()).slice(-2));
  format = format.replace("mm", ("0" + (date.getUTCMonth() + 1)).slice(-2));
  return format;
};

export var getUTCFromFormattedDate = function (date, format) {
  var i,
    day,
    month,
    year,
    utc,
    date_ar,
    format_ar = format.split("/");
  //__("utils::getUTCFromFormattedDate()");
  //__("\tdate: " + date);
  //__("\tformat: " + format);
  date_ar = date.split("/");
  for (i = 0; i < format_ar.length; i++) {
    //__("\t\tformat_ar[i]: " + format_ar[i]);
    switch (format_ar[i]) {
      case "mm":
        month = date_ar[i] - 1;
        break;
      case "dd":
        day = date_ar[i];
        break;
      case "yy":
        year = date_ar[i];
        break;
    }
  }
  utc = new Date(Date.UTC("20" + year, month, day));
  //__("\tyear: " + year);
  //__("\tmonth: " + month);
  //__("\tday: " + day);
  //__("\tutc: " + utc);
  return utc;
};

export var convertDateBetweenFormats = function (date, old_format, new_format) {
  //__("utils::convertDateBetweenFormats()");
  var utc = getUTCFromFormattedDate(date, old_format);
  //__("\tutc: " + utc);
  return getFormattedDate(utc, new_format);
};

export var getDistanceBetweenPoints = function (p1, p2) {
  var a = Math.abs(p1.x - p2.x),
    b = Math.abs(p1.y - p2.y);
  return Math.sqrt(a * a + b * b);
};

export var getStyle = function (el, styleProp) {
  var style;
  if (el && el.currentStyle) {
    style = el.currentStyle[styleProp];
  } else if (window.getComputedStyle) {
    style = document.defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
  }
  return style;
};

export var setTextInputHeightByContents = function (el) {
  var lineHeight, padding, lines, lastScrollHeight;

  while (el.scrollHeight !== lastScrollHeight) {
    lastScrollHeight = el.scrollHeight;
    lineHeight = parseInt(getStyle(el, "line-height"), 10);
    padding =
      parseInt(getStyle(el, "border-width"), 10) +
      parseInt(getStyle(el, "padding-top"), 10) +
      parseInt(getStyle(el, "padding-bottom"), 10);
    lines = Math.ceil((el.scrollHeight - padding) / lineHeight);

    //__("Typing into notes");

    //__({lineHeight});
    //__({padding});
    //__({lines});
    //__("scrollHeight: " + el.scrollHeight);
    el.style.height = lines * lineHeight + padding + "px";
  }
  //__("iterations: " + i);
};

export var convertRemToPixels = function (rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export var isEmptyOrNull = function (obj) {
  var isEmptyOrNull = true;
  if (obj) {
    isEmptyOrNull = !!!Object.keys(obj).length;
  }
  return isEmptyOrNull;
};

export var floatToArray = function (float) {
  var numStr = "" + float;
  if (numStr.indexOf(".") === -1) {
    numStr += ".00";
  }
  return numStr.split(".");
};

// ----------------------------------------------------------
// A short snippet for detecting versions of IE in JavaScript
// without resorting to user-agent sniffing
// http://james.padolsey.com/javascript/detect-ie-in-js-using-conditional-comments/
// ----------------------------------------------------------
// If you're not in IE (or IE version is less than 5) then:
//     getIEVersion() === undefined
// If you're in IE (>=5) then you can determine which version:
//     getIEVersion() === 7; // IE7
// Thus, to detect IE:
//     if (getIEVersion()) {}
// And to detect the version:
//     getIEVersion() === 6 // IE6
//     getIEVersion() > 7 // IE8, IE9 ...
//     getIEVersion() < 9 // Anything less than IE9
// ----------------------------------------------------------

// UPDATE: Now using Live NodeList idea from @jdalton
export var getIEVersion = function () {
  var undef,
    v = 3,
    div = document.createElement("div"),
    all = div.getElementsByTagName("i");

  while (((div.innerHTML = "<!--[if gt IE " + ++v + "]><i></i><![endif]-->"), all[0]));

  return v > 4 ? v : undef;
};

export var isTouchDevice = function () {
  // window.alert("ontouchstart in window: " + ('ontouchstart' in window) );
  // window.alert("onmsgesturechange in window: " + ('onmsgesturechange' in window) );
  // return 'ontouchstart' in window || 'onmsgesturechange' in window;

  return (
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

// http://stackoverflow.com/questions/542938/how-do-i-get-the-number-of-days-between-two-dates-in-javascript
export var treatAsUTC = function (date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};
export var daysBetween = function (startDate, endDate) {
  var msPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / msPerDay;
};

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export var getGUID = function () {
  /*return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});*/
  var d = new Date().getTime();
  if (typeof performance !== "undefined" && typeof performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export var shortID = function (length) {
  /*
    62 chars, so number of possibilities per length:
      2 - 3,844
      3 - 238,328
      4 - 14,776,336
      5 - 916,132,832
      6 - 56,800,235,584
      7 - 3,521,614,606,208
      8 - 218,340,105,584,896
      9 - 13,537,086,546,263,552
     10 - 839,299,365,868,340,200
  */
  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    ret = "";
  for (var i = 0; i < length; i++) {
    ret += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return ret;
};

/*
export var formatSimpleDate = function(ms, format) {
  var date = new Date(ms);
  __("utils::formatSimpleDate()");
  __("\tms: " + ms);
  __("\tformat: " + format);
  __("\tdate: " + date);
	var format_ar = chunkString(format, 2);
  var return_str = "";
  for (var i = 0; i < format_ar.length; i++) {
    switch (format_ar[i]) {
      case "mm":
       format_ar[i] = date.getUTCMonth() + 1; // getUTCMonth is zero indexed
       break;
      case "dd":
       format_ar[i] = date.getUTCDate();
       break;
      case "yy":
       format_ar[i] = date.getUTCFullYear();
       break;
      default:
        __("utils::formatSimpleDate ERROR");
    }
  }
  return_str = format_ar.join("/");
  return return_str;
};*/

export var chunkString = function (str, length) {
  return str.match(new RegExp(".{1," + length + "}", "g"));
};

export var updateSelectOptionList = function (el, optionsData) {
  var option, option_el;

  while (el.options.length) {
    el.options.remove(el.options.length - 1);
  }
  for (option in optionsData) {
    option_el = el.options[el.options.length] = new Option(
      optionsData[option].name,
      optionsData[option].class
    );
    option_el.classList.add(optionsData[option].class);
  }
};

export var changeSelectByOption = function (el, option) {
  var i,
    options = el.options;
  for (i = 0; i < options.length; i++) {
    if (options[i].value === option) {
      el.selectedIndex = i;
      break;
    }
  }
};

export var minutesToHoursAndMinutesArray = function (n) {
  var hours = Math.floor(n / 60),
    minutes = n % 60;
  return [hours, minutes];
};

export var hoursAndMinutesArrayToMinutes = function (ar) {
  var mins = parseInt(ar[0], 10) * 60; // first item is hours
  mins += parseInt(ar[1], 10);
  return mins;
};

export var prettyTimeFromArray = function (ar) {
  var str = padString("" + ar[0], "00");
  str += ":";
  str += padString("" + ar[1], "00");
  return str;
};

export var getTableFromArrayOfObjects = function (ar, cols) {
  var i,
    row_el,
    cell_el,
    properties,
    property,
    table_el = document.createElement("table");

  for (i = 0; i < ar.length; i++) {
    //__(i);
    if (i % cols === 0) {
      row_el = table_el.appendChild(document.createElement("tr"));
    }
    cell_el = row_el.appendChild(document.createElement("td"));
    properties = ar[i];
    for (property in properties) {
      cell_el[property] = properties[property];
      if (property === "colSpan") {
        i += properties[property] - 1;
        //__(i);
      }
    }
  }
  return table_el;
};

export var forceBrowserReflow = function () {
  // HACK - trigger a reflow, needed to update the DOM sometimes
  document.body.offsetTop;
};

export var createCSSFromOb = function (_ob) {
  var rules = "",
    selector =
      _ob.negator +
      "." +
      _ob.id +
      ", " +
      _ob.negator +
      "." +
      _ob.id +
      ":hover, " +
      _ob.negator +
      "." +
      _ob.id +
      ":active";

  //console.group("createCSSFromOb()");
  //__("_ob: " + JSON.stringify(_ob));

  // add main CSS for eg. worksheets page
  if (_ob.important) {
    rules += "color: " + _ob.color + " !important; ";
    rules += "background-color: " + _ob.bgcolor + " !important; ";
  } else {
    rules += "color: " + _ob.color + "; ";
    rules += "background-color: " + _ob.bgcolor + "; ";
  }
  rules += "font-style: normal; ";
  rules += "border: none; ";
  _ob.stylesheet.insertRule(
    selector + " {" + rules + "}",
    _ob.stylesheet.cssRules.length
  );

  //console.groupEnd();
};

export var removeChildFromParent = function (el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export var getElementFromElementOrID = function (_elementOrID) {
  return typeof _elementOrID == "string"
    ? document.getElementById(_elementOrID)
    : _elementOrID;
};

export var parentContainsClass = function (_el, _class) {
  return (
    _el.parentNode &&
    _el.parentNode.classList &&
    _el.parentNode.classList.contains(_class)
  );
};

export var grandparentContainsClass = function (_el, _class) {
  return (
    _el.parentNode &&
    _el.parentNode.parentNode &&
    _el.parentNode.parentNode.classList &&
    _el.parentNode.parentNode.classList.contains(_class)
  );
};
