/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  WorksheetNav:
  Jump around worksheet by week, month or to 'today'

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";
import {Worksheet} from "./Worksheet.js";


class WorksheetNav {}


WorksheetNav.jumpWeek = function (_dir) {
  var weekdayCur,
    dateToJumpTo = new Date();
  console.group("WorksheetNav.jumpWeek()");

  dateToJumpTo.setTime(Worksheet.getCurrentViewedDayElement().date.getTime());
  dateToJumpTo.setDate(dateToJumpTo.getDate() + _dir * DDD.DATETIME.DAYSINWEEK);

  weekdayCur = dateToJumpTo.getDay(); // 0 = Sunday, 1 = Monday etc
  dateToJumpTo.setDate(dateToJumpTo.getDate() - weekdayCur + Worksheet.weekStartDay); // first day of week

  if (
    Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_WEEK ||
    (Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_MONTH &&
      dateToJumpTo.getMonth() !== Worksheet.getCurrentViewedDayElement().date.getMonth())
  ) {
    Worksheet.redrawThenJumpTo(dateToJumpTo);
  } else {
    Worksheet.jumpToDayElement(document.getElementById(dateToJumpTo.getShortISO()));
  }
  console.groupEnd();
};

WorksheetNav.jumpWeekPrev = function () {
  WorksheetNav.jumpWeek(-1);
};

WorksheetNav.jumpWeekNext = function () {
  WorksheetNav.jumpWeek(1);
};

WorksheetNav.jumpMonth = function (_dir) {
  var dateToJumpTo = new Date();
  dateToJumpTo.setTime(Worksheet.getCurrentViewedDayElement().date.getTime());
  dateToJumpTo.setMonth(dateToJumpTo.getMonth() + _dir);
  dateToJumpTo.setDate(1);

  if (Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_YEAR) {
    Worksheet.jumpToDayElement(document.getElementById(dateToJumpTo.getShortISO()));
  } else {
    Worksheet.redrawThenJumpTo(dateToJumpTo);
  }
};

WorksheetNav.jumpMonthPrev = function () {
  WorksheetNav.jumpMonth(-1);
};

WorksheetNav.jumpMonthNext = function () {
  WorksheetNav.jumpMonth(1);
};

WorksheetNav.todayClick = function () {
  var dateToJumpTo,
    week1Number_ar,
    week2Number_ar,
    weeksAreSame = false;

  if (Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_YEAR) {
    Worksheet.jumpToToday();
  } else {
    dateToJumpTo = new Date(Date.now()).setMidnight();
    if (Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_MONTH) {
      if (dateToJumpTo.getMonth() !== Worksheet.getCurrentViewedDayElement().date.getMonth()) {
        Worksheet.redrawThenJumpTo(dateToJumpTo);
      } else {
        Worksheet.jumpToToday();
      }
    } else if (Worksheet.timeSpan === DDD.PREF_OPTS.TIMESPAN_WEEK) {
      week1Number_ar = dateToJumpTo.getWeekNumber();
      week2Number_ar = Worksheet.getCurrentViewedDayElement().date.getWeekNumber();
      if (week1Number_ar[0] === week2Number_ar[0] && week1Number_ar[1] === week2Number_ar[1]) {
        weeksAreSame = true;
      }
      if (weeksAreSame) {
        Worksheet.jumpToToday();
      } else {
        Worksheet.redrawThenJumpTo(dateToJumpTo);
      }
    }
  }
};

export {WorksheetNav};
