/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  JobClientItem:
  Functions related to individual job/client items

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as LABELS from "./DDD/LABELS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import {Button} from "./Button.js";
import {ColorPicker} from "./ColorPicker.js";
import {Elements} from "./Elements.js";
import {JobsClients} from "./JobsClients.js";
import {Storage} from "./Storage.js";

import {attachEventArrayToElement} from "./DOM.js";

import {
  cloneObject,
  createCSSFromOb, createElementWithId, getRandomHexColor
} from "./utils.js";

class JobClientItem {}

JobClientItem.getNewClient = function () {
  var id = JobsClients.getNextID(TYPES.DATA_CLIENT);
  return {
    tmp_id: id,
    name: STRINGS.CLIENT_UI,
    color: getRandomHexColor("light"),
    bgcolor: getRandomHexColor("dark")
  };
};

JobClientItem.getNewJob = function () {
  var id = JobsClients.getNextID(TYPES.DATA_JOB);
  return {
    tmp_id: id,
    name: STRINGS.JOB_UI,
    color: getRandomHexColor("dark"),
    bgcolor: getRandomHexColor("light")
  };
};

JobClientItem.draw = function (_id, _item, _itemType) {
  var item_el, tmp_el, chipWrapper_el, parent_el;

  item_el = createElementWithId("li", _id);

  parent_el = Elements[_itemType + "Existing"].getElementsByTagName("FG-LIST")[0];

  // add new item to top of list
  parent_el.insertBefore(item_el, parent_el.firstChild);

  tmp_el = document.createElement("input");
  tmp_el.type = "text";
  tmp_el.classList.add(_id);
  tmp_el.setAttribute("maxlength", DDD.MAXLENGTH_INPUT_NAMES);
  tmp_el.value = _item.name;
  item_el.appendChild(tmp_el);

  // Colour chips
  chipWrapper_el = document.createElement("span");
  item_el.appendChild(chipWrapper_el);

  tmp_el = new ColorPicker();
  tmp_el.init({
    parent: chipWrapper_el,
    class: ["color-picker", "bg"],
    id: "cpbg-" + _id,
    color: _item.bgcolor
  });

  tmp_el = new ColorPicker();
  tmp_el.init({
    parent: chipWrapper_el,
    class: ["color-picker", "fg"],
    id: "cpfg-" + _id,
    color: _item.color
  });

  // 'remove' button
  tmp_el = new Button();
  tmp_el.init({
    class: CLASSNAMES.REMOVEITEMBTN,
    label: LABELS.REMOVEITEM,
    parent: item_el
  });
  attachEventArrayToElement(tmp_el, {
    event_ar: ["click"],
    methodPathStr: "DontDillyDally.JobsClients.removeItem",
    scopeID: _id
  });
};

// _itemType: TYPES.CONTENT_CLIENTS or TYPES.CONTENT_JOBS
// _id: client or job id
// _inc: 1 or -1 (for increment or decrement)
JobClientItem.incrementUsedCount = function (_itemType, _id, _inc) {
  var matchedItem = Storage.getObj(_itemType)[_id],
    origData_ar = Storage.getObj(_itemType);

  if (matchedItem) {
    if (!matchedItem[STRINGS.CLIENTSORJOBS_USED]) {
      matchedItem[STRINGS.CLIENTSORJOBS_USED] = 0;
    }
    matchedItem[STRINGS.CLIENTSORJOBS_USED] += _inc;
    origData_ar[_id] = matchedItem;
    Storage.setObj(_itemType, origData_ar);
  }
};

JobClientItem.create = function (_id, _ob, _itemType) {
  var containerObject,
    containerObjectName,
    css_ob = {};

  // create unique identifier for this job or client
  if (_itemType === TYPES.DATA_CLIENT) {
    containerObjectName = TYPES.CONTENT_CLIENTS;
  } else if (_itemType === TYPES.DATA_JOB) {
    containerObjectName = TYPES.CONTENT_JOBS;
  }
  containerObject = Storage.getObj(containerObjectName);
  containerObject[_id] = _ob;
  Storage.setObj(containerObjectName, containerObject);

  cloneObject(_ob, css_ob);
  css_ob.id = _id;
  css_ob.important = true;
  css_ob.stylesheet = DontDillyDally.customStylesheet;
  css_ob.negator = STRINGS.NOT_INKMODE;
  createCSSFromOb(css_ob);
};

JobClientItem.remove = function () {
  // `this` will be an `LI` item representing a client or job
  // This method gets attached to items via GUIDATA before everything exists,
  // hence the indirection
  JobsClients.removeItem.call(this);
};

export {JobClientItem};
