/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as GUI_TYPES from "./GUI_TYPES.js";
import * as HELPITEM_IDS from "./HELPITEM_IDS.js";
import * as PAGETYPES from "./PAGETYPES.js";
import * as STRINGS from "./STRINGS.js";

import {PAGEDATA} from "./PAGEDATA.js";

export const HELPITEM_DATA = {
  DEMOSOURCE_CREATEREPORT: "/video/demo-create-report.mp4",
  DEMOSOURCE_ADDJOBCLIENT: "/video/demo-add-job-client.mp4",
  DEMOSOURCE_ADDMONEY: "/video/demo-add-money-entry.mp4",
  DEMOSOURCE_ADDTIME: "/video/demo-add-time-entry.mp4"
};


HELPITEM_DATA[HELPITEM_IDS.WORKSHEET_MAIN] = {
  id: HELPITEM_IDS.WORKSHEET_MAIN,
  type: GUI_TYPES.HELP,
  parent: "main",
  items: [
    {
      type: "h2",
      text: PAGEDATA[PAGETYPES.WORKSHEET].title
    },
    {
      type: "p",
      text: PAGEDATA[PAGETYPES.WORKSHEET].intro
    },
    {
      type: "p",
      text: STRINGS.HELPITEM_LINK_TO_GUIDE
    }
  ]
	,
  demos: [
    {
      source: HELPITEM_DATA.DEMOSOURCE_ADDMONEY,
      label: STRINGS.HELPDEMO_ADD_MONEY_ENTRY
    },
    {
      source: HELPITEM_DATA.DEMOSOURCE_ADDTIME,
      label: STRINGS.HELPDEMO_ADD_TIME_ENTRY
    }
  ]
};

HELPITEM_DATA[HELPITEM_IDS.JOBSANDCLIENTS_MAIN] = {
  id: HELPITEM_IDS.JOBSANDCLIENTS_MAIN,
  type: GUI_TYPES.HELP,
  parent: "main",
  items: [
    {
      type: "h2",
      text: PAGEDATA[PAGETYPES.JOBSANDCLIENTS].title
    },
    {
      type: "p",
      text: PAGEDATA[PAGETYPES.JOBSANDCLIENTS].intro
    },
    {
      type: "p",
      text: STRINGS.HELPITEM_LINK_TO_GUIDE
    }
  ],
  demos: [
    {
      source: HELPITEM_DATA.DEMOSOURCE_ADDJOBCLIENT,
      label: STRINGS.HELPDEMO_ADD_JOBCLIENT
    }
  ]
};

HELPITEM_DATA[HELPITEM_IDS.CONFIG_MAIN] = {
  id: HELPITEM_IDS.CONFIG_MAIN,
  type: GUI_TYPES.HELP,
  parent: "main",
  items: [
    {
      type: "h2",
      text: PAGEDATA[PAGETYPES.CONFIG].title
    },
    {
      type: "p",
      text: PAGEDATA[PAGETYPES.CONFIG].intro
    },
    {
      type: "p",
      text: STRINGS.HELPITEM_LINK_TO_GUIDE
    }
  ]
};

HELPITEM_DATA[HELPITEM_IDS.REPORTS_MAIN] = {
  id: HELPITEM_IDS.REPORTS_MAIN,
  type: GUI_TYPES.HELP,
  parent: "main",
  items: [
    {
      type: "h2",
      text: PAGEDATA[PAGETYPES.REPORTS].title
    },
    {
      type: "p",
      text: PAGEDATA[PAGETYPES.REPORTS].intro
    },
    {
      type: "p",
      text: STRINGS.HELPITEM_LINK_TO_GUIDE
    }
  ],
  demos: [
    {
      source: HELPITEM_DATA.DEMOSOURCE_CREATEREPORT,
      label: STRINGS.HELPDEMO_CREATE_REPORT
    }
  ]
};
