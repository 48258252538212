/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  RadioButtonGroup:
  A group of radio buttons

---------------------------------------------------------
*/

import {__, getElementFromElementOrID} from "./utils.js";

class RadioButtonGroup extends HTMLElement {
  init(_ob) {
    var description_el,
      headingElementType,
      parent_el = getElementFromElementOrID(_ob.parent);

    console.group("RadioButtonGroup.init()");
    __("parent_el: " + parent_el);

    parent_el.appendChild(this);

    this.id = _ob.id;
    this.setAttribute("tabindex", 0);

    if (_ob.class) {
      this.classList.add(_ob.class);
    }

    this.voidClass = _ob.voidClass;
    this.voidIfContent = _ob.voidIfContent;

    if (_ob.headingElementType) {
      headingElementType = _ob.headingElementType;
    } else {
      headingElementType = "h2";
    }

    // label/text for this entire group of buttons
    if (_ob.label) {
      description_el = document.createElement(headingElementType);
      this.appendChild(description_el);
      description_el.textContent = _ob.label;
    }

    this.options = _ob.options;
    this.value = _ob.checkIfMatched;

    console.groupEnd();
  }

  clear() {
    var i,
      el,
      elementList = this.querySelectorAll("input,label");

    for (i = 0; i < elementList.length; i++) {
      el = elementList[i];
      el.parentNode.removeChild(el);
    }
  }

  set options(_options) {
    var prop, label_el, button_el;

    this.clear();
    for (prop in _options) {
      label_el = document.createElement("label");
      // some buttons can be marked as void (eg in the datepicker the blank squares are not interactive), if so add the specified class to them
      if (_options[prop] === this.voidIfContent) {
        label_el.classList.add(this.voidClass);
      } else {
        button_el = document.createElement("input");
        button_el.setAttribute("type", "radio");
        //button_el.setAttribute("tabindex", 0);
        button_el.name = this.id;
        button_el.id = prop;
        label_el.htmlFor = prop;
        button_el.value = _options[prop].value;
        if (_options[prop].label) {
          label_el.innerHTML = _options[prop].label;
        }
        //label_el.setAttribute("tabindex", 0);
        this.appendChild(button_el);
      }
      this.appendChild(label_el);
    }
  }

  get value() {
    var i,
      curRadioButton,
      returnValue,
      radioButtons = this.getElementsByTagName("INPUT");
    for (i = 0; i < radioButtons.length; i++) {
      curRadioButton = radioButtons[i];
      if (curRadioButton.checked) {
        returnValue = curRadioButton.value;
      }
    }
    return returnValue;
  }
  set value(_id) {
    var i,
      curRadioButton,
      returnValue,
      radioButtons = this.getElementsByTagName("INPUT");

    for (i = 0; i < radioButtons.length; i++) {
      curRadioButton = radioButtons[i];
      if (curRadioButton.value === _id) {
        curRadioButton.checked = true;
      } else {
        curRadioButton.checked = false;
      }
    }
    return returnValue;
  }
}

customElements.define("fg-radio-button-group", RadioButtonGroup);
export {RadioButtonGroup};
