/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

// IDs for each user preference
// This class is iterated through to create default preferences
// - never store anything else in here
export const PAGETYPE = 'pagetype';
export const DATE_FORMAT = 'dateFormat';
export const TIMESPAN = 'timespan';
export const MINUTE_INCREMENTS = 'minuteIncrements';

export const DAY_LENGTH_HOURS = 'dayLengthHours';
export const DAY_INCREMENTS = 'dayIncrements';
export const DAY_ROUNDING_TYPE = 'dayRoundingType';

export const ADD_ENTRY_BEFOREORAFTER = 'addEntryBeforeOrAfter';
export const COMPACTMENUS = 'compactMenus';
export const COLORSCHEME = 'colorScheme';

export const YEAR_STARTDATE = 'yearStartDate';
export const WEEK_STARTDAY = 'weekStartDay';

export const SELECTED_DATE = 'selectedDate';

export const SHOW_WEEKTOTALS = 'showWeekTotals';
export const SHOW_MONTHTOTALS = 'showMonthTotals';

export const ANIMATION_ENABLED = 'animationEnabled';

export const PRESELECT_LASTUSED = 'preselectLastUsed';

export const WEEKEND_DAYS = 'weekendDays';

export const MAILDATA_ADDRESS = 'mailDataAddress';

export const WEBDAV_ADDRESS = 'webdavAddress';
export const WEBDAV_USER = 'webdavUser';
export const WEBDAV_PASS = 'webdavPass';

export const IMPORTTYPE_DAYS = 'importTypeDays';
export const IMPORTTYPE_CLIENTS = 'importTypeClients';
export const IMPORTTYPE_JOBS = 'importTypeJobs';

export const TEMPORARY_DELETION_PREF = 'temporaryDeletionPref';
