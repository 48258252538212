/*
---------------------------------------------------------
  UserData:
  Handles import, export and wiping of user data

  Mark Mayes 2020
---------------------------------------------------------
*/

import { DDD } from "./DDD/CONST.js";
import { DEFAULTS } from "./DDD/DEFAULTS.js";
import { PREF_OPTS } from "./DDD/PREF_OPTS.js";

import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import { AppUI } from "./AppUI.js";
import { ColorScheme } from "./ColorScheme.js";
import { DataMerger } from "./DataMerger.js";
import { JobClientItem } from "./JobClientItem.js";
import { JobsClients } from "./JobsClients.js";
import { Storage } from "./Storage.js";
import { Page } from "./Page.js";
import { isEmptyOrNull, padString, __ } from "./utils.js";

class UserData {}

/**
 * @function import
 * static
 *
 * @param {} _imported_ob
 * @param {boolean} _isAutomatedSync Forces days to be merged ignoring user preference, to be used eg during certain stages of the WebDAV sync process, also prevents automatic page refresh/reload (this will be handled manually by the syncing class)
 */
UserData.import = function (_imported_ob, _isAutomatedSync) {
  var currentPage = Storage.getPref(PREF_IDS.PAGETYPE);

  console.group("UserData.import()");

  // remember current page rather than redirecting to page from stored prefs
  Storage.setPref(PREF_IDS.PAGETYPE, currentPage);

  // Merge the lists of hidden help items
  Storage.setObj(
    TYPES.CONTENT_HELPITEMSHIDDEN,
    DataMerger.getHelpItemsHidden(_imported_ob[TYPES.CONTENT_HELPITEMSHIDDEN])
  );

  if (TYPES.CONTENT_PREFS in _imported_ob) {
    UserData.retainLocalPrefs(_imported_ob[TYPES.CONTENT_PREFS]);
  }

  // Days
  if (
    _isAutomatedSync ||
    Storage.getPref(PREF_IDS.IMPORTTYPE_DAYS) === PREF_OPTS.DAYS_MERGE
  ) {
    __("MERGE Days", DDD.LOG_FORMAT.USERDATA);
    Storage.setObj(
      TYPES.CONTENT_DAYS,
      DataMerger.getDays(_imported_ob[TYPES.CONTENT_DAYS])
    );
  } else {
    __("DELETE AND REPLACE Days", DDD.LOG_FORMAT.USERDATA);
    Storage.setObj(TYPES.CONTENT_DAYS, _imported_ob[TYPES.CONTENT_DAYS]);
  }

  // Jobs/clients
  UserData.importJobsClients(_imported_ob, TYPES.CONTENT_CLIENTS);
  UserData.importJobsClients(_imported_ob, TYPES.CONTENT_JOBS);

  // fill in any gaps in imported prefs eg new options introduced into app since
  // data was exported
  if (!_isAutomatedSync) {
    UserData.initialiseAndRefreshPage();
  }
  console.groupEnd();
};

UserData.importJobsClients = function (_imported_ob, _contentType) {
  // Backwards compatibility fix
  _imported_ob[_contentType] = DataMerger.arrayToObject(
    _imported_ob[_contentType]
  );

  // If replacing (clients|jobs), first delete current unused
  if (UserData.contentTypeShouldBeReplaced(_contentType)) {
    DataMerger.deleteUnusedJobsClients(_contentType);
  }

  // Add/store imported (jobs|clients)
  Storage.setObj(
    _contentType,
    DataMerger.getJobsClients(_imported_ob[_contentType], _contentType)
  );
};

UserData.contentTypeShouldBeReplaced = function (_contentType) {
  var replace = false;
  if (_contentType === TYPES.CONTENT_CLIENTS) {
    if (
      Storage.getPref(PREF_IDS.IMPORTTYPE_CLIENTS) === PREF_OPTS.CLIENTS_REPLACE
    ) {
      replace = true;
    }
  } else if (_contentType === TYPES.CONTENT_JOBS) {
    if (Storage.getPref(PREF_IDS.IMPORTTYPE_JOBS) === PREF_OPTS.JOBS_REPLACE) {
      replace = true;
    }
  }
  return replace;
};

// Some preferences should not be overwritten by stored/imported data
UserData.retainLocalPrefs = function (_importedPrefs) {
  var i,
    id,
    prefID_ar = [
      PREF_IDS.IMPORTTYPE_DAYS,
      PREF_IDS.IMPORTTYPE_CLIENTS,
      PREF_IDS.IMPORTTYPE_JOBS,
      PREF_IDS.WEBDAV_ADDRESS,
      PREF_IDS.WEBDAV_USER,
      PREF_IDS.WEBDAV_PASS,
    ];

  for (i = 0; i < prefID_ar.length; i++) {
    id = prefID_ar[i];
    _importedPrefs[id] = Storage.getPref(id);
  }

  Storage.setObj(TYPES.CONTENT_PREFS, _importedPrefs);
};

UserData.deleteByPref = function () {
  var i,
    ar,
    deletionPref = document.getElementById(
      PREF_IDS.TEMPORARY_DELETION_PREF
    ).value;

  console.group("UserData.deleteByPref()");
  __("deletionPref: " + deletionPref, DDD.LOG_FORMAT.USERDATA);
  if (deletionPref === PREF_OPTS.DELETE_EVERYTHING) {
    ar = [
      TYPES.CONTENT_PREFS,
      TYPES.CONTENT_JOBS,
      TYPES.CONTENT_CLIENTS,
      TYPES.CONTENT_DAYS,
      TYPES.CONTENT_HELPITEMSHIDDEN,
    ];
  } else if (deletionPref === PREF_OPTS.DELETE_ENTRIES_ONLY) {
    ar = [TYPES.CONTENT_DAYS];
  }

  for (i = 0; i < ar.length; i++) {
    __("DELETING: " + ar[i], DDD.LOG_FORMAT.USERDATA);
    Storage.setObj(ar[i], {});
  }

  if (Storage.getPref(PREF_IDS.COMPACTMENUS) === true) {
    AppUI.setCompactMenu(true);
  } else {
    AppUI.setCompactMenu(false);
  }
  UserData.initialiseAndRefreshPage();
  ColorScheme.select(Storage.getPref(PREF_IDS.COLORSCHEME));
  console.groupEnd();
};

UserData.getExportDataObject = function () {
  var today = new Date(),
    y = today.getFullYear(),
    m = padString("" + (today.getMonth() + 1), "00"),
    d = padString("" + today.getDate(), "00"),
    h = padString("" + today.getHours(), "00"),
    mi = padString("" + today.getMinutes(), "00"),
    s = padString("" + today.getSeconds(), "00"),
    dateTime_str = y + "-" + m + "-" + d + "_" + h + "-" + mi + "-" + s;

  return {
    data: {
      [STRINGS.JSON_NOTES_HEADING]: DDD.NOTES_ON_EXPORTED_DATA,
      [TYPES.CONTENT_PREFS]: Storage.getObj(TYPES.CONTENT_PREFS),
      [TYPES.CONTENT_JOBS]: Storage.getObj(TYPES.CONTENT_JOBS),
      [TYPES.CONTENT_CLIENTS]: Storage.getObj(TYPES.CONTENT_CLIENTS),
      [TYPES.CONTENT_DAYS]: Storage.getObj(TYPES.CONTENT_DAYS),
      [TYPES.CONTENT_HELPITEMSHIDDEN]: Storage.getObj(
        TYPES.CONTENT_HELPITEMSHIDDEN
      ),
    },
    filenameDated: DDD.SAVE_FILENAME + "_" + dateTime_str + ".json",
  };
};

UserData.setUnspecifiedPrefsToDefaults = function () {
  var prefIDKey,
    prefID,
    storedPref,
    tmp_ob,
    prefIDs = PREF_IDS;
  console.group("UserData.setUnspecifiedPrefsToDefaults()");

  // create new object to store which help items have been hidden by the user
  tmp_ob = Storage.getObj(TYPES.CONTENT_HELPITEMSHIDDEN);
  if (isEmptyOrNull(tmp_ob)) {
    Storage.setObj(TYPES.CONTENT_HELPITEMSHIDDEN, {});
  }

  // loop through all potential preferences
  // If the preference doesn't already exist in the user's data store,
  // create it and set it to default value
  tmp_ob = Storage.getObj(TYPES.CONTENT_PREFS);
  for (prefIDKey in prefIDs) {
    if (prefIDs.hasOwnProperty(prefIDKey)) {
      prefID = prefIDs[prefIDKey];
      storedPref = Storage.getPref(prefID);
      if (storedPref === undefined) {
        __(
          "\tMissing preference, setting default: " +
            prefID +
            ": " +
            DEFAULTS[prefID],
          DDD.LOG_FORMAT.USERDATA
        );
        tmp_ob[prefID] = DEFAULTS[prefID];
      }
    }
  }
  Storage.setObj(TYPES.CONTENT_PREFS, tmp_ob);
  console.groupEnd();
};

// Create data for new user, or initialise any missing parts
// after user has deleted some data
UserData.createMissing = function () {
  var i,
    id,
    tmpID,
    tmpItem,
    tmp_ob,
    items,
    typeData,
    typeData_ar = [
      {
        dataType: TYPES.DATA_CLIENT,
        contentType: TYPES.CONTENT_CLIENTS,
        defaultItems: DEFAULTS.CLIENTS,
      },
      {
        dataType: TYPES.DATA_JOB,
        contentType: TYPES.CONTENT_JOBS,
        defaultItems: DEFAULTS.JOBS,
      },
    ],
    prefIDs = PREF_IDS;

  console.group("UserData.createMissing()");

  // Initialise/update preferences
  tmp_ob = Storage.getObj(TYPES.CONTENT_PREFS);
  if (isEmptyOrNull(tmp_ob)) {
    __("PREFS object does not exist - creating", DDD.LOG_FORMAT.USERDATA);
    tmp_ob = {};
  } else {
    __(
      "PREFS object exists: " + JSON.stringify(tmp_ob),
      DDD.LOG_FORMAT.USERDATA_ALT
    );
  }
  // remember current page to avoid jumping to default page
  if (location.hash) {
    tmp_ob[prefIDs.PAGETYPE] = decodeURIComponent(location.hash.substring(1));
  } else {
    tmp_ob[prefIDs.PAGETYPE] = DEFAULTS[prefIDs.PAGETYPE];
  }
  Storage.setObj(TYPES.CONTENT_PREFS, tmp_ob);

  // Loop through [jobs|clients] data containers
  for (i = 0; i < typeData_ar.length; i++) {
    typeData = typeData_ar[i];
    items = Storage.getObj(typeData.contentType);

    // If it doesn't already exist...
    if (isEmptyOrNull(items)) {
      __(
        typeData.contentType.toUpperCase() +
          " object does not exist - creating and adding defaults",
        DDD.LOG_FORMAT.USERDATA
      );
      // create new object to store [jobs|clients] and fill it with some defaults
      Storage.setObj(typeData.contentType, {});
      for (id in typeData.defaultItems) {
        if (typeData.defaultItems.hasOwnProperty(id)) {
          tmpID = JobsClients.getNextID(typeData.dataType);
          tmpItem = typeData.defaultItems[id];
          JobClientItem.create(tmpID, tmpItem, typeData.dataType);
        }
      }
    } else {
      __(
        typeData.contentType.toUpperCase() +
          " object exists: " +
          JSON.stringify(items),
        DDD.LOG_FORMAT.USERDATA_ALT
      );
      if (Array.isArray(items)) {
        __("Legacy data format -- converting", DDD.LOG_FORMAT.ERROR);
        Storage.setObj(typeData.contentType, DataMerger.arrayToObject(items));
      }
    }
  }

  tmp_ob = Storage.getObj(TYPES.CONTENT_DAYS);
  if (isEmptyOrNull(tmp_ob)) {
    __("DAYS object does not exist - creating", DDD.LOG_FORMAT.USERDATA);
    tmp_ob = {};
  } else {
    __(
      "DAYS object exists: " + JSON.stringify(tmp_ob),
      DDD.LOG_FORMAT.USERDATA_ALT
    );
  }
  // new empty object to store days (work items are stored in days)
  Storage.setObj(TYPES.CONTENT_DAYS, tmp_ob);

  console.groupEnd();
};

UserData.setPrefsByFieldProperties = function (_ob) {
  var i,
    tmpID,
    prefs_ob = Storage.getObj(STRINGS.PREFS);

  for (i = 0; i < _ob.id_ar.length; i++) {
    tmpID = _ob.id_ar[i];
    __("tmpID: " + tmpID);
    if (document.getElementById(tmpID)) {
      prefs_ob[tmpID] = document.getElementById(tmpID)[_ob.property];
    } else {
      __("\tMISSING");
    }
  }

  Storage.setObj(STRINGS.PREFS, prefs_ob);
};

UserData.initialiseAndRefreshPage = function () {
  Storage.initialiseData();
  Page.refresh();
};

export { UserData };
