/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
  HelpDemo:
  A video demonstrating an aspect of app usage
  Intended to appear inside the popover
*/

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";

import {removeChildFromParent} from "./utils.js";

class HelpDemo {}

HelpDemo.create = function (_ob) {
  var tmp_el;

  // Container for all radio button groups
  HelpDemo.el = document.createElement("div");
  HelpDemo.el.classList.add(CLASSNAMES.HELP_DEMO);
  _ob.parent.appendChild(HelpDemo.el);
        
  tmp_el = document.createElement('video');
  tmp_el.setAttribute("type", "video/mp4");
  tmp_el.setAttribute("src", _ob.data.source);
  tmp_el.setAttribute("controls", "");
  HelpDemo.el.appendChild(tmp_el);
};

HelpDemo.remove = function () {
  if (HelpDemo.el) {
    HelpDemo.el.innerHTML = "";
    removeChildFromParent(HelpDemo.el);
  }
};

export {HelpDemo};
