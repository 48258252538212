/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as PREF_IDS from "./PREF_IDS.js";

export const PREF_OPTS = {
	INTROS: {
		[PREF_IDS.DATE_FORMAT]: "How dates look",
		[PREF_IDS.TIMESPAN]: "Worksheet shows",
		[PREF_IDS.MINUTE_INCREMENTS]: "Round times to the nearest",
		[PREF_IDS.DAY_INCREMENTS]: "Round days to the nearest",
		[PREF_IDS.DAY_ROUNDING_TYPE]: "When rounding days or half days",
		[PREF_IDS.DAY_LENGTH_HOURS]:
			"Length of working day in hours<em>Used when converting hours worked into billable days</em>",
		[PREF_IDS.YEAR_STARTDATE]:
			"First day of the year<em>When showing a full year on the worksheet, it will start on this date and finish on the preceding day of the following year</em><em>(Eg you might set this to match the first day of your tax year)</em>",
		[PREF_IDS.WEEK_STARTDAY]: "The first day of the week is",
		[PREF_IDS.ADD_ENTRY_BEFOREORAFTER]: "Entries are added to the day",
		[PREF_IDS.ANIMATION_ENABLED]:
			"<em>Spin bird's eye when saving / Important text flashes to attract attention</em>",
		[PREF_IDS.WEEKEND_DAYS]:
			"Set weekend days<em>This just marks them visually on the worksheet</em>",
		[PREF_IDS.SHOW_WEEKTOTALS]: "At the end of each week",
		[PREF_IDS.SHOW_MONTHTOTALS]: "At the end of each month",
		[PREF_IDS.MAILDATA_ADDRESS]:
			"Address to email data to<em>Used when you press the 'Email Data' button</em>",
		[PREF_IDS.COLORSCHEME]: "Color scheme",
		[PREF_IDS.PRESELECT_LASTUSED]: "Pre-select the previous job/client",
    [PREF_IDS.WEBDAV_ADDRESS]: "WebDAV address including the directory to store dontdillydally data in",
    [PREF_IDS.WEBDAV_USER]: "Username",
    [PREF_IDS.WEBDAV_PASS]: "Password",
	},

	///////////////////////////////////////////////////////////
	// ALL REMAINING VALUES INSIDE THIS OBJECT
	// - MUST BE UNIQUE
	// - MUST BE VALID JS IDS
	///////////////////////////////////////////////////////////

	DATEFORMAT_YYMMDD: "yymmdd",
	DATEFORMAT_DDMMYY: "ddmmyy",
	DATEFORMAT_MMDDYY: "mmddyy",

	TIMESPAN_WEEK: "week",
	TIMESPAN_MONTH: "month",
	TIMESPAN_YEAR: "year",

	MINUTEINCREMENTS_1: "m1",
	MINUTEINCREMENTS_5: "m5",
	MINUTEINCREMENTS_10: "m10",
	MINUTEINCREMENTS_15: "m15",
	MINUTEINCREMENTS_30: "m30",

	DAYINCREMENTS_FULL: "fullDay",
	DAYINCREMENTS_HALF: "halfDay",

	DAYSROUND_UP: "daysRoundUp",
	DAYSROUND_DOWN: "daysRoundDown",
	DAYSROUND_NEAREST: "daysRoundNearest",

	ADD_ENTRY_BEFORE: "before",
	ADD_ENTRY_AFTER: "after",

	COLORSCHEME_DARK: "colorDark",
	COLORSCHEME_LIGHT: "colorLight",
	COLORSCHEME_AUTO: "colorAuto",
	COLORSCHEME_MONO_DARK: "monoDark",
	COLORSCHEME_MONO_LIGHT: "monoLight",
	COLORSCHEME_MONO_AUTO: "monoAuto",
	COLORSCHEME_INK_DARK: "inkDark",
	COLORSCHEME_INK_LIGHT: "inkLight",
	COLORSCHEME_INK_AUTO: "inkAuto",

	WEEKSTART_MONDAY: "startMonday",
	WEEKSTART_SUNDAY: "startSunday",

	WEEKEND_MONDAY: "weekendMonday",
	WEEKEND_TUESDAY: "weekendTuesday",
	WEEKEND_WEDNESDAY: "weekendWednesday",
	WEEKEND_THURSDAY: "weekendThursday",
	WEEKEND_FRIDAY: "weekendFriday",
	WEEKEND_SATURDAY: "weekendSaturday",
	WEEKEND_SUNDAY: "weekendSunday",

	DAYS_REPLACE: "daysReplace",
	DAYS_MERGE: "daysMerge",
	CLIENTS_REPLACE: "clientsReplace",
	CLIENTS_MERGE: "clientsMerge",
	JOBS_REPLACE: "jobsReplace",
	JOBS_MERGE: "jobsMerge",

	DELETE_EVERYTHING: "deleteEverything",
	DELETE_ENTRIES_ONLY: "entriesOnly"
};

PREF_OPTS[PREF_IDS.WEEKEND_DAYS] = {
	[PREF_OPTS.WEEKEND_MONDAY]: {
		value: PREF_OPTS.WEEKEND_MONDAY,
		label: "Monday"
	},
	[PREF_OPTS.WEEKEND_TUESDAY]: {
		value: PREF_OPTS.WEEKEND_TUESDAY,
		label: "Tuesday"
	},
	[PREF_OPTS.WEEKEND_WEDNESDAY]: {
		value: PREF_OPTS.WEEKEND_WEDNESDAY,
		label: "Wednesday"
	},
	[PREF_OPTS.WEEKEND_THURSDAY]: {
		value: PREF_OPTS.WEEKEND_THURSDAY,
		label: "Thursday"
	},
	[PREF_OPTS.WEEKEND_FRIDAY]: {
		value: PREF_OPTS.WEEKEND_FRIDAY,
		label: "Friday"
	},
	[PREF_OPTS.WEEKEND_SATURDAY]: {
		value: PREF_OPTS.WEEKEND_SATURDAY,
		label: "Saturday"
	},
	[PREF_OPTS.WEEKEND_SUNDAY]: {
		value: PREF_OPTS.WEEKEND_SUNDAY,
		label: "Sunday"
	}
};

PREF_OPTS[PREF_IDS.DATE_FORMAT] = {
	[PREF_OPTS.DATEFORMAT_YYMMDD]: {
		id: "yymmdd",
		value: "yymmdd",
		label: "yy/mm/dd"
	},
	[PREF_OPTS.DATEFORMAT_DDMMYY]: {
		id: "ddmmyy",
		value: "ddmmyy",
		label: "dd/mm/yy"
	},
	[PREF_OPTS.DATEFORMAT_MMDDYY]: {
		id: "mmddyy",
		value: "mmddyy",
		label: "mm/dd/yy"
	}
};

PREF_OPTS[PREF_IDS.COLORSCHEME] = {
	[PREF_OPTS.COLORSCHEME_DARK]: {
		label: "Dark",
		href: "/css/DDD-dark.css",
		value: [PREF_OPTS.COLORSCHEME_DARK]
	},
	[PREF_OPTS.COLORSCHEME_LIGHT]: {
		label: "Light",
		href: "/css/DDD-light.css",
		value: [PREF_OPTS.COLORSCHEME_LIGHT]
	},
	[PREF_OPTS.COLORSCHEME_AUTO]: {
		label: "Auto<em>match system light/dark mode</em>",
		href: "/css/DDD-light.css",
		value: [PREF_OPTS.COLORSCHEME_AUTO]
	},
	[PREF_OPTS.COLORSCHEME_MONO_DARK]: {
		label: "Mono Dark",
		href: "/css/DDD-dark-mono.css",
		value: [PREF_OPTS.COLORSCHEME_MONO_DARK]
	},
	[PREF_OPTS.COLORSCHEME_MONO_LIGHT]: {
		label: "Mono Light",
		href: "/css/DDD-light-mono.css",
		value: [PREF_OPTS.COLORSCHEME_MONO_LIGHT]
	},
	[PREF_OPTS.COLORSCHEME_MONO_AUTO]: {
		label: "Mono Auto<em>match system light/dark mode but stay black and white</em>",
		href: "/css/DDD-light-mono.css",
		value: [PREF_OPTS.COLORSCHEME_MONO_AUTO]
	},
	[PREF_OPTS.COLORSCHEME_INK_DARK]: {
		label: "Ink Dark",
		href: "/css/DDD-dark-mono.css",
		value: [PREF_OPTS.COLORSCHEME_INK_DARK]
	},
	[PREF_OPTS.COLORSCHEME_INK_LIGHT]: {
		label: "Ink Light",
		href: "/css/DDD-light-mono.css",
		value: [PREF_OPTS.COLORSCHEME_INK_LIGHT]
	},
	[PREF_OPTS.COLORSCHEME_INK_AUTO]: {
		label: "Ink Auto<em>match system light/dark mode but stay inky</em>",
		href: "/css/DDD-light-mono.css",
		value: [PREF_OPTS.COLORSCHEME_INK_AUTO]
	}
};

PREF_OPTS[PREF_IDS.TIMESPAN] = {
	[PREF_OPTS.TIMESPAN_WEEK]: {
		label: "A week",
		value: [PREF_OPTS.TIMESPAN_WEEK]
	},
	[PREF_OPTS.TIMESPAN_MONTH]: {
		label: "A month",
		value: [PREF_OPTS.TIMESPAN_MONTH]
	},
	[PREF_OPTS.TIMESPAN_YEAR]: {
		label: "A year",
		value: [PREF_OPTS.TIMESPAN_YEAR]
	}
};

PREF_OPTS[PREF_IDS.DAY_INCREMENTS] = {
	[PREF_OPTS.DAYINCREMENTS_FULL]: {
		label: "Full days",
		value: [PREF_OPTS.DAYINCREMENTS_FULL]
	},
	[PREF_OPTS.DAYINCREMENTS_HALF]: {
		label: "Half days",
		value: [PREF_OPTS.DAYINCREMENTS_HALF]
	}
};

PREF_OPTS[PREF_IDS.DAY_ROUNDING_TYPE] = {
	[PREF_OPTS.DAYSROUND_UP]: {
		label: "Always round up",
		value: [PREF_OPTS.DAYSROUND_UP]
	},
	[PREF_OPTS.DAYSROUND_DOWN]: {
		label: "Always round down",
		value: [PREF_OPTS.DAYSROUND_DOWN]
	},
	[PREF_OPTS.DAYSROUND_NEAREST]: {
		label: "Round up or down, whichever is nearest",
		value: [PREF_OPTS.DAYSROUND_NEAREST]
	}
};

PREF_OPTS[PREF_IDS.MINUTE_INCREMENTS] = {
	[PREF_OPTS.MINUTEINCREMENTS_1]: {
		label: "1 minute",
		minutes: 1,
		value: [PREF_OPTS.MINUTEINCREMENTS_1]
	},
	[PREF_OPTS.MINUTEINCREMENTS_5]: {
		label: "5 minutes",
		minutes: 5,
		value: [PREF_OPTS.MINUTEINCREMENTS_5]
	},
	[PREF_OPTS.MINUTEINCREMENTS_10]: {
		label: "10 minutes",
		minutes: 10,
		value: [PREF_OPTS.MINUTEINCREMENTS_10]
	},
	[PREF_OPTS.MINUTEINCREMENTS_15]: {
		label: "15 minutes",
		minutes: 15,
		value: [PREF_OPTS.MINUTEINCREMENTS_15]
	},
	[PREF_OPTS.MINUTEINCREMENTS_30]: {
		label: "30 minutes",
		minutes: 30,
		value: [PREF_OPTS.MINUTEINCREMENTS_30]
	}
};

PREF_OPTS[PREF_IDS.ADD_ENTRY_BEFOREORAFTER] = {
	[PREF_OPTS.ADD_ENTRY_BEFORE]: {
		label: "From bottom to top",
		value: [PREF_OPTS.ADD_ENTRY_BEFORE]
	},
	[PREF_OPTS.ADD_ENTRY_AFTER]: {
		label: "From top to bottom",
		value: [PREF_OPTS.ADD_ENTRY_AFTER]
	}
};

PREF_OPTS[PREF_IDS.WEEK_STARTDAY] = {
	[PREF_OPTS.WEEKSTART_MONDAY]: {
		label: "Monday",
		value: [PREF_OPTS.WEEKSTART_MONDAY]
	},
	[PREF_OPTS.WEEKSTART_SUNDAY]: {
		label: "Sunday",
		value: [PREF_OPTS.WEEKSTART_SUNDAY]
	}
};

PREF_OPTS[PREF_IDS.IMPORTTYPE_DAYS] = {
	[PREF_OPTS.DAYS_REPLACE]: {
		value: [PREF_OPTS.DAYS_REPLACE]
	},
	[PREF_OPTS.DAYS_MERGE]: {
		value: PREF_OPTS.DAYS_MERGE
	}
};
PREF_OPTS[PREF_IDS.IMPORTTYPE_CLIENTS] = {
	[PREF_OPTS.CLIENTS_REPLACE]: {
		value: [PREF_OPTS.CLIENTS_REPLACE]
	},
	[PREF_OPTS.CLIENTS_MERGE]: {
		value: [PREF_OPTS.CLIENTS_MERGE]
	}
};
PREF_OPTS[PREF_IDS.IMPORTTYPE_JOBS] = {
	[PREF_OPTS.JOBS_REPLACE]: {
		value: [PREF_OPTS.JOBS_REPLACE]
	},
	[PREF_OPTS.JOBS_MERGE]: {
		value: [PREF_OPTS.JOBS_MERGE]
	}
};
PREF_OPTS[PREF_IDS.TEMPORARY_DELETION_PREF] = {
	[PREF_OPTS.DELETE_EVERYTHING]: {
		value: [PREF_OPTS.DELETE_EVERYTHING]
	},
	[PREF_OPTS.DELETE_ENTRIES_ONLY]: {
		value: [PREF_OPTS.DELETE_ENTRIES_ONLY]
	}
};
