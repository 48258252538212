/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  ColorPicker:
  Color picker

---------------------------------------------------------
*/

import {Elements} from "./Elements.js";
import {
  createElementWithId,
  getElementFromElementOrID,
  manualEvent,
  registerEventHandler,
  rgbToHex,
  unregisterEventHandler,
  __
} from "./utils.js";

import {DDD} from "./DDD/CONST.js";

class ColorPicker extends HTMLElement {
  init(_ob) {
    var i,
      label_el,
      parent_el = getElementFromElementOrID(_ob.parent);

    parent_el.appendChild(this);
    if (_ob.class) {
      if (Array.isArray(_ob.class)) {
        for (i = 0; i < _ob.class.length; i++) {
          this.classList.add(_ob.class[i]);
        }
      } else {
        this.classList.add(_ob.class);
      }
    }
    this.id = _ob.id;
    this.type = "checkbox";

    label_el = document.createElement("label");
    label_el.htmlFor = _ob.id;
    label_el.style.backgroundColor = _ob.color;
    parent_el.appendChild(label_el);

    ColorPicker.setupCanvas();

    registerEventHandler(label_el, "click", ColorPicker.onClick, true);
  }
}

ColorPicker.IMG_PATH = "/images/spectrum_chart.jpg";
ColorPicker.CHANGEEVENT_ID = "colorpickerchange";

ColorPicker.dispose = function () {
  if (ColorPicker.spectrumCanvas && ColorPicker.spectrumCanvas.parentNode) {
    ColorPicker.spectrumCanvas.parentNode.removeChild(ColorPicker.spectrumCanvas);
    ColorPicker.close();
  }
};

ColorPicker.close = function () {
  ColorPicker.spectrumCanvas.style.display = "none";
  if (ColorPicker.selectedCurrentItem) {
    ColorPicker.selectedCurrentItem.classList.remove(DDD.CLASSNAMES.COLORPICKER_SELECTED);
  }
  if (Elements.mainContainer) {
    Elements.mainContainer.classList.remove(DDD.CLASSNAMES.COLORPICKER_EDITING);
    document.body.classList.remove(DDD.CLASSNAMES.COLORPICKER_EDITING);
  }
  ColorPicker.setListeningState({stopListening: true});
  ColorPicker.isOpen = false;
};

ColorPicker.calculateScaleFactors = function () {
  ColorPicker.scaleXFactor =
    ColorPicker.spectrumCanvas.width / ColorPicker.spectrumCanvas.offsetWidth;
  ColorPicker.scaleYFactor =
    ColorPicker.spectrumCanvas.height / ColorPicker.spectrumCanvas.offsetHeight;
};

ColorPicker.setupCanvas = function () {
  var spectrumCanvas = document.getElementById(DDD.EL_IDS.COLORPICKER_CANVAS);
  if (spectrumCanvas) {
    spectrumCanvas.parentNode.removeChild(spectrumCanvas);
  }

  ColorPicker.spectrumCanvas = createElementWithId(
    "canvas",
    DDD.EL_IDS.COLORPICKER_CANVAS
  );
  ColorPicker.spectrumCanvas.width = DDD.COLORPICKER_UNSCALED_CANVAS_WIDTH;
  ColorPicker.spectrumCanvas.height = DDD.COLORPICKER_UNSCALED_CANVAS_HEIGHT;

  ColorPicker.addGradient({
    size_ar: [0, 0, ColorPicker.spectrumCanvas.width, 0],
    colorStop_ar: [
      [0, "#ff0000"],
      [(1 / 9) * 2, "#ff0000"],
      [(1 / 9) * 3, "#ffff00"],
      [(1 / 9) * 4, "#ffff00"],
      [(1 / 9) * 5, "#00ff00"],
      [(1 / 9) * 6, "#00ffff"],
      [(1 / 9) * 7, "#0000ff"],
      [(1 / 9) * 8, "#ff00ff"],
      [1, "#ff0000"]
    ]
  });
  ColorPicker.addGradient({
    size_ar: [0, 0, ColorPicker.spectrumCanvas.width, 0],
    colorStop_ar: [
      [0, "rgba(255, 255, 255, 1)"],
      [0.3, "rgba(255, 255, 255, 0)"],
      [1, "rgba(255, 255, 255, 0)"]
    ]
  });
  ColorPicker.addGradient({
    size_ar: [0, 0, 0, ColorPicker.spectrumCanvas.height],
    colorStop_ar: [
      [0, "rgba(255, 255, 255, 1)"],
      [0.02, "rgba(255, 255, 255, 1)"],
      [0.25, "rgba(255, 255, 255, 0)"],
      [0.3, "rgba(0, 0, 0, 0)"],
      [0.5, "rgba(0, 0, 0, 0)"],
      [0.66, "rgba(0, 0, 0, 0.2)"],
      [0.8, "rgba(0, 0, 0, 0.33)"],
      [0.995, "rgba(0, 0, 0, 1)"],
      [1, "rgba(0, 0, 0, 1)"]
    ]
  });
  ColorPicker.blockify();
  Elements.mainContainer.appendChild(ColorPicker.spectrumCanvas);
};

ColorPicker.blockify = function () {
  var src_data,
    tempImageData,
    tempImageData_ar,
    sourcePixelRGBA,
    destByte,
    sourceByte,
    i,
    j,
    y,
    x;

  // First grab original image data from canvas
  src_data = ColorPicker.spectrumCanvas
    .getContext("2d")
    .getImageData(
      0,
      0,
      ColorPicker.spectrumCanvas.width,
      ColorPicker.spectrumCanvas.height
    ).data;

  // Enlarge canvas
  ColorPicker.spectrumCanvas.width =
    DDD.COLORPICKER_UNSCALED_CANVAS_WIDTH * DDD.COLORPICKER_CANVAS_BLOCKINESS;
  ColorPicker.spectrumCanvas.height =
    DDD.COLORPICKER_UNSCALED_CANVAS_HEIGHT * DDD.COLORPICKER_CANVAS_BLOCKINESS;

  // Make new ImageData object to contain larger blocky image
  tempImageData = ColorPicker.spectrumCanvas
    .getContext("2d")
    .createImageData(ColorPicker.spectrumCanvas.width, ColorPicker.spectrumCanvas.height);
  tempImageData_ar = tempImageData.data;

  destByte = 0;
  for (y = 0; y < DDD.COLORPICKER_UNSCALED_CANVAS_HEIGHT; y++) {
    for (i = 0; i < DDD.COLORPICKER_CANVAS_BLOCKINESS; i++) {
      for (x = 0; x < DDD.COLORPICKER_UNSCALED_CANVAS_WIDTH; x++) {
        sourcePixelRGBA = 4 * (y * DDD.COLORPICKER_UNSCALED_CANVAS_WIDTH + x);
        for (j = 0; j < DDD.COLORPICKER_CANVAS_BLOCKINESS; j++) {
          sourceByte = sourcePixelRGBA;
          tempImageData_ar[destByte++] = src_data[sourceByte++];
          tempImageData_ar[destByte++] = src_data[sourceByte++];
          tempImageData_ar[destByte++] = src_data[sourceByte++];
          tempImageData_ar[destByte++] = src_data[sourceByte++];
        }
      }
    }
  }
  ColorPicker.spectrumCanvas.getContext("2d").putImageData(tempImageData, 0, 0);
};

ColorPicker.addGradient = function (_ob) {
  var i,
    colorStop,
    gradient = ColorPicker.spectrumCanvas
      .getContext("2d")
      .createLinearGradient(..._ob.size_ar);

  for (i = 0; i < _ob.colorStop_ar.length; i++) {
    colorStop = _ob.colorStop_ar[i];
    gradient.addColorStop(...colorStop);
  }

  ColorPicker.spectrumCanvas.getContext("2d").fillStyle = gradient;
  ColorPicker.spectrumCanvas
    .getContext("2d")
    .fillRect(0, 0, ColorPicker.spectrumCanvas.width, ColorPicker.spectrumCanvas.height);
};

ColorPicker.setRelativeCoordsFromEvent = function (_event) {
  ColorPicker.relativeCoords = {};
  if (_event.touches) {
    ColorPicker.relativeCoords.x =
      (_event.touches[0].pageX - ColorPicker.spectrumCanvas.offsetLeft) *
      ColorPicker.scaleXFactor;
    ColorPicker.relativeCoords.y =
      (_event.touches[0].pageY - ColorPicker.spectrumCanvas.offsetTop) *
      ColorPicker.scaleYFactor;
    _event.stopPropagation();
    _event.preventDefault();
  } else {
    ColorPicker.relativeCoords.x =
      (_event.pageX - ColorPicker.spectrumCanvas.offsetLeft) * ColorPicker.scaleXFactor;
    ColorPicker.relativeCoords.y =
      (_event.pageY - ColorPicker.spectrumCanvas.offsetTop) * ColorPicker.scaleYFactor;
  }
};

ColorPicker.setListeningState = function (_ob) {
  var func;
  if (_ob.startListening) {
    func = registerEventHandler;
  } else if (_ob.stopListening) {
    func = unregisterEventHandler;
  }
  func(document, "mouseup", ColorPicker.onMoveEnd, {
    passive: false
  });
  func(document, "touchend", ColorPicker.onMoveEnd, {
    passive: false
  });
  func(document, "mousemove", ColorPicker.onCanvasMoveOver, {passive: false});
  func(document, "touchmove", ColorPicker.onCanvasMoveOver, {passive: false});
  func(document, "touchstart", ColorPicker.onClickWhileOpen, {passive: false});
};

ColorPicker.onClick = function (_event) {
  ColorPicker.isOpen = true;
  ColorPicker.selectedCurrent = _event.currentTarget;

  ColorPicker.selectedCurrentItem = _event.currentTarget.parentNode.parentNode;
  _event.currentTarget.parentNode.parentNode.classList.add(
    DDD.CLASSNAMES.COLORPICKER_SELECTED
  );
  Elements.mainContainer.classList.add(DDD.CLASSNAMES.COLORPICKER_EDITING);
  document.body.classList.add(DDD.CLASSNAMES.COLORPICKER_EDITING);
  ColorPicker.spectrumCanvas.style.display = "inline-block";

  ColorPicker.calculateScaleFactors();
  ColorPicker.setListeningState({startListening: true});
};

ColorPicker.onMoveEnd = function () {
  if (ColorPicker.isOpen) {
    ColorPicker.close();
  }
};

ColorPicker.onClickWhileOpen = function (_event) {
  __("ColorPicker.onClickWhileOpen");
  _event.preventDefault();
  _event.stopPropagation();
};

ColorPicker.onCanvasMoveOver = function (_event) {
  var pixelData;

  if (ColorPicker.spectrumCanvas) {
    ColorPicker.setRelativeCoordsFromEvent(_event);
    pixelData = ColorPicker.spectrumCanvas
      .getContext("2d")
      .getImageData(
        ColorPicker.relativeCoords.x,
        ColorPicker.relativeCoords.y,
        1,
        1
      ).data;

    if (pixelData[3] > 0) {
      ColorPicker.spectrumCanvas.style.cursor = "crosshair";
      ColorPicker.pointerIsOverSpectrum = true;
      ColorPicker.selectedCurrent.style.backgroundColor = rgbToHex(
        pixelData[0],
        pixelData[1],
        pixelData[2]
      );
    } else {
      ColorPicker.spectrumCanvas.style.cursor = "default";
      ColorPicker.pointerIsOverSpectrum = false;
    }

    manualEvent(ColorPicker.selectedCurrent.parentNode, ColorPicker.CHANGEEVENT_ID);
  }
  _event.preventDefault();
  _event.stopPropagation();
};

ColorPicker.onResize = function () {
  ColorPicker.calculateScaleFactors();
};

customElements.define("fg-color-picker", ColorPicker);
export {ColorPicker};
