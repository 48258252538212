/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Button:
  Custom `button` element

---------------------------------------------------------
*/

import {__, getElementFromElementOrID} from "./utils.js";

class Button extends HTMLElement {
  init(_ob) {
    var i,
      label_el,
      // _ob.parent can be the ID(string) of the parent
      // or a reference to the HTML node/element itself
      parent_el = getElementFromElementOrID(_ob.parent);

    label_el = document.createElement("label");
    label_el.innerHTML = _ob.label;
    this.setAttribute("title", _ob.label);
    if (_ob.id) {
      this.id = _ob.id;
      label_el.htmlFor = _ob.id;
    }
    parent_el.appendChild(this);

    if (_ob.class) {
      if (Array.isArray(_ob.class)) {
        for (i = 0; i < _ob.class.length; i++) {
          this.classList.add(_ob.class[i]);
        }
      } else {
        this.classList.add(_ob.class);
      }
    }
    this.ob = _ob;

    if (_ob.disabled) {
      this.setAttribute("disabled", "");
    }

    Button.setElementAsButton(this);

    this.appendChild(label_el);
  }
}

/*
  `setElementAsButton` can be used to set some default behaviours
  on a clickable element

  - Includes it in the tab order
  - Allows 'Enter' or 'Space' keypresses to act as a click
    (by firing a 'click' event for the element)
*/
Button.setElementAsButton = function (_el) {
  _el.setAttribute("tabindex", 0);
};

customElements.define("fg-button", Button);
export {Button};
