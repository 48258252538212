/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";
import * as EL_IDS from "./EL_IDS.js";
import * as GUI_TYPES from "./GUI_TYPES.js";
import * as HELPITEM_IDS from "./HELPITEM_IDS.js";
import * as LABELS from "./LABELS.js";
import * as STRINGS from "./STRINGS.js";

import {HELPITEM_DATA} from "./HELPITEM_DATA.js";

export const GUIDATA_REPORTS = [
  {
    type: GUI_TYPES.FORM,
    id: EL_IDS.REPORTFORM,
    class: CLASSNAMES.FORMMAIN,
    parent: "main",
    hidden: false
  },
  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_DATERANGE_CHOOSER,
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.REPORTFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: EL_IDS.REPORT_DATERANGE_CHOOSER,
    text: STRINGS.REPORT_DATERANGE_CHOOSER_HEADING
  },

  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_STARTDATE_WRAPPER,
    parent: EL_IDS.REPORT_DATERANGE_CHOOSER
  },
  {
    type: GUI_TYPES.DATEPICKER,
    id: EL_IDS.REPORT_STARTDATE,
    heading: STRINGS.REPORT_STARTDATE_HEADING,
    label: STRINGS.REPORT_STARTDATE,
    parent: EL_IDS.REPORT_STARTDATE_WRAPPER
  },
  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_ENDDATE_WRAPPER,
    parent: EL_IDS.REPORT_DATERANGE_CHOOSER
  },
  {
    type: GUI_TYPES.DATEPICKER,
    id: EL_IDS.REPORT_ENDDATE,
    heading: STRINGS.REPORT_ENDDATE_HEADING,
    label: STRINGS.REPORT_ENDDATE,
    parent: EL_IDS.REPORT_ENDDATE_WRAPPER
  },

  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_JOBCLIENT_CHOOSER,
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.REPORTFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: EL_IDS.REPORT_JOBCLIENT_CHOOSER,
    text: STRINGS.REPORT_JOBCLIENT_CHOOSER_HEADING
  },

  {
    type: GUI_TYPES.SPAN,
    parent: EL_IDS.REPORT_JOBCLIENT_CHOOSER,
    id: "report-client-wrapper"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.REMOVEITEMBTN,
    label: LABELS.REMOVEITEM,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.Reports.clearSelection",
        args: [GUI_TYPES.CLIENTCHOOSER],
        scopeID: "main"
      }
    ],
    parent: "report-client-wrapper"
  },
  {
    type: GUI_TYPES.CLIENTCHOOSER,
    parent: "report-client-wrapper"
  },
  {
    type: GUI_TYPES.SPAN,
    parent: EL_IDS.REPORT_JOBCLIENT_CHOOSER,
    id: "report-job-wrapper"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.REMOVEITEMBTN,
    label: LABELS.REMOVEITEM,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.Reports.clearSelection",
        args: [GUI_TYPES.JOBCHOOSER],
        scopeID: "main"
      }
    ],
    parent: "report-job-wrapper"
  },
  {
    type: GUI_TYPES.JOBCHOOSER,
    parent: "report-job-wrapper"
  },

  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_TIMEMONEY_CHECKBOXES,
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.REPORTFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: EL_IDS.REPORT_TIMEMONEY_CHECKBOXES,
    text: STRINGS.REPORT_TIMEMONEY_CHECKBOXES_HEADING
  },
  {
    type: GUI_TYPES.SPAN,
    parent: EL_IDS.REPORT_TIMEMONEY_CHECKBOXES,
    id: "report-money-checkbox-wrapper"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: EL_IDS.REPORT_SHOW_MONEY,
    label: STRINGS.REPORT_SHOW_MONEY,
    checked: true,
    parent: "report-money-checkbox-wrapper"
  },
  {
    type: GUI_TYPES.SPAN,
    parent: EL_IDS.REPORT_TIMEMONEY_CHECKBOXES,
    id: "report-time-checkbox-wrapper"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: EL_IDS.REPORT_SHOW_TIME,
    label: STRINGS.REPORT_SHOW_TIME,
    checked: true,
    parent: "report-time-checkbox-wrapper"
  },

  {
    type: GUI_TYPES.COL,
    id: EL_IDS.REPORT_RESULTS,
    class: [CLASSNAMES.SHEET, CLASSNAMES.HIDDEN],
    parent: EL_IDS.REPORTFORM
  },
  {
    type: GUI_TYPES.TABLE,
    parent: EL_IDS.REPORT_RESULTS
  },
  HELPITEM_DATA[HELPITEM_IDS.REPORTS_MAIN],
  {
    type: GUI_TYPES.METHODCALL,
    methodPathStr: "DontDillyDally.Reports.update",
    scopeID: "main"
  }
];
