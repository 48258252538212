/**
 * @module DataMerger
 *
 * On import, handles merging/conversion of imported with existing data
 * Including various helper functions
 */

import { DDD } from "./DDD/CONST.js";

import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import { Storage } from "./Storage.js";
import { __ } from "./utils.js";

class DataMerger {}

/**
 * DataMerger.getDays.
 *
 * @param {Object} _importedDays - Container of day objects
 *
 * @return {Object} - Imported days merged with existing/stored days
 */
DataMerger.getDays = function (_importedDays) {
  var importedEntryKey,
    curImportedEntry,
    importedDayKey,
    importedDay_ob,
    existingDays = Storage.getObj(TYPES.CONTENT_DAYS);

  console.group("DataMerger.getDays()");

  // loop through all days in importedData_ob
  for (importedDayKey in _importedDays) {
    if (_importedDays.hasOwnProperty(importedDayKey)) {
      importedDay_ob = _importedDays[importedDayKey];
      // if currentDay does not exist on existingDays, create it
      if (!existingDays[importedDayKey]) {
        __(
          "IMPORTING NEW DAY (creating object): " + importedDayKey,
          DDD.LOG_FORMAT.USERDATA
        );
        existingDays[importedDayKey] = {};
      } else {
        __(
          "DAY ALREADY EXISTS: " + importedDayKey,
          DDD.LOG_FORMAT.USERDATA_ALT
        );
      }
      // loop through all entries in currentDay
      for (importedEntryKey in importedDay_ob) {
        if (importedDay_ob.hasOwnProperty(importedEntryKey)) {
          curImportedEntry = importedDay_ob[importedEntryKey];
          // if entry does not exist, add it to day, or if it exists but is
          // shorter than the imported (meaning it may have been added to),
          // overwrite it
          if (
            !existingDays[importedDayKey][importedEntryKey] ||
            JSON.stringify(existingDays[importedDayKey][importedEntryKey])
              .length < JSON.stringify(curImportedEntry).length
          ) {
            __(
              "\tIMPORTING NEW ENTRY (cloning object): " + importedEntryKey,
              DDD.LOG_FORMAT.USERDATA
            );
            // deep clone the entry
            existingDays[importedDayKey][importedEntryKey] = JSON.parse(
              JSON.stringify(curImportedEntry)
            );
          } else {
            __(
              "\tENTRY EXISTS --- KEEP CURRENT ENTRY: " + importedEntryKey,
              DDD.LOG_FORMAT.USERDATA_ALT
            );
          }
        }
      }
    }
  }
  console.groupEnd();
  return existingDays;
};

/**
 * DataMerger.getHelpItemsHidden.
 *
 * If help items have been hidden in imported data or current data, either way
 * it means the user has seen/hidden the help item. So just do a simple merge
 * of both data objects.
 *
 * @param {Object} _importedItems - Container list of (client|job) objects
 */
DataMerger.getHelpItemsHidden = function (_importedItems) {
  var importedItemKey,
    existingItems = Storage.getObj(TYPES.CONTENT_HELPITEMSHIDDEN);

  // loop through all help items in importedData_ob
  for (importedItemKey in _importedItems) {
    if (_importedItems.hasOwnProperty(importedItemKey)) {
      existingItems[importedItemKey] = _importedItems[importedItemKey];
    }
  }
  return existingItems;
};

DataMerger.getJobsClients = function (_importedItems, _itemType) {
  var importedItemKey,
    importedItem_ob,
    existingItems = Storage.getObj(_itemType);

  console.group("DataMerger.getJobsClients()");

  // loop through all items (jobs or clients) in importedData_ob
  for (importedItemKey in _importedItems) {
    if (_importedItems.hasOwnProperty(importedItemKey)) {
      importedItem_ob = _importedItems[importedItemKey];
      // if currentItem does not exist on existingItems, create it
      if (!existingItems[importedItemKey]) {
        __(
          "IMPORTING NEW ITEM (creating object): " +
            importedItemKey +
            " / " +
            importedItem_ob.name,
          DDD.LOG_FORMAT.USERDATA
        );
        existingItems[importedItemKey] = JSON.parse(
          JSON.stringify(importedItem_ob)
        );
      } else {
        __(
          "ITEM ALREADY EXISTS: " +
            importedItemKey +
            " / " +
            importedItem_ob.name,
          DDD.LOG_FORMAT.USERDATA_ALT
        );
      }
    }
  }
  console.groupEnd();
  return existingItems;
};

/**
 * DataMerger.arrayToObject.
 *
 * In old versions of the app, some data (eg lists of clients/jobs) were stored as an array
 * of objects. Each object had an `id` property.
 *
 * Now, instead of an array, a container object is used. Each (client/job) object inside
 * the container is referred to using its id as the key. Its internal id property is
 * redundant so gets deleted.
 *
 * @param {Array} _ar - A legacy array eg [ {id: 'id1', ...}, {id: 'id2', ...} ]
 *
 * @return {Object} eg { id1: {...}, id2: {...} }
 */
DataMerger.arrayToObject = function (_ar) {
  console.group("DataMerger.import()");
  __("IN: " + JSON.stringify(_ar), DDD.LOG_FORMAT.USERDATA);
  var i,
    return_ob = {};
  if (Array.isArray(_ar)) {
    for (i = 0; i < _ar.length; i++) {
      item = _ar[i];
      return_ob[item.id] = item;
      // `id` property is no longer used, now the key of the object is the id
      delete return_ob[item.id].id;
    }
  } else {
    return_ob = _ar;
  }
  __("OUT: " + JSON.stringify(return_ob), DDD.LOG_FORMAT.USERDATA);
  console.groupEnd();
  return return_ob;
};

/**
 * DataMerger.deleteUnusedJobsClients.
 *
 * Find and delete any (jobs|clients) which have a 'used' count of less than 1
 *
 * @param {string} _contentType - Either TYPES.CONTENT_CLIENTS or TYPES.CONTENT_JOBS
 */
DataMerger.deleteUnusedJobsClients = function (_contentType) {
  var itemKey,
    item_ob,
    items = Storage.getObj(_contentType);

  console.group("DataMerger.deleteUnusedJobsClients()");

  for (itemKey in items) {
    if (items.hasOwnProperty(itemKey)) {
      item_ob = items[itemKey];
      // if item (job/client) used count is not 1 or higher, delete it
      if (
        !item_ob[STRINGS.CLIENTSORJOBS_USED] ||
        item_ob[STRINGS.CLIENTSORJOBS_USED] < 1
      ) {
        __(
          "DELETING UNUSED ITEM (" +
            _contentType +
            " - " +
            itemKey +
            " / " +
            item_ob.name +
            ")",
          DDD.LOG_FORMAT.USERDATA
        );
        delete items[itemKey];
      }
    }
  }
  Storage.setObj(_contentType, items);
  console.groupEnd();
};

export { DataMerger };
