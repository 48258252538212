/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as PAGETYPES from "./PAGETYPES.js";

export const PAGEDATA = {
  [PAGETYPES.GUIDE]: {
    title: "Guide",
    intro:
      "dontdillydally helps track your work and finances"
  },

  [PAGETYPES.JOBSANDCLIENTS]: {
    title: "Jobs & Clients",
    intro: "Edit your list of jobs and clients, rename them or change their colours"
  },

  [PAGETYPES.WORKSHEET]: {
    title: "Worksheet",
    intro: "Track your hours, income and expenses"
  },

  [PAGETYPES.CONFIG]: {
    title: "Settings",
    intro: "Change settings to match your preferences"
  },

  [PAGETYPES.REPORTS]: {
    title: "Reports",
    intro: "See total hours/income/expenses for a client or job, over a certain period"
  }
};
