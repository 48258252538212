/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";
import * as DATA_INDICES from "./DATA_INDICES.js";
import * as DATETIME from "./DATETIME.js";
import {DEFAULTS} from "./DEFAULTS.js";
import * as EL_IDS from "./EL_IDS.js";
import {GUIDATA_CONFIG} from "./GUIDATA_CONFIG.js";
import {GUIDATA_GUIDE} from "./GUIDATA_GUIDE.js";
import {GUIDATA_JOBSANDCLIENTS} from "./GUIDATA_JOBSANDCLIENTS.js";
import {GUIDATA_NAVMAIN} from "./GUIDATA_NAVMAIN.js";
import {GUIDATA_REPORTS} from "./GUIDATA_REPORTS.js";
import {GUIDATA_WORKSHEET} from "./GUIDATA_WORKSHEET.js";
import * as GUI_TYPES from "./GUI_TYPES.js";
import * as KEYCODES from "./KEYCODES.js";
import * as LABELS from "./LABELS.js";
import {PAGEDATA} from "./PAGEDATA.js";
import * as PAGETYPES from "./PAGETYPES.js";
import * as PREF_IDS from "./PREF_IDS.js";
import {PREF_OPTS} from "./PREF_OPTS.js";
import * as STRINGS from "./STRINGS.js";
import * as TIMERS from "./TIMERS.js";
import * as TYPES from "./TYPES.js";
import {__} from "./../utils.js";

export const DDD = {
  APP_ID: "DDD",
  SAVE_FILENAME: "dontdillydally-export",

  LOG_FORMAT: {
    ERROR: "font-weight: bold; color: white; background-color: #880000; ",
    STORAGE: "font-weight: bold; color: #006600; ",
    DOM: "background-color: #ffff99; ",
    UI: "background-color: #bbbbdd; color: #000000; ",
    REPORTS: "font-weight: bold; color: #660000; ",
    DATEPICKER: "font-weight: bold; color: #ff6600; ",
    ALERT: "font-weight: bold; color: black; background-color: #dddd00; ",
    WORKSHEET: "color: #000000; background-color: darkorange; ",
    USERDATA: "color: #000000; background-color: pink; ",
    USERDATA_ALT: "color: #000000; background-color: #d2b4de; "
  },

  MAXLENGTH_INPUT_NAMES: 50,
  MAXLENGTH_INPUT_NOTES: 5000,
  SIZE_ATTRIBUTE_INPUT: 30, // this is needed to stop the bug where placeholder (italic) and custom text changes the size of the field and affects layout

  DAYJUMP_TOPPOS_BUFFERPX: 40,

  DAYLENGTH_HOURS_MIN: 1,
  DAYLENGTH_HOURS_MAX: 24,

  UID_LENGTH: 10,
  UID_JOBSCLIENTS_LENGTH: 5,

  COLORPICKER_UNSCALED_CANVAS_SIZE: 32, // Used as both height and width to draw the palette, gets scaled up to fill window so this size can affect blockiness and possibly performance
  COLORPICKER_UNSCALED_CANVAS_WIDTH: 33,
  COLORPICKER_UNSCALED_CANVAS_HEIGHT: 33,
  COLORPICKER_CANVAS_BLOCKINESS: 12,

  // lower number means more frequent updates on loading indicator
  // smoother, but can feel slower
  DAYSDRAWN_UPDATE_FREQ: 50,

  YEARS_TO_DISPLAY: 15, // how many years to display in the date picker

  // References to imports for other classes to use
  CLASSNAMES: CLASSNAMES,
  KEYCODES: KEYCODES,
  STRINGS: STRINGS,
  TYPES: TYPES,
  PAGETYPES: PAGETYPES,
  GUI_TYPES: GUI_TYPES,
  DEFAULTS: DEFAULTS,
  PAGEDATA: PAGEDATA,
  DATETIME: DATETIME,
  EL_IDS: EL_IDS,
  DATA_INDICES: DATA_INDICES,
  TIMERS: TIMERS,
  LABELS: LABELS,
  PREF_IDS: PREF_IDS,
  PREF_OPTS: PREF_OPTS,

  GUIDATA_GUIDE: GUIDATA_GUIDE,
  GUIDATA_JOBSANDCLIENTS: GUIDATA_JOBSANDCLIENTS,
  GUIDATA_NAVMAIN: GUIDATA_NAVMAIN,
  GUIDATA_WORKSHEET: GUIDATA_WORKSHEET,
  GUIDATA_REPORTS: GUIDATA_REPORTS,
  GUIDATA_CONFIG: GUIDATA_CONFIG,

  NOTES_ON_EXPORTED_DATA: [
    "This file/data can be edited with any simple text editor and re-imported into the app.",
    "It is JSON format. To make it more readable, copy and paste the contents into a JSON beautifier (search for one online).",
    "If you've emailed it to yourself, copy/paste the entire contents of the email into a new text file, you can then import the file into the app.",
    "The filename (or email subject) includes the time of export in the following format: YYYY-MM-DD_HH-MM-SS",
    "",
    "Description of data fields:",
    "",
    "# jobs",
    "# clients",
    "  - As defined on the 'Jobs & Clients' page",
    "  - The ID is used to represent the job/client internally and should not be changed",
    "",
    "# days",
    "  - Contains data for all days which have at least one entry added (empty days are not stored)",
    "  - Days are labelled in YYYY-MM-DD format",
    "  - Days are full of [entries]",
    "",
    "# [entries] with random IDs eg 'OL5BWJBkmJ'",
    "  - Each entry contains the following items:",
    "  - ! THE ORDER OF THE ITEMS IS IMPORTANT !",
    "  - [0] A value:",
    "      If starting with 'T' it is a Time value, in minutes",
    "      If starting with 'M' it is a Monetary value, in pennies",
    "  - [1] A client ID (starting with 'C'), or null value",
    "  - [2] A job ID (starting with 'J'), or null value",
    "  - [3] A note if one exists for this entry (if there is no note this item will not exist)",
    "",
    "# helpItemsHidden",
    "  - List of IDs for help items which you've hidden (so they won't be displayed)",
    "",
    "# prefs",
    "  - Settings as saved from the 'Prefs' page",
    "",
    "",
    ""
  ]
};
