/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Reports:
  Produce reports which are a bit like worksheets but only for
  a specific timespan, job and/or client

---------------------------------------------------------
*/

import { DDD } from "./DDD/CONST.js";
import { PREF_OPTS } from "./DDD/PREF_OPTS.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as DATA_INDICES from "./DDD/DATA_INDICES.js";
import * as GUI_TYPES from "./DDD/GUI_TYPES.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import { Elements } from "./Elements.js";
import { Storage } from "./Storage.js";
import {
  daysBetween,
  getFormattedDate,
  getTableFromArrayOfObjects,
  manualEvent,
  minutesToHoursAndMinutesArray,
  prettyDays,
  prettyMoney,
  prettyTimeFromArray,
  __,
} from "./utils.js";
import { WorksheetEntry } from "./WorksheetEntry.js";

class Reports {}

Reports.update = function () {
  // Prevent update before page is fully drawn
  if (Elements.reportResults) {
    Reports.allDaysWithEntries = Storage.getObj(TYPES.CONTENT_DAYS);

    Elements.reportResults.classList.remove(CLASSNAMES.HIDDEN);

    Reports.startDate = new Date(Elements.reportStartDatePicker.dateShortISO);
    Reports.endDate = new Date(Elements.reportEndDatePicker.dateShortISO);
    Reports.curDay = new Date(Elements.reportStartDatePicker.dateShortISO);
    Reports.selectedClientID = Elements.reportClientChooser.value;
    Reports.selectedJobID = Elements.reportJobChooser.value;
    Reports.showTime = Elements.reportShowTimeCheckbox.checked;
    Reports.showMoney = Elements.reportShowMoneyCheckbox.checked;
    console.group("Reports.update()");
    __("Start date: " + Reports.startDate, DDD.LOG_FORMAT.REPORTS);
    __("End date: " + Reports.endDate, DDD.LOG_FORMAT.REPORTS);
    __("Client ID: " + Reports.selectedClientID, DDD.LOG_FORMAT.REPORTS);
    __("Job ID: " + Reports.selectedJobID, DDD.LOG_FORMAT.REPORTS);
    __("Show time: " + Reports.showTime, DDD.LOG_FORMAT.REPORTS);
    __("Show money: " + Reports.showMoney, DDD.LOG_FORMAT.REPORTS);
    console.groupEnd();

    if (Object.keys(Reports.allDaysWithEntries).length === 0) {
      Reports.displayFeedback(STRINGS.REPORT_NOENTRIES);
    } else if (daysBetween(Reports.startDate, Reports.endDate) < 0) {
      Reports.displayFeedback(STRINGS.REPORT_BADDATES);
    } else {
      Reports.matchingEntries = {};
      Reports.addEntriesFromDaysRecursively();
      Reports.drawResults();
    }
  }
};

Reports.addEntriesFromDaysRecursively = function () {
  var entryKey,
    curEntry,
    curEntryType,
    dayKey = Reports.curDay.getShortISO(),
    day_ob = Reports.allDaysWithEntries[dayKey];

  if (day_ob) {
    for (entryKey in day_ob) {
      if (day_ob.hasOwnProperty(entryKey)) {
        curEntry = day_ob[entryKey];
        // we want `null` to match `undefined` here, so == is intentional
        if (
          // Rules for a match:
          // client matches or is empty AND
          // job matches or is empty AND
          (!Reports.selectedClientID ||
            curEntry[DATA_INDICES.CLIENT_ID] == Reports.selectedClientID) &&
          (!Reports.selectedJobID ||
            curEntry[DATA_INDICES.JOB_ID] == Reports.selectedJobID)
        ) {
          // create array to hold entries for this day if it doesn't already exist
          if (!Reports.matchingEntries[dayKey]) {
            Reports.matchingEntries[dayKey] = [];
          }
          curEntryType = WorksheetEntry.getTypeFromCombinedString(
            curEntry[DATA_INDICES.COMBINED_VALUE_STR]
          );
          if (
            (curEntryType === TYPES.ITEM_MONEY && Reports.showMoney === true) ||
            (curEntryType === TYPES.ITEM_TIME && Reports.showTime === true)
          ) {
            Reports.matchingEntries[dayKey].push(curEntry);
          }
        }
      }
    }
  }

  if (Reports.curDay.getTime() !== Reports.endDate.getTime()) {
    Reports.curDay.setUTCDate(Reports.curDay.getUTCDate() + 1);
    Reports.addEntriesFromDaysRecursively();
  }
};

Reports.drawResults = function () {
  var i,
    curEntry,
    curEntryType,
    curEntryValue,
    curValue,
    curValueClassname,
    curDateClassname,
    curClientID,
    curJobID,
    curClientData,
    curJobData,
    curJobClientHTML,
    dayKey,
    entry_ar,
    foundSomething = false,
    table_ar = [];

  Reports.totalTime = 0;
  Reports.totalMoneyIn = 0;
  Reports.totalMoneyOut = 0;

  for (dayKey in Reports.matchingEntries) {
    if (Reports.matchingEntries.hasOwnProperty(dayKey)) {
      entry_ar = Reports.matchingEntries[dayKey];
      for (i = 0; i < entry_ar.length; i++) {
        foundSomething = true;
        curEntry = entry_ar[i];
        curEntryType = WorksheetEntry.getTypeFromCombinedString(
          curEntry[DATA_INDICES.COMBINED_VALUE_STR]
        );
        curValue = WorksheetEntry.getValueFromCombinedString(
          curEntry[DATA_INDICES.COMBINED_VALUE_STR]
        );

        curClientID = curEntry[DATA_INDICES.CLIENT_ID];
        //__("curClientID: " + curClientID, DDD.LOG_FORMAT.REPORTS);
        curClientData = Storage.getObj(TYPES.CONTENT_CLIENTS)[curClientID];
        //__("curClientData: " + JSON.stringify(curClientData), DDD.LOG_FORMAT.REPORTS);
        curJobID = curEntry[DATA_INDICES.JOB_ID];
        //__("curJobID: " + curJobID, DDD.LOG_FORMAT.REPORTS);
        curJobData = Storage.getObj(TYPES.CONTENT_JOBS)[curJobID];
        //__("curJobData: " + JSON.stringify(curJobData), DDD.LOG_FORMAT.REPORTS);

        if (curEntryType === TYPES.ITEM_MONEY) {
          curEntryValue = prettyMoney(curValue, STRINGS.SEPARATOR_CASH, "£");
          curValueClassname = CLASSNAMES.MONEY;
          if (curValue < 0) {
            // stored as a negative so subtract to flip it
            Reports.totalMoneyOut -= curValue;
            curValueClassname += " " + CLASSNAMES.NEGATIVE;
          } else if (curValue > 0) {
            Reports.totalMoneyIn += curValue;
            curValueClassname += " " + CLASSNAMES.POSITIVE;
          } else {
            Reports.totalMoneyIn += curValue;
          }
        } else {
          curValueClassname = CLASSNAMES.HOURS;
          Reports.totalTime += curValue;
          curEntryValue = prettyTimeFromArray(
            minutesToHoursAndMinutesArray(curValue)
          );
        }

        curDateClassname = i ? CLASSNAMES.DATE_REPEAT : CLASSNAMES.DATE_INITIAL;

        curJobClientHTML = "";
        if (curJobData) {
          curJobClientHTML +=
            "<span class='" + curJobID + "'>" + curJobData.name + "</span>";
        }
        if (curClientData) {
          curJobClientHTML +=
            "<span class='" +
            curClientID +
            "'>" +
            curClientData.name +
            "</span>";
        }

        table_ar.push(
          {
            textContent: getFormattedDate(
              new Date(dayKey),
              PREF_OPTS[PREF_IDS.DATE_FORMAT][
                Storage.getPref(PREF_IDS.DATE_FORMAT)
              ].label
            ),
            className: curDateClassname,
          },

          {
            innerHTML: curJobClientHTML !== "" ? curJobClientHTML : "",
            className: curJobClientHTML !== "" ? "" : CLASSNAMES.EMPTY,
          },
          {
            textContent: curEntry[DATA_INDICES.NOTES],
            className:
              curEntry[DATA_INDICES.NOTES] &&
              curEntry[DATA_INDICES.NOTES] !== ""
                ? CLASSNAMES.NOTES
                : CLASSNAMES.EMPTY,
          },
          { textContent: curEntryValue, className: curValueClassname }
        );
      }
    }
  }

  if (foundSomething) {
    Elements.reportResults.innerHTML = "";
    Elements.reportResults.classList.remove(CLASSNAMES.ATTENTION);
    table_ar = Reports.getTotalsContainer().concat(table_ar);
    Elements.reportResults.appendChild(getTableFromArrayOfObjects(table_ar, 4));
  } else {
    Reports.displayFeedback(STRINGS.REPORT_NORESULTS);
  }
};

Reports.displayFeedback = function (_str) {
  Elements.reportResults.innerHTML = "";
  Elements.reportResults.classList.add(CLASSNAMES.ATTENTION);
  manualEvent(document, "requestAnimation", {
    el: Elements.reportResults,
    animClass: CLASSNAMES.ANIM_ATTRACT,
  });
  Elements.reportResults.appendChild(
    getTableFromArrayOfObjects([{ innerHTML: _str }], 1)
  );
};

Reports.getTotalsContainer = function () {
  var ar = [],
    clientData = Storage.getObj(TYPES.CONTENT_CLIENTS)[
      Reports.selectedClientID
    ],
    jobData = Storage.getObj(TYPES.CONTENT_JOBS)[Reports.selectedJobID],
    heading =
      (clientData ? clientData.name + ", " : STRINGS.REPORT_ALLCLIENTS) +
      (jobData ? jobData.name + ", " : STRINGS.REPORT_ALLJOBS) +
      getFormattedDate(
        Reports.startDate,
        PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)]
          .label
      ) +
      "&nbsp;&mdash;&nbsp;" +
      getFormattedDate(
        Reports.endDate,
        PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)]
          .label
      );

  ar.push(
    {
      innerHTML: heading,
      colSpan: 4,
      className: CLASSNAMES.TOTAL_RESULTS_HEADING,
    },
    // when colSpan is used the blank array items/cells need to be sent
    // to keep the count correct (they will be ignored)
    { textContent: "" },
    { textContent: "" },
    { textContent: "" }
  );
  ar.push(
    { textContent: "", colSpan: 4, className: CLASSNAMES.RESULTS_SPACER_SMALL },
    { textContent: "" },
    { textContent: "" },
    { textContent: "" }
  );

  if (Reports.showTime === true) {
    ar.push(
      {
        textContent: STRINGS.HOURSWORKED,
        colSpan: 3,
        className: CLASSNAMES.TOTAL_LABEL,
      },
      { textContent: "" },
      { textContent: "" },
      {
        textContent: prettyTimeFromArray(
          minutesToHoursAndMinutesArray(Reports.totalTime)
        ),
        className: CLASSNAMES.HOURS,
      }
    );
    ar.push(
      {
        textContent: STRINGS.DAYSWORKED,
        colSpan: 3,
        className: CLASSNAMES.TOTAL_LABEL,
      },
      { textContent: "" },
      { textContent: "" },
      {
        innerHTML: prettyDays({
          timeInMins: Reports.totalTime,
          hoursInDay: Storage.getPref(PREF_IDS.DAY_LENGTH_HOURS),
          dayIncrements: Storage.getPref(PREF_IDS.DAY_INCREMENTS),
          dayRoundingType: Storage.getPref(PREF_IDS.DAY_ROUNDING_TYPE),
        }),
        className: CLASSNAMES.HOURS,
      }
    );
    ar.push(
      {
        textContent: "",
        colSpan: 4,
        className: CLASSNAMES.RESULTS_SPACER_SMALL,
      },
      { textContent: "" },
      { textContent: "" },
      { textContent: "" }
    );
  }

  if (Reports.showMoney === true) {
    ar.push(
      {
        textContent: STRINGS.INCOME,
        colSpan: 3,
        className: CLASSNAMES.TOTAL_LABEL,
      },
      { textContent: "" },
      { textContent: "" },
      {
        textContent: prettyMoney(
          Reports.totalMoneyIn,
          STRINGS.SEPARATOR_CASH,
          "£"
        ),
        className: CLASSNAMES.MONEY + " " + CLASSNAMES.POSITIVE,
      }
    );
    ar.push(
      {
        textContent: STRINGS.EXPENDITURE,
        colSpan: 3,
        className: CLASSNAMES.TOTAL_LABEL,
      },
      { textContent: "" },
      { textContent: "" },
      {
        textContent: prettyMoney(
          Reports.totalMoneyOut,
          STRINGS.SEPARATOR_CASH,
          "£"
        ),
        className: CLASSNAMES.MONEY + " " + CLASSNAMES.NEGATIVE,
      }
    );
    ar.push(
      {
        textContent: STRINGS.PROFIT,
        colSpan: 3,
        className: CLASSNAMES.TOTAL_LABEL,
      },
      { textContent: "" },
      { textContent: "" },
      {
        textContent: prettyMoney(
          Reports.totalMoneyIn - Reports.totalMoneyOut,
          STRINGS.SEPARATOR_CASH,
          "£"
        ),
        className:
          Reports.totalMoneyIn === Reports.totalMoneyOut
            ? CLASSNAMES.MONEY
            : Reports.totalMoneyIn < Reports.totalMoneyOut
            ? CLASSNAMES.MONEY + " " + CLASSNAMES.NEGATIVE
            : CLASSNAMES.MONEY + " " + CLASSNAMES.POSITIVE,
      }
    );
  }

  ar.push(
    { textContent: "", colSpan: 4, className: CLASSNAMES.RESULTS_SPACER },
    { textContent: "" },
    { textContent: "" },
    { textContent: "" }
  );
  return ar;
};

Reports.clearSelection = function (_id) {
  if (_id === GUI_TYPES.CLIENTCHOOSER) {
    Elements.reportClientChooser.selectedIndex = 0;
  } else if (_id === GUI_TYPES.JOBCHOOSER) {
    Elements.reportJobChooser.selectedIndex = 0;
  }
  Reports.update();
};

export { Reports };
