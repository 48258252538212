/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";
import * as EL_IDS from "./EL_IDS.js";
import * as GUI_TYPES from "./GUI_TYPES.js";
import * as HELPITEM_IDS from "./HELPITEM_IDS.js";
import * as LABELS from "./LABELS.js";
import * as TYPES from "./TYPES.js";

import {HELPITEM_DATA} from "./HELPITEM_DATA.js";

export const GUIDATA_JOBSANDCLIENTS = [
  {
    type: GUI_TYPES.COL,
    id: EL_IDS.JOBSEXISTING,
    class: [CLASSNAMES.SHEET],
    parent: "main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.ADDITEMBTN,
    label: LABELS.ADDJOB,
    parent: EL_IDS.JOBSEXISTING,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.JobsClients.addItemByType",
        args: [TYPES.CONTENT_JOBS],
        scopeID: EL_IDS.JOBSEXISTING
      }
    ]
  },
  {
    type: GUI_TYPES.H1,
    parent: EL_IDS.JOBSEXISTING,
    text: LABELS.ADDJOB,
  },
  {
    type: GUI_TYPES.UL,
    parent: EL_IDS.JOBSEXISTING,
    class: CLASSNAMES.JOBS
  },
  {
    type: GUI_TYPES.BTN,
    class: [CLASSNAMES.BTNNAV, CLASSNAMES.TEXT_BUTTON],
    label: LABELS.DELETE_UNUSED_JOBS,
    parent: EL_IDS.JOBSEXISTING,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.JobsClients.onDeleteUnusedItemsBtnClick",
        args: [TYPES.CONTENT_JOBS],
        scopeID: "main"
      }
    ]
  },
  
  {
    type: GUI_TYPES.COL,
    id: EL_IDS.CLIENTSEXISTING,
    class: [CLASSNAMES.SHEET],
    parent: "main"
  },
  {
    type: GUI_TYPES.BTN,
    class: CLASSNAMES.ADDITEMBTN,
    label: LABELS.ADDCLIENT,
    parent: EL_IDS.CLIENTSEXISTING,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.JobsClients.addItemByType",
        args: [TYPES.CONTENT_CLIENTS],
        scopeID: EL_IDS.CLIENTSEXISTING
      }
    ]
  },
  {
    type: GUI_TYPES.H1,
    parent: EL_IDS.CLIENTSEXISTING,
    text: LABELS.ADDCLIENT,
  },
  {
    type: GUI_TYPES.UL,
    parent: EL_IDS.CLIENTSEXISTING,
    class: CLASSNAMES.CLIENTS
  },
  {
    type: GUI_TYPES.BTN,
    class: [CLASSNAMES.BTNNAV, CLASSNAMES.TEXT_BUTTON],
    label: LABELS.DELETE_UNUSED_CLIENTS,
    parent: EL_IDS.CLIENTSEXISTING,
    event_ar: [
      {
        eventType: "click",
        methodPathStr: "DontDillyDally.JobsClients.onDeleteUnusedItemsBtnClick",
        args: [TYPES.CONTENT_CLIENTS],
        scopeID: "main"
      }
    ]
  },
  
  {
    type: GUI_TYPES.METHODCALL,
    methodPathStr: "DontDillyDally.JobsClients.drawPage",
    scopeID: "main"
  },
  HELPITEM_DATA[HELPITEM_IDS.JOBSANDCLIENTS_MAIN],
];
