/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as PAGETYPES from "./PAGETYPES.js";
import {PAGEDATA} from "./PAGEDATA.js";

export const MONTHPREV = 'previous month';
export const MONTHNEXT = 'next month';
export const WEEKPREV = 'previous week';
export const WEEKNEXT = 'next week';
export const TODAYJUMPBTN = 'today';
export const TODAY_INDICATOR = 'Today';
export const EDIT_JOBSCLIENTS = 'Edit jobs and clients';
export const ADDITEM = 'add item';
export const ADDCLIENT = 'add new client';
export const ADDJOB = 'add new job';
export const REMOVEITEM = 'remove item';
export const OK = 'OK';
export const CANCEL = 'cancel';

export const WORKSHEET = PAGEDATA[PAGETYPES.WORKSHEET].title;
export const REPORTS = PAGEDATA[PAGETYPES.REPORTS].title;
export const JOBS_AND_CLIENTS = PAGEDATA[PAGETYPES.JOBSANDCLIENTS].title;
export const SETTINGS = PAGEDATA[PAGETYPES.CONFIG].title;
export const TOGGLE_MENU = "Expand / contract menus";
export const SAVE_FILE = "Save to file";
export const MAIL_DATA = "Email data";
export const LOAD_FILE = "Load from file";
export const CLEAR_DATA = "Clear data";
export const SYNC_DATA = "Sync data";

export const DELETE_UNUSED_JOBS = "Delete all unused jobs";
export const DELETE_UNUSED_CLIENTS = "Delete all unused clients";
