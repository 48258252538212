/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/* ---------------------------------------------------------------------------
    GlobalEvents --- handle events globally instead of on individual elements
    to help with performance and maintenance 
  --------------------------------------------------------------------------- */

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as EL_IDS from "./DDD/EL_IDS.js";
import * as PAGETYPES from "./DDD/PAGETYPES.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TIMERS from "./DDD/TIMERS.js";

import { AppUI } from "./AppUI.js";
import { ColorPicker } from "./ColorPicker.js";
import { ColorScheme } from "./ColorScheme.js";
import { DatePicker } from "./DatePicker.js";
import { Elements } from "./Elements.js";
import { JobsClients } from "./JobsClients.js";
import { LinkingLines } from "./LinkingLines.js";
import { LoadingIndicator } from "./LoadingIndicator.js";
import { Page } from "./Page.js";
import { Popover } from "./Popover.js";
import { Reports } from "./Reports.js";
import { Storage } from "./Storage.js";
import { Timers } from "./Timers.js";
import { UserData } from "./UserData.js";
import {
  __,
  forceBrowserReflow,
  grandparentContainsClass,
  manualEvent,
  parentContainsClass,
  registerEventHandler,
  setTextInputHeightByContents,
} from "./utils.js";
import { Worksheet } from "./Worksheet.js";
import { WorksheetTotals } from "./WorksheetTotals.js";

class GlobalEvents {}

GlobalEvents.onFormChange = function () {
  var newColorScheme,
    previousColorScheme = Storage.getPref[PREF_IDS.COLORSCHEME];

  console.group("GlobalEvents::onFormChange");

  newColorScheme = document.getElementById([PREF_IDS.COLORSCHEME]).value;
  if (newColorScheme !== previousColorScheme) {
    ColorScheme.select(newColorScheme);
  }

  UserData.setPrefsByFieldProperties({
    property: "value",
    id_ar: [
      PREF_IDS.TIMESPAN,
      PREF_IDS.DATE_FORMAT,
      PREF_IDS.MINUTE_INCREMENTS,
      PREF_IDS.DAY_LENGTH_HOURS,
      PREF_IDS.DAY_INCREMENTS,
      PREF_IDS.DAY_ROUNDING_TYPE,
      PREF_IDS.COLORSCHEME,
      PREF_IDS.ADD_ENTRY_BEFOREORAFTER,
      PREF_IDS.WEEK_STARTDAY,
      PREF_IDS.WEEKEND_DAYS,
      PREF_IDS.MAILDATA_ADDRESS,
      PREF_IDS.WEBDAV_ADDRESS,
      PREF_IDS.WEBDAV_USER,
      PREF_IDS.WEBDAV_PASS,
    ],
  });
  UserData.setPrefsByFieldProperties({
    property: "checked",
    id_ar: [
      PREF_IDS.ANIMATION_ENABLED,
      PREF_IDS.PRESELECT_LASTUSED,
      PREF_IDS.SHOW_WEEKTOTALS,
      PREF_IDS.SHOW_MONTHTOTALS,
    ],
  });
  UserData.setPrefsByFieldProperties({
    property: "dateShortISO",
    id_ar: [PREF_IDS.YEAR_STARTDATE],
  });

  console.groupEnd();
};

GlobalEvents.onQuickSettingsFormClick = function () {
  UserData.setPrefsByFieldProperties({
    property: "value",
    id_ar: [PREF_IDS.TIMESPAN],
  });
  UserData.setPrefsByFieldProperties({
    property: "checked",
    id_ar: [PREF_IDS.SHOW_WEEKTOTALS, PREF_IDS.SHOW_MONTHTOTALS],
  });

  Timers.clearByID(TIMERS.QUICKSETTINGS_REFRESH_ID);
  Timers.setByID(
    TIMERS.QUICKSETTINGS_REFRESH_ID,
    Page.refresh,
    TIMERS.QUICKSETTINGS_REFRESH_DELAY
  );
  Elements.worksheetFooter.setAttribute("disabled", "");
};

GlobalEvents.onFormSubmit = function (_event) {
  stopPropagation(_event);
};

GlobalEvents.onKeyDown = function (_event) {
  var cancelEvent = false,
    focused_el = document.activeElement,
    labelFor_el;

  //console.group("GlobalEvents::onKeyDown");
  //__({focused_el});
  switch (focused_el.nodeName) {
    case "INPUT":
      if (focused_el.parentNode.nodeName === "FG-NUMBER-INPUT") {
        focused_el.parentNode.onKeyDown(_event);
      }
      break;
    case "TEXTAREA":
      setTextInputHeightByContents(focused_el);
      break;
    case "P":
      if (focused_el.classList.contains(CLASSNAMES.DATE)) {
        if (_event.code === "Enter" || _event.code === "Space") {
          Worksheet.onAddEntryBtnClick.bind(focused_el.parentNode)();
          cancelEvent = true;
        }
      }
      break;
    case "FG-BUTTON":
      if (_event.code === "Enter" || _event.code === "Space") {
        manualEvent(focused_el, "mousedown");
        cancelEvent = true;
      }
      break;
    case "LABEL":
      if (focused_el.htmlFor) {
        if (_event.code === "Enter" || _event.code === "Space") {
          labelFor_el = document.getElementById(focused_el.htmlFor);
          labelFor_el.checked = !labelFor_el.checked;
          cancelEvent = true;
        }
      }
      break;
    case "FG-SELECT":
      if (_event.code === "ArrowUp") {
        focused_el.onArrowUp();
        cancelEvent = true;
      } else if (_event.code === "ArrowDown") {
        focused_el.onArrowDown();
        cancelEvent = true;
      } else if (_event.code === "Enter" || _event.code === "Space") {
        focused_el.blur();
        cancelEvent = true;
      } else if (_event.code === "Escape") {
        focused_el.revert();
        focused_el.blur();
        cancelEvent = true;
      }
      break;
    //case "FG-RADIO-BUTTON-GROUP":
    //  if (_event.code === "ArrowUp") {
    //    focused_el.onArrowUp();
    //    cancelEvent = true;
    //  } else if (_event.code === "ArrowDown") {
    //    focused_el.onArrowDown();
    //    cancelEvent = true;
    //  } else if (_event.code === "Enter" || _event.code === "Space") {
    //    focused_el.blur();
    //    cancelEvent = true;
    //  } else if (_event.code === "Escape") {
    //    focused_el.revert();
    //    focused_el.blur();
    //    cancelEvent = true;
    //  }
    //  break;
    default:
      break;
  }
  /*if (
    Elements.popoverWrapper &&
    !Elements.popoverWrapper.classList.contains(CLASSNAMES.INVISIBLE) &&
    _event.code === "Escape"
  ) {
    Popover.hide();
  }*/
  if (cancelEvent) {
    _event.stopPropagation();
    _event.preventDefault();
  }
  //console.groupEnd();
};

GlobalEvents.onMouseUp = function (_event) {
  var pressed_el = _event.target;

  if (
    pressed_el.nodeName === "BUTTON" &&
    pressed_el.classList.contains(CLASSNAMES.SPINNER_UPBTN_CLASSNAME)
  ) {
    pressed_el.previousSibling.onMouseUp(_event);
  } else if (
    pressed_el.nodeName === "BUTTON" &&
    pressed_el.classList.contains(CLASSNAMES.SPINNER_DOWNBTN_CLASSNAME)
  ) {
    pressed_el.previousSibling.previousSibling.onMouseUp(_event);
  }
};

GlobalEvents.onMouseOut = function (_event) {
  var pressed_el = _event.target;

  if (
    pressed_el.nodeName === "BUTTON" &&
    pressed_el.classList.contains(CLASSNAMES.SPINNER_UPBTN_CLASSNAME)
  ) {
    pressed_el.previousSibling.onMouseOut(_event);
  } else if (
    pressed_el.nodeName === "BUTTON" &&
    pressed_el.classList.contains(CLASSNAMES.SPINNER_DOWNBTN_CLASSNAME)
  ) {
    pressed_el.previousSibling.previousSibling.onMouseOut(_event);
  }
};

GlobalEvents.onMouseDown = function (_event) {
  var pressed_el = _event.target,
    focused_el = document.activeElement,
    pagetype = Storage.getPref(PREF_IDS.PAGETYPE); // refers to current page

  //console.group("GlobalEvents::onMouseDown");
  //__("was focused: " + focused_el.nodeName + " >> pressed: " + pressed_el.nodeName);
  //__("_event.currentTarget: " + _event.currentTarget);
  //__("_event.currentTarget.id: " + _event.currentTarget.id);
  //__("pressed_el: " + pressed_el);
  //__("pressed_el.nodeName: " + pressed_el.nodeName);
  //__("pressed_el.id: " + pressed_el.id);
  //__("pressed_el.classList: " + pressed_el.classList);
  //__("pressed_el.title: " + pressed_el.title);

  switch (pressed_el.nodeName) {
    case "LABEL":
      // label follows input(checkbox) in DOM as a sibling
      if (
        pressed_el.previousSibling &&
        pressed_el.previousSibling.id === EL_IDS.UI_MENU_MINIMISE
      ) {
        // AppUI.menuMinimiseToggle expects click _event but only needs 'target' property to see
        // if the checkbox is checked or not
        AppUI.menuMinimiseToggle({ target: pressed_el.previousSibling });
      }
      break;
    case "FG-OPTION":
      if (focused_el.nodeName === "FG-SELECT") {
        focused_el.onClick(_event);
      }
      break;
    case "TEXTAREA":
      setTextInputHeightByContents(pressed_el);
      break;
    case "INPUT":
      if (pressed_el.classList.contains(CLASSNAMES.DATEPICKER_TARGET_INPUT)) {
        DatePicker.onFocus(_event);
      }
      break;
    case "DIV":
      if (pressed_el.classList.contains(CLASSNAMES.POPOVER_CONTAINER)) {
        Popover.onWrapperClick(_event);
      }
      break;
    case "BUTTON":
      if (pressed_el.classList.contains(CLASSNAMES.SPINNER_UPBTN_CLASSNAME)) {
        pressed_el.previousSibling.onIncreaseMouseDown(_event);
      } else if (
        pressed_el.classList.contains(CLASSNAMES.SPINNER_DOWNBTN_CLASSNAME)
      ) {
        pressed_el.previousSibling.previousSibling.onDecreaseMouseDown(_event);
      }
      break;
    case "FG-BUTTON":
      if (parentContainsClass(pressed_el, CLASSNAMES.HELP_ITEM)) {
        if (pressed_el.classList.contains(CLASSNAMES.REMOVEITEMBTN)) {
          pressed_el.parentNode.classList.add(CLASSNAMES.HIDDEN);
        }
      } else if (
        grandparentContainsClass(
          pressed_el,
          CLASSNAMES.POPOVER_BUTTON_CONTAINER
        )
      ) {
        if (pressed_el.classList.contains(CLASSNAMES.OK_BUTTON)) {
          if (pagetype === PAGETYPES.REPORTS) {
            Reports.update();
          }
        }
      }
      break;
    default:
      if (focused_el.nodeName === "TEXTAREA") {
        // Click outside textarea
        setTextInputHeightByContents(focused_el);
      } else if (
        focused_el.nodeName === "FG-SELECT" ||
        focused_el.nodeName === "INPUT"
      ) {
        focused_el.blur();
      }
      //__("neutral element in flow, focus to move tab index");
      // For mixed keyboard/mouse use, allow tapping an element to shift the current tab index
      // (tabindex must be set on an element before it can be focused so set it to -1)
      pressed_el.tabIndex = -1;
      pressed_el.focus();
      break;
  }

  //console.groupEnd();
};

GlobalEvents.onChange = function (_event) {
  var entry,
    helpItemID,
    helpItemsHidden,
    changed_el = _event.target,
    prefs_ob = Storage.getObj(STRINGS.PREFS),
    pagetype = Storage.getPref(PREF_IDS.PAGETYPE); // refers to current page

  //console.group("GlobalEvents::onChange");
  //__({changed_el});

  if (parentContainsClass(changed_el, CLASSNAMES.HELP_ITEM)) {
    // HELP ITEM BEING HIDDEN/SHOWN
    helpItemsHidden = Storage.getObj(STRINGS.HELPITEMS_HIDDEN);
    helpItemID = changed_el.id.substr(0, changed_el.id.indexOf("-checkbox"));
    if (changed_el.checked) {
      helpItemsHidden[helpItemID] = true;
    } else {
      delete helpItemsHidden[helpItemID];
    }
    Storage.setObj(STRINGS.HELPITEMS_HIDDEN, helpItemsHidden);
  } else if (
    grandparentContainsClass(changed_el, CLASSNAMES.POPOVER_FORM_2COL) ||
    grandparentContainsClass(changed_el, CLASSNAMES.POPOVER_FORM_3COL)
  ) {
    // RADIO BUTTON ON STANDARD POPOVER
    prefs_ob[changed_el.name] = document.getElementById([
      changed_el.value,
    ]).value;
    Storage.setObj(STRINGS.PREFS, prefs_ob);
    manualEvent(document, "popoverradiobuttonchange");
  } else if (pagetype === PAGETYPES.WORKSHEET) {
    if (changed_el.nodeName === "FG-SPINNER") {
      changed_el.parentNode.parentNode.onSpinnerChange(_event);
    } else if (changed_el.nodeName === "FG-NUMBER-INPUT") {
      entry = changed_el.parentNode;
      // recalculate totals (delayed)
      Timers.clearByID(TIMERS.RECALCULATE_TOTALS_ID);
      Timers.setByID(
        TIMERS.RECALCULATE_TOTALS_ID,
        WorksheetTotals.recalculateAll,
        TIMERS.RECALCULATETOTALS_DELAY
      );
    } else if (changed_el.nodeName === "FG-SELECT") {
      entry = changed_el.parentNode;
    } else if (
      changed_el.nodeName === "TEXTAREA" &&
      changed_el.classList.contains(CLASSNAMES.NOTES)
    ) {
      entry = changed_el.entry_el;
      if (changed_el.value.isEmpty()) {
        changed_el.classList.add(CLASSNAMES.PLACEHOLDER);
      } else {
        changed_el.classList.remove(CLASSNAMES.PLACEHOLDER);
      }
    } else if (changed_el.nodeName === "INPUT") {
      if (changed_el.parentNode.parentNode === Elements.worksheetFooter) {
        //__(changed_el.parentNode.parentNode);
        GlobalEvents.onQuickSettingsFormClick();
      }
    }
  } else if (
    pagetype === PAGETYPES.JOBSANDCLIENTS &&
    (grandparentContainsClass(changed_el, CLASSNAMES.CLIENTS) ||
      grandparentContainsClass(changed_el, CLASSNAMES.JOBS))
  ) {
    __("changed_el.nodeName: " + changed_el.nodeName);
    JobsClients.updateDataFromEl(changed_el.parentNode);
  } else if (pagetype === PAGETYPES.REPORTS) {
    if (
      changed_el.classList.contains(CLASSNAMES.DATEPICKER_YEAR) ||
      changed_el.classList.contains(CLASSNAMES.DATEPICKER_MONTH)
    ) {
      DatePicker.drawDays();
    }
    Reports.update();
  } else if (pagetype === PAGETYPES.CONFIG) {
    if (changed_el.nodeName === "FG-SPINNER") {
      changed_el.parentNode.parentNode.onSpinnerChange(_event);
    } else if (
      changed_el.classList.contains(CLASSNAMES.DATEPICKER_YEAR) ||
      changed_el.classList.contains(CLASSNAMES.DATEPICKER_MONTH)
    ) {
      DatePicker.drawDays();
    }
    GlobalEvents.onFormChange();
  } else if (changed_el.onChange) {
    changed_el.onChange();
  }

  if (entry && document.body.contains(changed_el)) {
    //__(entry);
    Worksheet.updateDataFromEntryEl(entry);
  }
  //console.groupEnd();
};

GlobalEvents.onFocus = function (_event) {
  var focused_el = _event.target;
  //console.group("GlobalEvents::onFocus");
  //__({focused_el});
  if (
    focused_el.classList &&
    focused_el.classList.contains(CLASSNAMES.DATEPICKER_TARGET_INPUT)
  ) {
    DatePicker.onFocus(_event);
  } else if (focused_el.onFocus) {
    focused_el.onFocus();
  }
  //console.groupEnd();
};

GlobalEvents.onBlur = function (_event) {
  var blurred_el = _event.target;
  //console.group("GlobalEvents::onBlur");
  //__({blurred_el});
  if (
    blurred_el.parentNode &&
    blurred_el.parentNode.nodeName === "FG-NUMBER-INPUT"
  ) {
    blurred_el.parentNode.acceptKeyboardInput();
  } else if (blurred_el.onBlur) {
    blurred_el.onBlur();
  }
  //console.groupEnd();
};

GlobalEvents.onRequestAnimation = function (_event) {
  if (Storage.getPref(PREF_IDS.ANIMATION_ENABLED)) {
    GlobalEvents.startCSSAnimation(_event.detail.el, _event.detail.animClass);
  }
};

GlobalEvents.onRequestPageRefresh = function (_event) {
  Page.refresh();
};

GlobalEvents.startCSSAnimation = function (_el, _animClass) {
  _el.classList.remove(_animClass);
  forceBrowserReflow();
  _el.classList.add(_animClass);
};

GlobalEvents.onHashChange = function () {
  Page.select(decodeURIComponent(location.hash.substring(1)));
};

GlobalEvents.onStylesheetLoaded = function () {
  document.body.style.display = "initial";
};

// As well as onPageLoaded, some methods are set up to call when
// specific pages load via the pages' respective GUIDATA file
GlobalEvents.onPageLoaded = function () {
  AppUI.setMinimiseState();
  if (Storage.getPref(PREF_IDS.PAGETYPE) === PAGETYPES.WORKSHEET) {
    Elements.navWorksheet.classList.remove(CLASSNAMES.HIDDEN);
  }
};

GlobalEvents.onColorPickerChange = function (_event) {
  JobsClients.updateColorsFromPickers(_event);
};

GlobalEvents.onResize = function () {
  var pagetype = Storage.getPref(PREF_IDS.PAGETYPE);
  if (pagetype === PAGETYPES.JOBSANDCLIENTS) {
    ColorPicker.onResize();
  } else if (pagetype === PAGETYPES.GUIDE) {
    LinkingLines.onResize();
  }
};

GlobalEvents.onScroll = function () {
  var pagetype = Storage.getPref(PREF_IDS.PAGETYPE);
  if (pagetype === PAGETYPES.GUIDE) {
    LinkingLines.onScroll();
  }
};

GlobalEvents.startListening = function () {
  registerEventHandler(
    document,
    "requestAnimation",
    GlobalEvents.onRequestAnimation
  );
  registerEventHandler(
    document,
    "requestPageRefresh",
    GlobalEvents.onRequestPageRefresh
  );

  registerEventHandler(document, "pageLoaded", GlobalEvents.onPageLoaded);
  registerEventHandler(document, "pageLoaded", LoadingIndicator.onPageLoaded);
  registerEventHandler(
    document,
    "pageLoadStarted",
    LoadingIndicator.onPageLoadStarted
  );
  registerEventHandler(document, "pageLoadStarted", Popover.onPageLoadStarted);
  registerEventHandler(document, "pageLoadUpdate", LoadingIndicator.onUpdate);

  registerEventHandler(
    document,
    "stylesheetLoaded",
    GlobalEvents.onStylesheetLoaded
  );

  registerEventHandler(document, "mousedown", GlobalEvents.onMouseDown, true);
  registerEventHandler(document, "mouseup", GlobalEvents.onMouseUp, true);
  registerEventHandler(document, "mouseout", GlobalEvents.onMouseOut, true);
  registerEventHandler(document, "keydown", GlobalEvents.onKeyDown, true);
  registerEventHandler(document, "focus", GlobalEvents.onFocus, true);
  registerEventHandler(document, "blur", GlobalEvents.onBlur, true);
  registerEventHandler(document, "change", GlobalEvents.onChange, true);

  registerEventHandler(window, "hashchange", GlobalEvents.onHashChange);
  registerEventHandler(window, "resize", GlobalEvents.onResize);
  registerEventHandler(window, "scroll", GlobalEvents.onScroll);

  registerEventHandler(
    document,
    ColorPicker.CHANGEEVENT_ID,
    GlobalEvents.onColorPickerChange
  );
};

export { GlobalEvents };
