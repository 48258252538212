/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  ColorScheme:
  Mange colour schemes

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";
import {Elements} from "./Elements.js";
import {Storage} from "./Storage.js";
import {manualEvent, registerEventHandler, __} from "./utils.js";

class ColorScheme {}

ColorScheme.select = function (_schemeID) {
  __("ColorScheme.selectColorScheme()");
  var href,
    colorSchemes = DDD.PREF_OPTS[DDD.PREF_IDS.COLORSCHEME],
    scheme = colorSchemes[_schemeID];

  __({colorSchemes});
  __({_schemeID});
  __({scheme});

  // integrate with system dark/light mode
  if (ColorScheme.lightPref === undefined) {
    // first run
    ColorScheme.lightPref = window.matchMedia("(prefers-color-scheme: light)");
    ColorScheme.darkPref = window.matchMedia("(prefers-color-scheme: dark)");
    ColorScheme.noPref = window.matchMedia("(prefers-color-scheme: no-preference)");
    ColorScheme.lightPref.addListener(ColorScheme.onDarkModePrefChange);
    ColorScheme.darkPref.addListener(ColorScheme.onDarkModePrefChange);
    ColorScheme.noPref.addListener(ColorScheme.onDarkModePrefChange);
    registerEventHandler(Elements.themeLink, "load", ColorScheme.onStylesheetLoad);
  }

  if (_schemeID === DDD.PREF_OPTS.COLORSCHEME_AUTO) {
    if (ColorScheme.darkPref.matches) {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_DARK].href;
    } else {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_LIGHT].href;
    }
  } else if (_schemeID === DDD.PREF_OPTS.COLORSCHEME_MONO_AUTO) {
    if (ColorScheme.darkPref.matches) {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_MONO_DARK].href;
    } else {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_MONO_LIGHT].href;
    }
  } else if (_schemeID === DDD.PREF_OPTS.COLORSCHEME_INK_AUTO) {
    if (ColorScheme.darkPref.matches) {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_INK_DARK].href;
    } else {
      href = colorSchemes[DDD.PREF_OPTS.COLORSCHEME_INK_LIGHT].href;
    }
  } else {
    href = scheme.href;
  }

  if (
    _schemeID === DDD.PREF_OPTS.COLORSCHEME_INK_AUTO ||
    _schemeID === DDD.PREF_OPTS.COLORSCHEME_INK_LIGHT ||
    _schemeID === DDD.PREF_OPTS.COLORSCHEME_INK_DARK
  ) {
    ColorScheme.inkMode = true;
  } else {
    ColorScheme.inkMode = false;
  }

  Elements.themeLink.setAttribute("href", href);
  ColorScheme.setInkModeClass();
};

ColorScheme.setInkModeClass = function () {
  if (ColorScheme.inkMode === true) {
    document.body.classList.add(DDD.CLASSNAMES.COLORSCHEME_INK_MODE);
  } else {
    document.body.classList.remove(DDD.CLASSNAMES.COLORSCHEME_INK_MODE);
  }
};

ColorScheme.onDarkModePrefChange = function () {
  __("lightPref: " + ColorScheme.lightPref.matches);
  __("darkPref: " + ColorScheme.darkPref.matches);
  __("noPref: " + ColorScheme.noPref.matches);
  ColorScheme.select(Storage.getPref(DDD.PREF_IDS.COLORSCHEME));
};

ColorScheme.onStylesheetLoad = function () {
  manualEvent(document, "stylesheetLoaded");
};


export {ColorScheme};
