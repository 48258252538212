/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  WorksheetTotals:
  Calculate and draw various totals/summaries into the worksheet

---------------------------------------------------------
*/

import {PREF_OPTS} from "./DDD/PREF_OPTS.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as DATA_INDICES from "./DDD/DATA_INDICES.js";
import * as DATETIME from "./DDD/DATETIME.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import {Elements} from "./Elements.js";
import {Storage} from "./Storage.js";
import {Worksheet} from "./Worksheet.js";
import {WorksheetEntry} from "./WorksheetEntry.js";

import {
  daysBetween, getFormattedDateLong, getTableFromArrayOfObjects, minutesToHoursAndMinutesArray, padString, prettyDays, prettyMoney
} from "./utils.js";

class WorksheetTotals {}

WorksheetTotals.calculateFromDateSpan = function (_dateEnd, _timeSpan) {
  var i,
    daysToCalculate,
    dayCur,
    dayID,
    hoursMins_ar,
    dayWorkEntries,
    entryID,
    entryCur,
    entryType,
    entryNumberValue,
    daysInMonth,
    dateStart,
    spanContainsData = false,
    return_ob = {timeTotal: 0, incomeTotal: 0, spendTotal: 0},
    allWorkEntries = Storage.getObj(TYPES.CONTENT_DAYS);

  dateStart = new Date(_dateEnd.getTime());

  if (_timeSpan === PREF_OPTS.TIMESPAN_WEEK) {
    dateStart.setDate(_dateEnd.getDate() - DATETIME.DAYSINWEEK + 1);
  } else if (_timeSpan === PREF_OPTS.TIMESPAN_MONTH) {
    daysInMonth = _dateEnd.monthDays();
    dateStart.setDate(_dateEnd.getDate() + 1 - daysInMonth);
  } else if (_timeSpan === PREF_OPTS.TIMESPAN_YEAR) {
    dateStart.setDate(_dateEnd.getDate() - DATETIME.DAYSINYEAR);
  }
  daysToCalculate = daysBetween(dateStart, _dateEnd);
  dayCur = new Date(dateStart.getTime());

  for (i = 0; i <= daysToCalculate; i++) {
    dayID = dayCur.getShortISO();
    dayWorkEntries = allWorkEntries[dayID];
    if (dayWorkEntries) {
      for (entryID in dayWorkEntries) {
        entryCur = dayWorkEntries[entryID];
        // first char of value denotes type eg T-ime or M-oney
        entryType = WorksheetEntry.getTypeFromCombinedString(
          entryCur[DATA_INDICES.COMBINED_VALUE_STR]
        );
        entryNumberValue = WorksheetEntry.getValueFromCombinedString(
          entryCur[DATA_INDICES.COMBINED_VALUE_STR]
        );

        if (entryType === TYPES.ITEM_MONEY) {
          if (entryNumberValue < 0) {
            return_ob.spendTotal += entryNumberValue;
          } else {
            return_ob.incomeTotal += entryNumberValue;
          }
          if (entryNumberValue) {
            spanContainsData = true;
          }
        } else if (entryType === TYPES.ITEM_TIME) {
          return_ob.timeTotal += entryNumberValue;
          if (return_ob.timeTotal) {
            spanContainsData = true;
          }
        }
      }
    }
    dayCur.setDate(dayCur.getDate() + 1);
  }
  return_ob.spanContainsData = spanContainsData;

  return_ob.timeInMins = return_ob.timeTotal;

  // convert minutes to hh/mm
  hoursMins_ar = minutesToHoursAndMinutesArray(return_ob.timeInMins);
  // ensure padString gets sent a String rather than a Number
  return_ob.timeTotal = padString("" + hoursMins_ar[0], STRINGS.PAD_DOUBLEDIGIT);
  return_ob.timeTotal += STRINGS.SEPARATOR_TIME;
  return_ob.timeTotal += padString("" + hoursMins_ar[1], STRINGS.PAD_DOUBLEDIGIT);
  return return_ob;
};

WorksheetTotals.draw = function (_data_ob) {
  var heading_el = document.createElement("h5"),
    subheading_el = document.createElement("h6"),
    table_el = getTableFromArrayOfObjects(
      [
        {textContent: STRINGS.HOURSWORKED},
        {textContent: STRINGS.NUMBERS_EMPTY, className: CLASSNAMES.TOTALHRSWORKED},
        //
        {textContent: STRINGS.DAYSWORKED},
        {textContent: STRINGS.NUMBERS_EMPTY, className: CLASSNAMES.TOTALDAYSWORKED},
        //
        {textContent: STRINGS.INCOME},
        {textContent: STRINGS.NUMBERS_EMPTY, className: CLASSNAMES.TOTALINCOME},
        //
        {textContent: STRINGS.EXPENDITURE},
        {textContent: STRINGS.NUMBERS_EMPTY, className: CLASSNAMES.TOTALSPEND},
        //
        {textContent: STRINGS.PROFIT},
        {textContent: STRINGS.NUMBERS_EMPTY, className: CLASSNAMES.TOTALPROFIT}
      ],
      2
    ); // last arg is number of columns

  table_el.classList.add(CLASSNAMES.TOTALSCONTAINER);
  table_el.endDate = _data_ob.endDate;
  table_el.timeSpan = _data_ob.timeSpan;

  heading_el.innerHTML = _data_ob.heading;
  _data_ob.parent_el.appendChild(heading_el);

  if (_data_ob.subheading) {
    subheading_el.innerHTML = _data_ob.subheading;
    _data_ob.parent_el.appendChild(subheading_el);
  }

  _data_ob.parent_el.appendChild(table_el);
};

WorksheetTotals.drawSheetSummary = function () {
  var summary,
    subheading_str,
    endDate = new Date(),
    weekNumberInfo = Worksheet.startDate.getWeekNumber(),
    totals_el = document.createElement("li"),
    formattedStartDate = getFormattedDateLong(
      Worksheet.startDate,
      PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)]
        .label
    ),
    formattedEndDate = getFormattedDateLong(
      Worksheet.curDrawnDay,
      PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)]
        .label
    );
  totals_el.classList.add(CLASSNAMES.SUMMARY);

  endDate.setTime(Worksheet.curDrawnDay.getTime());

  // how many days do we want to draw?
  switch (Storage.getPref(PREF_IDS.TIMESPAN)) {
    case PREF_OPTS.TIMESPAN_WEEK:
      summary = STRINGS.WORKSHEETSUMMARY_WEEK.replace("$WEEKNUM", weekNumberInfo[1]).replace(
        "$WEEKYEAR",
        weekNumberInfo[0]
      );
      subheading_str = STRINGS.STARTDATE_TO_ENDDATE.replace(
        "$STARTDATE",
        formattedStartDate
      ).replace("$ENDDATE", formattedEndDate);
      break;
    case PREF_OPTS.TIMESPAN_MONTH:
      summary = STRINGS.WORKSHEETSUMMARY_MONTH.replace(
        "$MONTH",
        DATETIME.MONTH_NAMES[Worksheet.startDate.getMonth()]
      ).replace("$YEAR", Worksheet.startDate.getFullYear());
      break;
    case PREF_OPTS.TIMESPAN_YEAR:
      summary = STRINGS.WORKSHEETSUMMARY_YEAR_SPAN.replace(
        "$YEAR1",
        Worksheet.startDate.getFullYear()
      ).replace("$YEAR2", Worksheet.startDate.getFullYear() + 1);
      subheading_str = STRINGS.STARTDATE_TO_ENDDATE.replace(
        "$STARTDATE",
        formattedStartDate
      ).replace("$ENDDATE", formattedEndDate);

      break;
    default:
      break;
  }

  WorksheetTotals.draw({
    heading: summary,
    subheading: subheading_str,
    parent_el: totals_el,
    endDate: endDate,
    timeSpan: Storage.getPref(PREF_IDS.TIMESPAN)
  });

  Worksheet.fragment.appendChild(totals_el);

  // add fragment to DOM
  Elements.worksheet.appendChild(Worksheet.fragment);
};

WorksheetTotals.drawMonth = function () {
  var totals_el = document.createElement("li");
  totals_el.classList.add(CLASSNAMES.TOTALSMONTH);
  WorksheetTotals.draw({
    heading:
      DATETIME.MONTH_NAMES[Worksheet.prevDrawnDay.getMonth()] +
      ", " +
      Worksheet.prevDrawnDay.getFullYear() +
      STRINGS.SUFFIX_TOTALS,
    parent_el: totals_el,
    endDate: new Date(Worksheet.prevDrawnDay.getTime()),
    timeSpan: PREF_OPTS.TIMESPAN_MONTH
  });
  Worksheet.fragment.appendChild(totals_el);
};

WorksheetTotals.drawWeek = function () {
  var totals_el = document.createElement("li"),
    weekNumber = Worksheet.prevDrawnDay.getWeekNumber(); // returns an array [year, week number] as the first week of a year can start in the previous year

  totals_el.classList.add(CLASSNAMES.TOTALSWEEK);

  WorksheetTotals.draw({
    heading: STRINGS.WEEKTOTALS_HEADING.replace("$WEEKNUM", weekNumber[1]).replace(
      "$WEEKYEAR",
      weekNumber[0]
    ),
    parent_el: totals_el,
    endDate: new Date(Worksheet.prevDrawnDay.getTime()),
    timeSpan: PREF_OPTS.TIMESPAN_WEEK
  });
  Worksheet.fragment.appendChild(totals_el);
};

WorksheetTotals.recalculateAll = function () {
  var i,
    curContainer,
    curTotalsData,
    totalsContainers = Elements.worksheet.getElementsByClassName(CLASSNAMES.TOTALSCONTAINER);

  for (i = 0; i < totalsContainers.length; i++) {
    curContainer = totalsContainers[i];
    curTotalsData = WorksheetTotals.calculateFromDateSpan(
      curContainer.endDate,
      curContainer.timeSpan
    );
    curContainer.getElementsByClassName(CLASSNAMES.TOTALINCOME)[0].textContent = prettyMoney(
      curTotalsData.incomeTotal,
      STRINGS.SEPARATOR_CASH,
      "£"
    );
    curContainer.getElementsByClassName(CLASSNAMES.TOTALSPEND)[0].textContent = prettyMoney(
      Math.abs(curTotalsData.spendTotal),
      STRINGS.SEPARATOR_CASH,
      "£"
    );
    curContainer.getElementsByClassName(CLASSNAMES.TOTALHRSWORKED)[0].textContent =
      curTotalsData.timeTotal;
    curContainer.getElementsByClassName(CLASSNAMES.TOTALDAYSWORKED)[0].innerHTML = prettyDays({
      timeInMins: curTotalsData.timeInMins,
      hoursInDay: Storage.getPref(PREF_IDS.DAY_LENGTH_HOURS),
      dayIncrements: Storage.getPref(PREF_IDS.DAY_INCREMENTS),
      dayRoundingType: Storage.getPref(PREF_IDS.DAY_ROUNDING_TYPE)
    });
    curContainer.getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0].textContent = prettyMoney(
      curTotalsData.incomeTotal + curTotalsData.spendTotal,
      STRINGS.SEPARATOR_CASH,
      "£"
    );

    if (curTotalsData.spanContainsData) {
      curContainer.parentNode.classList.remove(CLASSNAMES.DIMMED);
    } else {
      curContainer.parentNode.classList.add(CLASSNAMES.DIMMED);
    }

    if (curTotalsData.incomeTotal + curTotalsData.spendTotal < 0) {
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.add(CLASSNAMES.NEGATIVE);
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.remove(CLASSNAMES.POSITIVE);
    } else if (curTotalsData.incomeTotal + curTotalsData.spendTotal > 0) {
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.add(CLASSNAMES.POSITIVE);
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.remove(CLASSNAMES.NEGATIVE);
    } else {
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.remove(CLASSNAMES.NEGATIVE);
      curContainer
        .getElementsByClassName(CLASSNAMES.TOTALPROFIT)[0]
        .classList.remove(CLASSNAMES.POSITIVE);
    }
  }
};

export {WorksheetTotals};
