/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  HelpItem:
  Help item

---------------------------------------------------------
*/

import * as GUI_TYPES from "./DDD/GUI_TYPES.js";

import {Button} from "./Button.js";
import {DDD} from "./DDD/CONST.js";
import {drawGUIFromAr} from "./DOM.js";
import {Storage} from "./Storage.js";
import {getElementFromElementOrID} from "./utils.js";

class HelpItem extends HTMLElement {
	init(_ob) {
		var i,
			tmp_el,
			parent_el = getElementFromElementOrID(_ob.parent);

		// if this item has been hidden by the user don't do anything
		if (!Storage.getObj(DDD.STRINGS.HELPITEMS_HIDDEN)[_ob.id]) {
			parent_el.appendChild(this);

			this.id = _ob.id;

			this.classList.add(DDD.CLASSNAMES.HELP_ITEM);

			for (i = 0; i < _ob.items.length; i++) {
				tmp_el = document.createElement(_ob.items[i].type);
				tmp_el.innerHTML = _ob.items[i].text;
				this.appendChild(tmp_el);
			}

			if (_ob.demos) {
				for (i = 0; i < _ob.demos.length; i++) {
				_ob.demos[i].type = GUI_TYPES.HELP_DEMO;
				_ob.demos[i].parent = this;
				}
				drawGUIFromAr(_ob.demos);
			}

			// 'remove' button
			tmp_el = new Button();
			tmp_el.init({
				class: DDD.CLASSNAMES.REMOVEITEMBTN,
				label: DDD.LABELS.REMOVEITEM,
				parent: this
			});

			// 'dont show again' checkbox/label
			tmp_el = document.createElement("input");
			tmp_el.setAttribute("type", "checkbox");
			tmp_el.name = _ob.id;
			tmp_el.id = _ob.id + "-checkbox";
			this.appendChild(tmp_el);

			tmp_el = document.createElement("label");
			tmp_el.htmlFor = _ob.id + "-checkbox";
			tmp_el.innerHTML = DDD.STRINGS.HELP_DONT_SHOW_AGAIN;
			this.appendChild(tmp_el);
		}
	}
}

customElements.define("fg-help-item", HelpItem);
export {HelpItem};
