/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
	MultiChoiceForm:
	A multiple-choice form to appear on the popover
	Contains a header, footer, and 1 or more radio button groups
*/

import {PREF_OPTS} from "./DDD/PREF_OPTS.js";

import {RadioButtonGroup} from "./RadioButtonGroup.js";
import {Storage} from "./Storage.js";

import {removeChildFromParent} from "./utils.js";

class MultiChoiceForm {}

MultiChoiceForm.create = function (_ob) {
	// Container for all radio button groups
	//  removeChildFromParent(MultiChoiceForm.el);
	MultiChoiceForm.el = document.createElement("div");
	MultiChoiceForm.el.classList.add(_ob.data.classname);
	_ob.parent.appendChild(MultiChoiceForm.el);

	// Elements to be drawn before radio buttons eg 'column' headings
	if (_ob.data.elementsBefore_ar) {
		MultiChoiceForm.createHeader(_ob.data.elementsBefore_ar);
	}

	if (_ob.data.button_ar) {
		MultiChoiceForm.createRadioButtonGroups(_ob.data.button_ar);
	}

	// Elements to be drawn after radio buttons eg 'column' footer/explanations
	if (_ob.data.elementsAfter_ar) {
		MultiChoiceForm.createFooter(_ob.data.elementsAfter_ar);
	}
};

MultiChoiceForm.createHeader = function (_item_ar) {
	var tmpText,
		tmp_el,
		header_el = document.createElement("header");

	MultiChoiceForm.el.appendChild(header_el);
	for (i = 0; i < _item_ar.length; i++) {
		tmpText = _item_ar[i];
		if (tmpText) {
			tmp_el = document.createElement("h3");
			tmp_el.innerText = tmpText;
		} else {
			tmp_el = document.createElement("span");
		}
		header_el.appendChild(tmp_el);
	}
};

MultiChoiceForm.createFooter = function (_item_ar) {
	var tmpText,
		tmp_el,
		footer_el = document.createElement("footer");

	MultiChoiceForm.el.appendChild(footer_el);
	for (i = 0; i < _item_ar.length; i++) {
		tmpText = _item_ar[i];
		tmp_el = document.createElement("span");
		if (tmpText) {
			tmp_el.innerHTML = tmpText;
		}
		footer_el.appendChild(tmp_el);
	}
};

MultiChoiceForm.createRadioButtonGroups = function (_ar) {
	var i, groupData_ob, group_el;

	// Create all button groups
	for (i = 0; i < _ar.length; i++) {
		groupData_ob = _ar[i];
		groupData_ob.parent = MultiChoiceForm.el;
		groupData_ob.headingElementType = "h4";
		// If this setting is already stored in preferences, select it
		groupData_ob.checkIfMatched = Storage.getPref(groupData_ob.id);
		if (Storage.getPref(groupData_ob.id)) {
			MultiChoiceForm.choiceHasBeenMade = true;
		}
		groupData_ob.options = PREF_OPTS[groupData_ob.id];
		group_el = new RadioButtonGroup();
		group_el.init(groupData_ob);
	}
};

MultiChoiceForm.remove = function () {
	if (MultiChoiceForm.el) {
		MultiChoiceForm.el.innerHTML = "";
		removeChildFromParent(MultiChoiceForm.el);
	}
	MultiChoiceForm.choiceHasBeenMade = false;
};

export {MultiChoiceForm};
