/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  App UI:
  Manages all non page-specific UI stuff

---------------------------------------------------------
*/

import { DDD } from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import { ColorScheme } from "./ColorScheme.js";
import { Elements } from "./Elements.js";
import { Page } from "./Page.js";
import { Popover } from "./Popover.js";
import { PopoverButtons } from "./PopoverButtons.js";
import { Storage } from "./Storage.js";
import { UserData } from "./UserData.js";
import { WebDAV } from "./WebDAV.js";
import {
  __,
  cloneObject,
  createCSSFromOb,
  manualEvent,
  registerEventHandler,
  unregisterEventHandler,
} from "./utils.js";

class AppUI {}

AppUI.navClick = function () {
  __("AppUI.navClick()", DDD.LOG_FORMAT.UI);
  Page.select(arguments[0]);
};

AppUI.setCompactMenu = function (_compact) {
  var i, el;
  __("AppUI.setCompactMenu(" + _compact + ")", DDD.LOG_FORMAT.UI);

  // When the menu and associated elements (toMinimiseOnMinimise_ar) are minimised...
  for (i = 0; i < Elements.toMinimiseOnMinimise_ar.length; i++) {
    el = Elements.toMinimiseOnMinimise_ar[i];
    if (el) {
      if (_compact === true) {
        el.classList.add(CLASSNAMES.MINIMISED);
      } else if (_compact === false) {
        el.classList.remove(CLASSNAMES.MINIMISED);
      }
    }
  }
  // ...some complementary elements (toMaximiseOnMinimise_ar) get maximised
  // (to make use of the reclaimed space)
  for (i = 0; i < Elements.toMaximiseOnMinimise_ar.length; i++) {
    el = Elements.toMaximiseOnMinimise_ar[i];
    if (el) {
      if (_compact === true) {
        el.classList.add(CLASSNAMES.MAXIMISED);
      } else if (_compact === false) {
        el.classList.remove(CLASSNAMES.MAXIMISED);
      }
    }
  }
};

AppUI.menuMinimiseToggle = function (_event) {
  var state = !!_event.target.checked;

  AppUI.setCompactMenu(state);
  Storage.setPref(PREF_IDS.COMPACTMENUS, state);

  // broadcast a resize so eg LinkingLines can be redrawn on guide page
  manualEvent(window, "resize");
};

AppUI.setMinimiseState = function () {
  if (Storage.getPref(PREF_IDS.COMPACTMENUS) === true) {
    Elements.menuMinimiseBtn.checked = true;
    AppUI.setCompactMenu(true);
  } else {
    Elements.menuMinimiseBtn.checked = false;
    AppUI.setCompactMenu(false);
  }
  AppUI.setNavNuttonsEnabledStates();
};

AppUI.setNavNuttonsEnabledStates = function () {
  if (
    Storage.getPref(PREF_IDS.WEBDAV_ADDRESS) &&
    Storage.getPref(PREF_IDS.WEBDAV_USER) &&
    Storage.getPref(PREF_IDS.WEBDAV_PASS)
  ) {
    Elements.syncDataBtn.removeAttribute("disabled");
  } else {
    Elements.syncDataBtn.setAttribute("disabled", "");
  }
  if (Storage.getPref(PREF_IDS.MAILDATA_ADDRESS)) {
    Elements.mailDataBtn.removeAttribute("disabled");
  } else {
    Elements.mailDataBtn.setAttribute("disabled", "");
  }
};

AppUI.onImportFileSelected = function (_event) {
  var file = _event.target.files[0], // FileList object first item (as only single file)
    reader = new FileReader();

  reader.onload = AppUI.onImportFileLoad;
  reader.readAsText(file);

  // the <input> needs to be reset, otherwise the filereader only works once
  // per app load, meaning any data imports after the first one fail
  _event.target.value = "";
};

AppUI.onImportFileLoad = function (_event) {
  var fileData;
  try {
    fileData = JSON.parse(_event.target.result);
    Popover.show({
      title: STRINGS.LOADFILE_CONFIRM.title,
      message: STRINGS.LOADFILE_CONFIRM.message,

      button_ar: [
        {
          label: STRINGS.LOADFILE_CONFIRM.buttonImport,
          func: function () {
            UserData.import(fileData);
            ColorScheme.select(Storage.getPref(PREF_IDS.COLORSCHEME));
          },
        },
        {
          label: STRINGS.LOADFILE_CONFIRM.buttonCancel,
          func: Popover.hide,
        },
      ],

      multiChoiceForm: {
        classname: CLASSNAMES.POPOVER_FORM_3COL,
        elementsBefore_ar: [null, STRINGS.REPLACE, STRINGS.MERGE],
        button_ar: [
          {
            id: PREF_IDS.IMPORTTYPE_DAYS,
            label: "Worksheet Entries",
          },
          {
            id: PREF_IDS.IMPORTTYPE_CLIENTS,
            label: "Clients",
          },
          {
            id: PREF_IDS.IMPORTTYPE_JOBS,
            label: "Jobs",
          },
        ],
        elementsAfter_ar: [
          null,
          STRINGS.LOADFILE_CONFIRM.replaceInfo,
          STRINGS.LOADFILE_CONFIRM.mergeInfo,
        ],
      },
    });
  } catch (_error) {
    Popover.show({
      title: STRINGS.IMPORT_BADFILE.title,
      message: STRINGS.IMPORT_BADFILE.message,
      button_ar: [
        {
          label: STRINGS.IMPORT_BADFILE.buttonClose,
          func: Popover.hide,
        },
      ],
    });
    __(_error, DDD.LOG_FORMAT.ERROR);
  }
};

AppUI.onClearDataBtnClick = function () {
  Popover.show({
    title: STRINGS.DELETEDATA_CONFIRM.title,
    button_ar: [
      {
        label: STRINGS.DELETEDATA_CONFIRM.buttonConfirm,
        func: UserData.deleteByPref,
        disabled: true,
      },
      {
        label: STRINGS.DELETEDATA_CONFIRM.buttonCancel,
        func: Popover.hide,
      },
    ],
    multiChoiceForm: {
      classname: CLASSNAMES.POPOVER_FORM_2COL,
      elementsBefore_ar: [STRINGS.EVERYTHING, STRINGS.ENTRIES_ONLY],
      button_ar: [
        {
          id: PREF_IDS.TEMPORARY_DELETION_PREF,
        },
      ],
      elementsAfter_ar: [
        STRINGS.EVERYTHING_DESCRIPTION,
        STRINGS.ENTRIES_ONLY_DESCRIPTION,
      ],
    },
  });
  registerEventHandler(
    document,
    "popoverradiobuttonchange",
    AppUI.onPopoverRadioButtonChange
  );
};

AppUI.onPopoverRadioButtonChange = function () {
  PopoverButtons.enableAll();
  unregisterEventHandler(
    document,
    "popoverradiobuttonchange",
    AppUI.onPopoverRadioButtonChange
  );
};

AppUI.onSyncBtnClick = function () {
  WebDAV.startSync();
};

AppUI.onSaveBtnClick = function () {
  var el,
    exportData = UserData.getExportDataObject();

  // create a phantom element, attach encoded JSON to it
  el = document.createElement("a");
  el.href =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportData.data, null, "\t"));
  el.download = exportData.filenameDated;
  el.innerHTML = "download json";
  // add it to DOM, click it, then remove it
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

AppUI.onMailDataBtnClick = function () {
  var exportData = UserData.getExportDataObject(),
    link =
      "mailto:" +
      Storage.getPref(PREF_IDS.MAILDATA_ADDRESS) +
      "?subject=" +
      exportData.filenameDated +
      "&body=" +
      encodeURIComponent(JSON.stringify(exportData.data, null, "  "));

  document.location.href = link;
};

AppUI.buildJobsClientsStylesheet = function () {
  var i,
    id,
    css_ob,
    items,
    itemType_ar = ["CONTENT_CLIENTS", "CONTENT_JOBS"];

  //console.group("AppUI.buildJobsClientsStylesheet");

  for (i = 0; i < itemType_ar.length; i++) {
    items = Storage.getObj(TYPES[itemType_ar[i]]);
    //__("items: " + JSON.stringify(items));
    for (id in items) {
      if (items.hasOwnProperty(id)) {
        css_ob = {};
        cloneObject(items[id], css_ob);
        //__("items[" + id + "]: " + JSON.stringify(items[id]));
        css_ob.id = id;
        css_ob.important = true;
        css_ob.stylesheet = DontDillyDally.customStylesheet;
        css_ob.negator = STRINGS.NOT_INKMODE;
        createCSSFromOb(css_ob);
      }
    }
  }
  //console.groupEnd();
};

export { AppUI };
