/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Worksheet:
  Draw some things
---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";
import {PREF_OPTS} from "./DDD/PREF_OPTS.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as DATA_INDICES from "./DDD/DATA_INDICES.js";
import * as DATETIME from "./DDD/DATETIME.js";
import * as LABELS from "./DDD/LABELS.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TIMERS from "./DDD/TIMERS.js";
import * as TYPES from "./DDD/TYPES.js";

import {Button} from "./Button.js";
import {attachEventArrayToElement} from "./DOM.js";
import {Elements} from "./Elements.js";
import {JobClientItem} from "./JobClientItem.js";
import {JobsClients} from "./JobsClients.js";
import {Storage} from "./Storage.js";
import {Timers} from "./Timers.js";
import {
  createElementWithId,
  daysBetween,
  getFormattedDate,
  isEmptyOrNull,
  manualEvent,
  __
} from "./utils.js";
import {WorksheetEntry} from "./WorksheetEntry.js";
import {WorksheetTotals} from "./WorksheetTotals.js";

class Worksheet {}

Worksheet.drawPage = function () {
  var weekdayCur,
    tempDate,
    prefOpts = PREF_OPTS;

  JobsClients.resetAllItemUsedCounts();
  window.scrollTo(0, 0);

  if (Storage.getPref(PREF_IDS.WEEK_STARTDAY) === PREF_OPTS.WEEKSTART_SUNDAY) {
    Worksheet.weekStartDay = 0;
  } else if (
    Storage.getPref(PREF_IDS.WEEK_STARTDAY) === PREF_OPTS.WEEKSTART_MONDAY
  ) {
    Worksheet.weekStartDay = 1;
  }
  __("WEEK START DAY: " + Worksheet.weekStartDay, DDD.LOG_FORMAT.WORKSHEET);

  Worksheet.fragment = document.createDocumentFragment(); // work in a fragment to improve performance

  // Date in browser on which the worksheet was last drawn/refreshed
  // used when jumping to today's date to decide if midnight has passed
  // and we need to draw again
  Worksheet.todaysDate = new Date(Date.now()).setMidnight();
  __("Worksheet.todaysDate: " + Worksheet.todaysDate, DDD.LOG_FORMAT.WORKSHEET);

  // start date
  if (!Worksheet.curDrawnDay) {
    Worksheet.setCurrentDrawnDate(Worksheet.todaysDate);
  }
  __("Worksheet.curDrawnDay: " + Worksheet.curDrawnDay, DDD.LOG_FORMAT.WORKSHEET);

  Worksheet.timeSpan = Storage.getPref(PREF_IDS.TIMESPAN);
  __("Worksheet.timeSpan: " + Worksheet.timeSpan, DDD.LOG_FORMAT.WORKSHEET);
  // how many days do we want to draw?
  switch (Worksheet.timeSpan) {
    case prefOpts.TIMESPAN_WEEK:
      weekdayCur = Worksheet.curDrawnDay.getDay(); // 0 = Sunday, 1 = Monday etc
      __("weekdayCur: " + weekdayCur, DDD.LOG_FORMAT.WORKSHEET);
      Worksheet.curDrawnDay.setDate(
        Worksheet.curDrawnDay.getDate() - weekdayCur + Worksheet.weekStartDay
      ); // first day of week
      __("Worksheet.curDrawnDay: " + Worksheet.curDrawnDay, DDD.LOG_FORMAT.WORKSHEET);
      Worksheet.daysToDraw = DATETIME.DAYSINWEEK;
      break;
    case prefOpts.TIMESPAN_MONTH:
      Worksheet.curDrawnDay.setDate(1);
      __("Worksheet.curDrawnDay: " + Worksheet.curDrawnDay, DDD.LOG_FORMAT.WORKSHEET);
      Worksheet.daysToDraw = Worksheet.curDrawnDay.monthDays();
      __("Worksheet.daysToDraw: " + Worksheet.daysToDraw, DDD.LOG_FORMAT.WORKSHEET);
      break;
    case prefOpts.TIMESPAN_YEAR:
      Worksheet.setCurrentDrawnDate(
        new Date(Storage.getPref(PREF_IDS.YEAR_STARTDATE))
      );
      __("Worksheet.curDrawnDay: " + Worksheet.curDrawnDay, DDD.LOG_FORMAT.WORKSHEET);
      if (
        Worksheet.curDrawnDay.getMonth() === 0 &&
        Worksheet.curDrawnDay.getDate() === 1
      ) {
        Worksheet.customYearStartDate = false;
      } else {
        Worksheet.customYearStartDate = true;
      }
      tempDate = new Date();
      tempDate.setTime(Worksheet.curDrawnDay.getTime());
      __("tempDate: " + tempDate, DDD.LOG_FORMAT.WORKSHEET);
      tempDate.setFullYear(tempDate.getFullYear() + 1);
      __("tempDate: " + tempDate, DDD.LOG_FORMAT.WORKSHEET);
      Worksheet.daysToDraw = daysBetween(Worksheet.curDrawnDay, tempDate);
      __("Worksheet.daysToDraw: " + Worksheet.daysToDraw, DDD.LOG_FORMAT.WORKSHEET);

      break;
    default:
      break;
  }

  Worksheet.updateTodayButtonState();

  Worksheet.daysToDrawTotal = Worksheet.daysToDraw;
  Worksheet.startDate = new Date(Worksheet.curDrawnDay.getTime());

  Worksheet.endDate = new Date(Worksheet.startDate.getTime());
  Worksheet.endDate.setDate(Worksheet.startDate.getDate() + Worksheet.daysToDrawTotal);

  Worksheet.addHeader();

  // draw days asynchronously with a timer
  // to enable us to give UI feedback on progress
  Timers.clearByID(TIMERS.WORKSHEET_DRAWDAY_ID);
  Timers.setByID(TIMERS.WORKSHEET_DRAWDAY_ID, Worksheet.drawNextDay, 0);
};

Worksheet.setCurrentDrawnDate = function (_date) {
  Worksheet.curDrawnDay = _date;
};

Worksheet.drawNextDay = function () {
  var day_str,
    tmp_el,
    day_el,
    date_el,
    entriesContainer_el,
    dayEntries,
    entryID,
    todaysDate,
    weekNumber,
    isToday,
    isFirstDayOfWeek,
    isFirstDayOfMonth,
    isFirstWeekOfYear,
    isLastMonthOfYear,
    isLastDayOfYear,
    isFirstDayOfYear,
    specialDays_ob = {},
    currentYear = Worksheet.curDrawnDay.getFullYear(),
    showWeekTotals = Storage.getPref(PREF_IDS.SHOW_WEEKTOTALS),
    showMonthTotals = Storage.getPref(PREF_IDS.SHOW_MONTHTOTALS),
    allEntries = Storage.getObj(TYPES.CONTENT_DAYS);

  //console.group("Worksheet.drawNextDay");

  weekNumber = Worksheet.curDrawnDay.getWeekNumber(); // returns array [year, weekNum]

  Worksheet.prevDrawnDay = new Date();
  Worksheet.prevDrawnDay.setTime(Worksheet.curDrawnDay.getTime());
  Worksheet.prevDrawnDay.setDate(Worksheet.curDrawnDay.getDate() - 1);

  todaysDate = new Date().setMidnight();
  //__({todaysDate}, DDD.LOG_FORMAT.WORKSHEET);
  isToday = Worksheet.curDrawnDay.getShortISO() === todaysDate.getShortISO();
  isFirstDayOfWeek = Worksheet.curDrawnDay.getDay() === Worksheet.weekStartDay; //getDay is zero-indexed week day
  isFirstDayOfMonth = Worksheet.curDrawnDay.getDate() === 1; //getDate is 1-indexed day of month
  isFirstWeekOfYear = weekNumber[1] === 1;
  isLastMonthOfYear = Worksheet.curDrawnDay.getMonth() === 11; // zero-indexed month number
  isLastDayOfYear = isLastMonthOfYear && Worksheet.curDrawnDay.getDate() === 31;
  isFirstDayOfYear =
    Worksheet.curDrawnDay.getMonth() === 0 && Worksheet.curDrawnDay.getDate() === 1;

  //__("Worksheet.curDrawnDay: " + Worksheet.curDrawnDay, DDD.LOG_FORMAT.WORKSHEET);
  day_str = Worksheet.curDrawnDay.getShortISO();
  //__({day_str}, DDD.LOG_FORMAT.WORKSHEET);

  if (showWeekTotals) {
    Elements.worksheet.classList.add(CLASSNAMES.WEEKLY);
  } else {
    Elements.worksheet.classList.remove(CLASSNAMES.WEEKLY);
  }
  if (showMonthTotals) {
    Elements.worksheet.classList.add(CLASSNAMES.MONTHLY);
  } else {
    Elements.worksheet.classList.remove(CLASSNAMES.MONTHLY);
  }

  day_el = createElementWithId("li", day_str);
  day_el.classList.add(CLASSNAMES.DAY);
  
  day_el.date = new Date();
  day_el.date.setTime(Worksheet.curDrawnDay.getTime());
  //__({day_el}, DDD.LOG_FORMAT.WORKSHEET);

  //__({isToday}, DDD.LOG_FORMAT.WORKSHEET);
  //__(daysBetween(Worksheet.curDrawnDay, todaysDate), DDD.LOG_FORMAT.WORKSHEET);
  //__(Math.floor(daysBetween(Worksheet.curDrawnDay, todaysDate)), DDD.LOG_FORMAT.WORKSHEET);

  //////////
  // Work out what kind of day this is (is it today? first day of a week? first of a month?

  if (isFirstDayOfWeek) {
    day_el.classList.add(CLASSNAMES.WEEKSTART);
    // 'WK', 'WEEK' etc are added in CSS to allow different lengths on different display sizes
    specialDays_ob[CLASSNAMES.WEEKSTART] = weekNumber[1];
    //specialDays_ob[CLASSNAMES.WEEKSTART] = "Week " + weekNumber[1];
    if (showWeekTotals && Worksheet.daysToDraw !== Worksheet.daysToDrawTotal) {
      WorksheetTotals.drawWeek();
    }
    // if this is week 1 and the month is December, it must be the first week of next year
    if (isFirstWeekOfYear && isLastMonthOfYear) {
      specialDays_ob[CLASSNAMES.YEARSTART] = " (" + (currentYear + 1) + ")";
    }
  }

  if (isFirstDayOfYear && !isFirstDayOfWeek) {
    day_el.classList.add(CLASSNAMES.SPECIAL_DAY);
    specialDays_ob[CLASSNAMES.SPECIAL_DAY] = STRINGS.PARTIAL_WEEK_NEWYEAR.replace(
      "$YEAR",
      currentYear - 1
    );
  }
  if (isLastDayOfYear) {
    if (weekNumber[1] === 53 || weekNumber[1] === 1) {
      day_el.classList.add(CLASSNAMES.SPECIAL_DAY);
      specialDays_ob[CLASSNAMES.SPECIAL_DAY] = STRINGS.PARTIAL_WEEK_OLDYEAR.replace(
        "$YEAR",
        currentYear + 1
      );
    }
  }

  if (isToday) {
    day_el.classList.add(CLASSNAMES.TODAY);
    specialDays_ob[STRINGS.TODAY] = LABELS.TODAY_INDICATOR;
  }

  if (isFirstDayOfMonth) {
    day_el.classList.add(CLASSNAMES.MONTHSTART);
    specialDays_ob[CLASSNAMES.MONTHSTART] =
      DATETIME.MONTH_NAMES[Worksheet.curDrawnDay.getMonth()];
    if (showMonthTotals && Worksheet.daysToDraw !== Worksheet.daysToDrawTotal) {
      WorksheetTotals.drawMonth();
    }
  }

  // if 31 Dec is in week 1, the year has 52 weeks, otherwise 53.


  //////////
  // Add calendar-style date details
  specialDays_ob[CLASSNAMES.DATE] = getFormattedDate(
    Worksheet.curDrawnDay,
    PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)].label
  );

  //em_el.textContent = " " + Worksheet.curDrawnDay.getWeekDay(3);
  date_el = Worksheet.getDateDetailsElement(specialDays_ob);
  day_el.appendChild(date_el);
  attachEventArrayToElement(date_el, {
    event_ar: ["click"],
    cancelable: true,
    methodPathStr: "DontDillyDally.Worksheet.onAddEntryBtnClick",
    scopeID: day_str
  });
  date_el.setAttribute("tabindex", 0);

  //////////
  // 'add entry' DUMMY button/icon
  tmp_el = new Button();
  tmp_el.init({
    class: CLASSNAMES.ADDITEMBTN,
    label: STRINGS.ADDITEMBTN_LABEL,
    parent: day_el
  });

  //////////
  // Add entries to day
  entriesContainer_el = document.createElement("ul");

  entriesContainer_el.classList.add("entries");
  day_el.appendChild(entriesContainer_el);

  dayEntries = allEntries[day_str];

  if (dayEntries && !isEmptyOrNull(dayEntries)) {
    for (entryID in dayEntries) {
      WorksheetEntry.create(entriesContainer_el, entryID, dayEntries[entryID]);
    }
  }

  //////////
  // Add day to the document fragment
  // We work on the fragment and add it to the DOM afterwards for performance reasons
  Worksheet.fragment.appendChild(day_el);

  console.groupEnd();

  //////////
  // Draw the next day or finish and add summary
  if (Worksheet.daysToDraw > 1) {
    if (Worksheet.daysToDraw % DDD.DAYSDRAWN_UPDATE_FREQ === 2) {
      // update the DOM (visually means loader bar updates)
      // we only do this every DAYSDRAWN_UPDATE_FREQ ticks as updating the loader bar for
      // each day drawn can mean it ends up taking slightly longer to load
      Timers.clearByID(TIMERS.WORKSHEET_DRAWDAY_ID);
      Timers.setByID(TIMERS.WORKSHEET_DRAWDAY_ID, Worksheet.drawNextDay, 0);
      // re-calling the function above via setTimeout means it will happen *after* the
      // flow of execution leaves this context - so the following increment/decrement happen
      // before the next Worksheet.drawNextDay() call
      Worksheet.curDrawnDay.setDate(Worksheet.curDrawnDay.getDate() + 1);
      Worksheet.daysToDraw--;
    } else {
      Worksheet.curDrawnDay.setDate(Worksheet.curDrawnDay.getDate() + 1);
      Worksheet.daysToDraw--;
      Worksheet.drawNextDay();
    }
  } else {
    // Again use a zero timer otherwise the loading bar doesn't fully fill
    // and feels less fluid
    Timers.clearByID(TIMERS.WORKSHEET_FINISHDRAW_ID);
    Timers.setByID(TIMERS.WORKSHEET_FINISHDRAW_ID, Worksheet.afterAllDaysDrawn, 0);
  }

  Worksheet.updateLoadingIndicator();
};

Worksheet.afterAllDaysDrawn = function () {
  WorksheetTotals.drawSheetSummary();
  manualEvent(document, "pageLoaded");
  WorksheetTotals.recalculateAll();
  Worksheet.jumpToSelectedDate();

  // Keep an eye on date to see if it changes (because page has been left open in browser)
  Timers.clearByID(TIMERS.WORKSHEET_TODAY_CHECK_ID);
  Timers.setByID(
    TIMERS.WORKSHEET_TODAY_CHECK_ID,
    Worksheet.updateToday,
    TIMERS.WORKSHEET_TODAY_CHECK_MS
  );
};

Worksheet.getDateDetailsElement = function (_ob) {
  var key,
    span_el,
    date_el = document.createElement("p"),
    em_el = document.createElement("em");

  date_el.classList.add(CLASSNAMES.DATE);
  em_el.textContent = Worksheet.curDrawnDay.getWeekDay(3);
  if (Worksheet.dayIsWeekend(Worksheet.curDrawnDay.getWeekDay())) {
    date_el.classList.add(CLASSNAMES.WEEKEND);
  }
  date_el.appendChild(em_el);

  // for each key apart from 'month start' add a corresponding <span>
  for (key in _ob) {
    if (Object.prototype.hasOwnProperty.call(_ob, key)) {
      span_el = document.createElement("span");
      span_el.innerHTML = _ob[key];
      date_el.appendChild(span_el);
    }
  }

  console.groupEnd();
  return date_el;
};

Worksheet.dayIsWeekend = function (_day) {
  // binary string representing days from Monday to Sunday as
  // 1 (meaning is a weekend day) or 0 (normal day)
  var weekendDays = Storage.getPref(PREF_IDS.WEEKEND_DAYS),
    // !!+ converts (from right to left) string to number, then number to boolean
    day_ar = {
      Monday: !!+weekendDays[0],
      Tuesday: !!+weekendDays[1],
      Wednesday: !!+weekendDays[2],
      Thursday: !!+weekendDays[3],
      Friday: !!+weekendDays[4],
      Saturday: !!+weekendDays[5],
      Sunday: !!+weekendDays[6]
    };

  return day_ar[_day];
};

Worksheet.addHeader = function () {
  var weekNumberInfo = Worksheet.startDate.getWeekNumber(),
    main_el = document.createElement("h1"),
    sub_el = document.createElement("h2");

  switch (Storage.getPref(PREF_IDS.TIMESPAN)) {
    case PREF_OPTS.TIMESPAN_WEEK:
      main_el.innerHTML = STRINGS.WORKSHEET_WEEK;
      Worksheet.fragment.appendChild(main_el);
      sub_el.innerHTML = STRINGS.WORKSHEETHEADER_WEEK.replace(
        "$WEEKNUM",
        weekNumberInfo[1]
      ).replace("$WEEKYEAR", weekNumberInfo[0]);
      Worksheet.fragment.appendChild(sub_el);
      break;
    case PREF_OPTS.TIMESPAN_MONTH:
      main_el.innerHTML = STRINGS.WORKSHEET_MONTH;
      Worksheet.fragment.appendChild(main_el);
      sub_el.innerHTML = STRINGS.WORKSHEETHEADER_MONTH.replace(
        "$MONTH",
        DATETIME.MONTH_NAMES[Worksheet.startDate.getMonth()]
      ).replace("$YEAR", Worksheet.startDate.getFullYear());
      Worksheet.fragment.appendChild(sub_el);
      break;
    case PREF_OPTS.TIMESPAN_YEAR:
      main_el.innerHTML = STRINGS.WORKSHEET_YEAR;
      if (Worksheet.customYearStartDate) {
        sub_el.innerHTML = STRINGS.WORKSHEETHEADER_YEAR_SPAN.replace(
          "$YEAR1",
          Worksheet.startDate.getFullYear()
        ).replace("$YEAR2", Worksheet.startDate.getFullYear() + 1);
      } else {
        sub_el.innerHTML = STRINGS.WORKSHEETHEADER_YEAR.replace(
          "$YEAR",
          Worksheet.startDate.getFullYear()
        );
      }
      Worksheet.fragment.appendChild(main_el);
      Worksheet.fragment.appendChild(sub_el);
      break;
    default:
      break;
  }
};

Worksheet.onAddEntryBtnClick = function () {
  console.group("Worksheet.onAddEntryBtnClick()");
  WorksheetEntry.create(this.getElementsByClassName("entries")[0]);
  console.groupEnd();
};

Worksheet.onRemoveEntryBtnClick = function () {
  WorksheetEntry.remove(this);
  WorksheetTotals.recalculateAll();
};

Worksheet.updateDataFromEntryEl = function (_entry_el) {
  console.group("Worksheet.updateDataFromEntryEl()");
  var days_ar,
    day_ob,
    entry_ar,
    previouslySelectedClientID,
    previouslySelectedJobID,
    entryID = _entry_el.id,
    isMoneyTaskChk_el = _entry_el.getElementsByClassName(
      CLASSNAMES.MONEYTASK_CHECKBOX
    )[0],
    clientSelect_el = _entry_el.getElementsByClassName(CLASSNAMES.CLIENTSELECT)[0],
    jobSelect_el = _entry_el.getElementsByClassName(CLASSNAMES.JOBSELECT)[0],
    notesInput_el = _entry_el.getElementsByTagName("textarea")[0],
    numberInput_el = _entry_el.getElementsByTagName("fg-number-input")[0],
    selectedClientID = clientSelect_el.value,
    selectedJobID = jobSelect_el.value,
    day_el = _entry_el.parentNode.parentNode,
    dayID = day_el.id;

  __("selectedClientID: " + selectedClientID, DDD.LOG_FORMAT.WORKSHEET);
  __("selectedJobID: " + selectedJobID, DDD.LOG_FORMAT.WORKSHEET);

  days_ar = Storage.getObj(TYPES.CONTENT_DAYS);
  // day_ob contains entries/data for a specific day
  day_ob = days_ar[dayID];
  if (day_ob === undefined) {
    day_ob = {};
  }

  entry_ar = [];
  if (isMoneyTaskChk_el && isMoneyTaskChk_el.checked) {
    entry_ar[DATA_INDICES.COMBINED_VALUE_STR] = WorksheetEntry.getCombinedValueString(
      TYPES.ITEM_MONEY,
      parseFloat(numberInput_el.value)
    );
  } else {
    entry_ar[DATA_INDICES.COMBINED_VALUE_STR] = WorksheetEntry.getCombinedValueString(
      TYPES.ITEM_TIME,
      parseFloat(numberInput_el.value)
    );
  }

  if (day_ob[entryID]) {
    previouslySelectedClientID = day_ob[entryID][DATA_INDICES.CLIENT_ID];
    previouslySelectedJobID = day_ob[entryID][DATA_INDICES.JOB_ID];
  }
  __(
    "previouslySelectedClientID: " + previouslySelectedClientID,
    DDD.LOG_FORMAT.WORKSHEET
  );
  __("previouslySelectedJobID: " + previouslySelectedJobID, DDD.LOG_FORMAT.WORKSHEET);
  if (previouslySelectedClientID !== selectedClientID) {
    if (previouslySelectedClientID) {
      JobClientItem.incrementUsedCount(
        TYPES.CONTENT_CLIENTS,
        previouslySelectedClientID,
        -1
      );
    }
    if (selectedClientID) {
      JobClientItem.incrementUsedCount(TYPES.CONTENT_CLIENTS, selectedClientID, 1);
      JobsClients.moveToTopOfList(selectedClientID, TYPES.CONTENT_CLIENTS);
    }
  }

  if (previouslySelectedClientID) {
    notesInput_el.classList.remove(previouslySelectedClientID);
  }
  if (selectedClientID) {
    if (notesInput_el.value.isEmpty()) {
      notesInput_el.classList.remove(selectedClientID);
    } else {
      notesInput_el.classList.add(selectedClientID);
    }
  }

  if (previouslySelectedJobID !== selectedJobID) {
    if (previouslySelectedJobID) {
      JobClientItem.incrementUsedCount(TYPES.CONTENT_JOBS, previouslySelectedJobID, -1);
    }
    if (selectedJobID) {
      JobClientItem.incrementUsedCount(TYPES.CONTENT_JOBS, selectedJobID, 1);
      JobsClients.moveToTopOfList(selectedJobID, TYPES.CONTENT_JOBS);
    }
  }

  if (previouslySelectedJobID) {
    notesInput_el.classList.remove(previouslySelectedJobID);
  }
  if (selectedJobID) {
    if (notesInput_el.value.isEmpty()) {
      notesInput_el.classList.remove(selectedJobID);
    } else {
      notesInput_el.classList.add(selectedJobID);
    }
  }

  entry_ar[DATA_INDICES.CLIENT_ID] = selectedClientID;
  entry_ar[DATA_INDICES.JOB_ID] = selectedJobID;
  if (!notesInput_el.value.isEmpty()) {
    entry_ar[DATA_INDICES.NOTES] = notesInput_el.value;
  }

  // does this entry contain storable data?
  if (
    numberInput_el.value != 0 ||
    selectedClientID ||
    selectedJobID ||
    notesInput_el.value
  ) {
    day_ob[entryID] = entry_ar; // write work entry to day object
    days_ar[dayID] = day_ob; // write updated day to days array
  } else {
    // delete this entry if it's empty
    delete day_ob[entryID];
    //  if this was the only entry for this day, delete this day
    if (days_ar[dayID] && Object.keys(days_ar[dayID]).length === 0) {
      delete days_ar[dayID];
    }
  }

  Storage.setObj(TYPES.CONTENT_DAYS, days_ar);
  Storage.setPref(PREF_IDS.SELECTED_DATE, dayID);
  console.groupEnd();
};

Worksheet.onIsMoneyTaskChkChange = function () {
  // `this` will resolve to an `entry` element
  var checkbox = this.getElementsByClassName(CLASSNAMES.MONEYTASK_CHECKBOX)[0],
    notesInput = this.getElementsByTagName("textarea")[0],
    numberInput = this.getElementsByTagName("fg-number-input")[0];

  if (checkbox.checked) {
    this.classList.add(CLASSNAMES.MONEY);
    this.classList.remove(CLASSNAMES.HOURS);
    notesInput.setAttribute("placeholder", STRINGS.MONEYNOTES_PLACEHOLDER);
    numberInput.entryType = TYPES.ITEM_MONEY;
  } else {
    this.classList.add(CLASSNAMES.HOURS);
    this.classList.remove(CLASSNAMES.MONEY);
    notesInput.setAttribute("placeholder", STRINGS.JOBNOTES_PLACEHOLDER);
    numberInput.entryType = TYPES.ITEM_TIME;
  }
  numberInput.reset();

  if (document.body.contains(this)) {
    // only recalculate if `this` is in the DOM
    // to avoid lots of recalculating while initially drawing the worksheet into
    // the document fragment
    Worksheet.updateDataFromEntryEl(this);
    WorksheetTotals.recalculateAll();
  }
};

Worksheet.redrawThenJumpTo = function (_date) {
  console.group("Worksheet.redrawThenJumpTo()");
  Storage.setPref(PREF_IDS.SELECTED_DATE, _date.getShortISO());
  __("_date: " + _date.getShortISO(), DDD.LOG_FORMAT.WORKSHEET);
  Worksheet.setCurrentDrawnDate(new Date(_date));
  manualEvent(document, "requestPageRefresh");
  console.groupEnd();
};

Worksheet.jumpToDayElement = function (_day_el) {
  console.group("Worksheet.jumpToDayElement()");
  __("_day_el: " + _day_el, DDD.LOG_FORMAT.WORKSHEET);
  if (_day_el) {
    __("_day_el.id: " + _day_el.id, DDD.LOG_FORMAT.WORKSHEET);
    window.scrollTo(0, _day_el.offsetTop - DDD.DAYJUMP_TOPPOS_BUFFERPX);
    manualEvent(document, "requestAnimation", {
      el: _day_el,
      animClass: CLASSNAMES.ANIM_ATTRACT
    });
  }
  console.groupEnd();
};

Worksheet.jumpToToday = function () {
  var today_el,
    todaysDate = new Date(Date.now()).setMidnight();

  // In case day has changed since worksheet was drawn, and button has been clicked before the timed check (WORKSHEET_TODAY_CHECK_ID) has caught it
  Worksheet.updateToday();
  today_el = document.getElementById(todaysDate.getShortISO());

  if (today_el) {
    Worksheet.jumpToDayElement(document.getElementsByClassName(CLASSNAMES.TODAY)[0]);
  } else {
    __(
      "JUMP TO TODAY: **TODAY DOES NOT EXIST ON THIS WORKSHEET**",
      DDD.LOG_FORMAT.WORKSHEET
    );
  }
};

// check to see if 'today' has changed since the worksheet was drawn
// and if it has re-draw it
Worksheet.updateToday = function () {
  var i,
    todayText_el,
    currentSpan_el,
    dateChildNodes,
    textNode,
    spanCollection,
    todaysDate = new Date(Date.now()).setMidnight(),
    isSameDayAsLastDraw = Worksheet.todaysDate.getShortISO() === todaysDate.getShortISO(),
    previousToday_el = document.getElementsByClassName(CLASSNAMES.TODAY)[0],
    newToday_el = document.getElementById(todaysDate.getShortISO());

  console.group("Worksheet.updateToday");
  __("todaysDate.getShortISO(): " + todaysDate.getShortISO(), DDD.LOG_FORMAT.WORKSHEET);
  __(
    "Worksheet.todaysDate.getShortISO(): " + Worksheet.todaysDate.getShortISO(),
    DDD.LOG_FORMAT.WORKSHEET
  );
  __({isSameDayAsLastDraw}, DDD.LOG_FORMAT.WORKSHEET);
  __({previousToday_el}, DDD.LOG_FORMAT.WORKSHEET);
  __({newToday_el}, DDD.LOG_FORMAT.WORKSHEET);

  if (!isSameDayAsLastDraw) {
    Worksheet.todaysDate = new Date(Date.now()).setMidnight();
    __(
      "JUMP TO TODAY: DAY HAS CHANGED SINCE LAST DRAW **REFRESHING**",
      DDD.LOG_FORMAT.WORKSHEET
    );
    if (previousToday_el) {
      // - remove classname from current TODAY
      previousToday_el.classList.remove(CLASSNAMES.TODAY);
      // - remove span 'TODAY' from current TODAY
      spanCollection = previousToday_el
        .getElementsByClassName(CLASSNAMES.DATE)[0]
        .getElementsByTagName("span");

      for (i = 0; i < spanCollection.length; i++) {
        currentSpan_el = spanCollection[i];
        if (currentSpan_el.innerHTML === LABELS.TODAY_INDICATOR) {
          currentSpan_el.parentNode.removeChild(currentSpan_el);
          break;
        }
      }
    }

    todayText_el = document.createElement("span");
    todayText_el.innerHTML = LABELS.TODAY_INDICATOR;

    // IF TODAY is still on visible sheet
    if (newToday_el) {
      // - add classname to new TODAY
      newToday_el.classList.add(CLASSNAMES.TODAY);

      // - add span 'TODAY' to new TODAY
      dateChildNodes = newToday_el.getElementsByClassName(CLASSNAMES.DATE)[0].childNodes;
      for (i = 0; i < dateChildNodes.length; i++) {
        if (dateChildNodes[i].nodeType == 3) {
          // If it is a text node,
          textNode = dateChildNodes[i];
          break;
        }
      }
      if (textNode && textNode.parentNode) {
        textNode.parentNode.insertBefore(todayText_el, textNode);
      }
    }
    Worksheet.updateTodayButtonState();
  }
  console.groupEnd();

  Timers.clearByID(TIMERS.WORKSHEET_TODAY_CHECK_ID);
  Timers.setByID(
    TIMERS.WORKSHEET_TODAY_CHECK_ID,
    Worksheet.updateToday,
    TIMERS.WORKSHEET_TODAY_CHECK_MS
  );
};

// TODO rather than just the year, this should check the entire span of the worksheet
Worksheet.todayIsInWorksheetYear = function () {
  var daysSinceYearStart,
    daysUntilYearEnd,
    todayIsInYear,
    todaysDate = new Date(Date.now()),
    yearStartDate = new Date(
      Storage.getPref(PREF_IDS.YEAR_STARTDATE)
    ).setMidnight(),
    yearEndDate = new Date();

  yearEndDate.setTime(yearStartDate.getTime());
  yearEndDate.setFullYear(yearEndDate.getFullYear() + 1);

  daysSinceYearStart = daysBetween(yearStartDate, todaysDate);
  daysUntilYearEnd = daysBetween(todaysDate, yearEndDate);
  __("todaysDate: " + todaysDate, DDD.LOG_FORMAT.WORKSHEET);
  __("yearStartDate: " + yearStartDate, DDD.LOG_FORMAT.WORKSHEET);
  __("yearEndDate: " + yearEndDate, DDD.LOG_FORMAT.WORKSHEET);
  __("daysSinceYearStart: " + daysSinceYearStart, DDD.LOG_FORMAT.WORKSHEET);
  __("daysUntilYearEnd: " + daysUntilYearEnd, DDD.LOG_FORMAT.WORKSHEET);

  if (daysSinceYearStart < 0 || daysUntilYearEnd < 0) {
    __("today is NOT in worksheet year", DDD.LOG_FORMAT.WORKSHEET);
    todayIsInYear = false;
  } else {
    __("today IS in worksheet year", DDD.LOG_FORMAT.WORKSHEET);
    todayIsInYear = true;
  }

  return todayIsInYear;
};

Worksheet.updateTodayButtonState = function () {
  if (Worksheet.todayIsInWorksheetYear()) {
    Elements.todayJumpButton.removeAttribute("disabled");
  } else {
    Elements.todayJumpButton.setAttribute("disabled", "");
  }
};

Worksheet.jumpToSelectedDate = function () {
  Worksheet.jumpToDayElement(
    document.getElementById(Storage.getPref(PREF_IDS.SELECTED_DATE))
  );
};

Worksheet.getCurrentViewedDayElement = function () {
  var i,
    day,
    viewedDay,
    scrollTop = window.scrollY,
    dayNodes = document.getElementsByClassName(CLASSNAMES.DAY);

  if (dayNodes.length > 0) {
    for (i = 0; i < dayNodes.length; i++) {
      day = dayNodes[i];
      if (day.offsetTop > scrollTop) {
        viewedDay = day;
        break;
      }
    }
  }
  if (!viewedDay) {
    // if no day is in view just default to last day in the sheet
    viewedDay = dayNodes[dayNodes.length - 1];
  }
  return viewedDay;
};

Worksheet.updateLoadingIndicator = function () {
  manualEvent(document, "pageLoadUpdate", {
    percentDone: 100 - 100 * (Worksheet.daysToDraw / Worksheet.daysToDrawTotal)
  });
};

Worksheet.getOrderedEntryDates = function () {
  var storedDays = Storage.getObj(TYPES.CONTENT_DAYS, {}),
    dayKeys = Object.keys(storedDays);

  dayKeys.sort();
  return dayKeys;
};

Worksheet.getRangeOfDaysWithEntriesArray = function () {
  console.group("Worksheet.getRangeOfDaysWithEntriesArray()");
  var dateIndex,
    orderedEntryDates = Worksheet.getOrderedEntryDates();

  if (orderedEntryDates.length < 1) {
    orderedEntryDates = [new Date().getShortISO()];
  }

  // Reset as these are static and there can be multiple DatePickers so
  // we could have leftovers from previous assignments
  firstEntryDate = undefined;
  lastEntryDate = undefined;

  // Ensure valid dates
  dateIndex = 0;
  while (isNaN(Date.parse(firstEntryDate)) && dateIndex < orderedEntryDates.length) {
    firstEntryDate = new Date(orderedEntryDates[dateIndex]);
    __("firstEntryDate: " + firstEntryDate, DDD.LOG_FORMAT.WORKSHEET);
    dateIndex++;
  }

  dateIndex = orderedEntryDates.length - 1;
  while (isNaN(Date.parse(lastEntryDate)) && dateIndex >= 0) {
    lastEntryDate = new Date(orderedEntryDates[dateIndex]);
    __("lastEntryDate: " + lastEntryDate, DDD.LOG_FORMAT.WORKSHEET);
    dateIndex--;
  }
  console.groupEnd();

  return [firstEntryDate, lastEntryDate];
};

Worksheet.dispose = function () {
  Timers.clearByID(TIMERS.WORKSHEET_DRAWDAY_ID);
  Timers.clearByID(TIMERS.WORKSHEET_TODAY_CHECK_ID);
};

export {Worksheet};
