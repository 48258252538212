/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  CheckboxGroup:
  A group of checkbox buttons

  Value can be set/retrieved via a binary string representing
  the state of each checkbox in the order in which they were added
  with a 1 representing 'checked' and a 0 representing 'unchecked'

---------------------------------------------------------
*/

import {__, getElementFromElementOrID} from "./utils.js";

class CheckboxGroup extends HTMLElement {
  init(_ob) {
    var description_el,
      parent_el = getElementFromElementOrID(_ob.parent);

    parent_el.appendChild(this);

    this.id = _ob.id;
    this.setAttribute("tabindex", 0);

    if (_ob.class) {
      this.classList.add(_ob.class);
    }

    // label/text for this entire group of buttons
    if (_ob.label) {
      description_el = document.createElement("h2");
      this.appendChild(description_el);
      description_el.innerHTML = _ob.label;
    }

    this.options = _ob.options;

    if (_ob.values) {
      this.value = _ob.values;
    }
  }

  clear() {
    var i,
      el,
      elementList = this.querySelectorAll("input,label");

    for (i = 0; i < elementList.length; i++) {
      el = elementList[i];
      el.parentNode.removeChild(el);
    }
  }

  set options(_options) {
    var prop, label_el, button_el;

    this.clear();
    for (prop in _options) {
      label_el = document.createElement("label");
      button_el = document.createElement("input");
      button_el.setAttribute("type", "checkbox");
      button_el.name = this.id;
      button_el.id = prop;
      label_el.htmlFor = prop;
      button_el.value = _options[prop].value;
      label_el.innerHTML = _options[prop].label;
      this.appendChild(button_el);
      this.appendChild(label_el);
    }
  }

  get value() {
    var i,
      curCheckbox,
      binaryString = "",
      checkboxes = this.getElementsByTagName("INPUT");

    for (i = 0; i < checkboxes.length; i++) {
      curCheckbox = checkboxes[i];
      if (curCheckbox.checked) {
        binaryString += "1";
      } else {
        binaryString += "0";
      }
    }
    return binaryString;
  }

  set value(_binaryString) {
    var i,
      curCheckbox,
      checkboxes = this.getElementsByTagName("INPUT");

    for (i = 0; i < checkboxes.length; i++) {
      curCheckbox = checkboxes[i];
      if (_binaryString.substr(i, 1) === "1") {
        curCheckbox.checked = true;
      } else {
        curCheckbox.checked = false;
      }
    }
  }
}

customElements.define("fg-checkbox-group", CheckboxGroup);
export {CheckboxGroup};
