/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  LinkingLines:

  Create and manage a group of linking lines

  - A linking line is a line drawn between two elements on the page
  
  - Lines are updated when the viewport scrolls so they always keep their two elements linked

  Used on the guide page

---------------------------------------------------------
*/

import {Elements} from "./Elements.js";
import {
  createElementWithId, getDistanceBetweenPoints, getStyle, hexOpacityToRGBA, rgbStyleStringToHex, __
} from "./utils.js";

import {DDD} from "./DDD/CONST.js";

class LinkingLines extends HTMLElement {
  init(_ob) {
    console.group("LinkingLines::init");
    __({_ob});
    // PROPERTIES
    // containerID or container_el: where we will be drawing the lines
    // idPairs_ar: array containing pairs of element IDs to be linked
    if (_ob.containerID) {
      LinkingLines.container_el = document.getElementById(_ob.containerID);
    } else {
      LinkingLines.container_el = _ob.container_el;
    }

    LinkingLines.idPairs_ar = _ob.idPairs_ar;
    LinkingLines.updateLines();
  }
}

LinkingLines.onResize = function () {
  LinkingLines.updateLines();
};

LinkingLines.onScroll = function () {
  LinkingLines.updateLines();
};

LinkingLines.dispose = function () {
  var i, currentPair, el1, el2;

  LinkingLines.deleteCanvas();

  if (LinkingLines.idPairs_ar) {
    for (i = 0; i < LinkingLines.idPairs_ar.length; i++) {
      currentPair = LinkingLines.idPairs_ar[i];
      el1 = document.getElementById(currentPair[0]);
      if (el1) {
        el1.classList.remove(DDD.CLASSNAMES.GUIDE_VISIBLE);
      }
      el2 = document.getElementById(currentPair[1]);
      if (el2) {
        el2.classList.remove(DDD.CLASSNAMES.GUIDE_VISIBLE);
      }
    }
  }
};

LinkingLines.deleteCanvas = function () {
  var linesCanvas = document.getElementById(DDD.EL_IDS.LINKINGLINES_CANVAS);
  if (linesCanvas) {
    linesCanvas.parentNode.removeChild(linesCanvas);
  }
};

LinkingLines.resetCanvas = function () {
  if (LinkingLines.container_el) {
    LinkingLines.deleteCanvas();
    LinkingLines.linesCanvas = createElementWithId(
      "canvas",
      DDD.EL_IDS.LINKINGLINES_CANVAS
    );

    LinkingLines.linesCanvas.width = document.documentElement.clientWidth;
    LinkingLines.linesCanvas.height = document.documentElement.clientHeight;
    LinkingLines.container_el.insertBefore(
      LinkingLines.linesCanvas,
      LinkingLines.container_el.childNodes[0]
    );
  }
};

LinkingLines.updateLines = function () {
  var i,
    currentPair,
    el1,
    el2,
    bufferInside = document.documentElement.clientHeight / 7,
    lineOpacity = 0.2,
    lineColor = getStyle(
      Elements.loadingIndicator.getElementsByTagName("em")[0],
      "background-color"
    ),
    hexLineColor = rgbStyleStringToHex(lineColor).substr(1),
    scrollTop = window.scrollY,
    scrollBottom = scrollTop + document.documentElement.clientHeight;

  LinkingLines.resetCanvas();
  if (Array.isArray(LinkingLines.idPairs_ar) && LinkingLines.idPairs_ar.length > 0) {
    for (i = 0; i < LinkingLines.idPairs_ar.length; i++) {
      currentPair = LinkingLines.idPairs_ar[i];
      el1 = document.getElementById(currentPair[0]);
      el2 = document.getElementById(currentPair[1]);
      if (
        //el1.offsetTop > scrollTop + bufferInside / 2 &&
        el1.offsetTop > scrollTop &&
        el1.offsetTop + el1.offsetHeight < scrollBottom - bufferInside
      ) {
        el1.classList.add(DDD.CLASSNAMES.GUIDE_VISIBLE);
        el2.classList.add(DDD.CLASSNAMES.GUIDE_VISIBLE);
        LinkingLines.drawLineBetweenElements(
          currentPair[0],
          currentPair[1],
          hexOpacityToRGBA(hexLineColor, lineOpacity)
        );
      } else {
        el1.classList.remove(DDD.CLASSNAMES.GUIDE_VISIBLE);
        el2.classList.remove(DDD.CLASSNAMES.GUIDE_VISIBLE);
      }
    }
  }
};

LinkingLines.drawLineBetweenElements = function (_id1, _id2, _strokeStyle) {
  var gap = 3,
    el1 = document.getElementById(_id1),
    el2 = document.getElementById(_id2),
    rect1 = el1.getBoundingClientRect(),
    rect2 = el2.getBoundingClientRect(),
    x1 = rect1.left - gap,
    y1 = rect1.top + (rect1.bottom - rect1.top) / 2,
    x2 = rect2.right + gap,
    y2 = rect2.top + (rect2.bottom - rect2.top) / 2,
    ctx = LinkingLines.linesCanvas.getContext("2d");

  // We need to get a colour which matches the page style but this
  // can vary based on user settings and is only stored in CSS
  // So grab a colour from something currently in the DOM
  ctx.strokeStyle = _strokeStyle;
  ctx.lineWidth = 3;
  ctx.lineCap = "round";

  // draw a red line
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
};

LinkingLines.getCurrentViewedElement = function () {
  var i,
    day,
    viewed,
    scrollTop = window.scrollY,
    nodes = document.getElementsByClassName(DDD.CLASSNAMES.DAY);

  if (nodes.length > 0) {
    for (i = 0; i < nodes.length; i++) {
      day = nodes[i];
      if (day.offsetTop > scrollTop) {
        viewed = day;
        break;
      }
    }
  }
  if (!viewed) {
    // if no day is in view just default to last day in the sheet
    viewed = nodes[nodes.length - 1];
  }
  return viewed;
};

customElements.define("fg-linking-lines", LinkingLines);
export {LinkingLines};
