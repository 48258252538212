/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  JobsClients:
  Helper class to do housekeeping for jobs and clients
  Both of these objects are very similar in how they work

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TYPES from "./DDD/TYPES.js";

import {DataMerger} from "./DataMerger.js";
import {JobClientItem} from "./JobClientItem.js";
import {Page} from "./Page.js";
import {Popover} from "./Popover.js";
import {Storage} from "./Storage.js";

import {
  cloneObject,
  createCSSFromOb,
  getStyle,
  rgbStyleStringToHex,
  shortID, __
} from "./utils.js";

class JobsClients {}

JobsClients.drawPage = function () {
  JobsClients.drawExistingItemsFromType(TYPES.CONTENT_JOBS);
  JobsClients.drawExistingItemsFromType(TYPES.CONTENT_CLIENTS);
};

JobsClients.drawExistingItemsFromType = function (_contentType) {
  var id,
    items = Storage.getObj(_contentType);

  for (id in items) {
    if (items.hasOwnProperty(id)) {
      JobClientItem.draw(id, items[id], _contentType);
    }
  }
};

// When an option is selected, client/job `Select` elements add the class of the
// option to their own classList (so that they get coloured according to the selection)
JobsClients.getSelectedIDFromSelectElement = function (_el) {
  var i, curClass, class_ar;
  if (_el) {
    class_ar = _el.className.split(" ");
    for (i = 0; i < class_ar.length; i++) {
      curClass = class_ar[i];
      if (curClass !== CLASSNAMES.JOBSELECT && curClass !== CLASSNAMES.CLIENTSELECT) {
        return curClass;
      }
    }
  }
};

JobsClients.onDeleteUnusedItemsBtnClick = function (_contentType) {
  Popover.show({
    title: STRINGS.DELETE_UNUSED.title,
    message: STRINGS.DELETE_UNUSED.message.replace(
      "$CONTENTTYPE",
      _contentType
    ),
    button_ar: [
      {
        label: STRINGS.DELETE_UNUSED.buttonDelete,
        func: function () {
          DataMerger.deleteUnusedJobsClients(_contentType);
          Page.refresh();
        }
      },
      {
        label: STRINGS.DELETE_UNUSED.buttonCancel,
        func: Popover.hide
      }
    ],
  });
}

JobsClients.resetAllItemUsedCounts = function () {
  var entry, data_ob;

  data_ob = Storage.getObj(TYPES.CONTENT_CLIENTS);
  for (entry in data_ob) {
    data_ob[entry][STRINGS.CLIENTSORJOBS_USED] = 0;
  }
  Storage.setObj(TYPES.CONTENT_CLIENTS, data_ob);

  data_ob = Storage.getObj(TYPES.CONTENT_JOBS);
  for (entry in data_ob) {
    data_ob[entry][STRINGS.CLIENTSORJOBS_USED] = 0;
  }
  Storage.setObj(TYPES.CONTENT_JOBS, data_ob);
};

JobsClients.updateDataFromEl = function (_item_el) {
  var i,
    inputNodes,
    currentNode,
    fg_el,
    bg_el,
    color,
    bgcolor,
    textInput_el,
    items,
    itemType,
    updated_ob,
    css_ob = {};

  textInput_el = _item_el.getElementsByTagName("INPUT")[0];
  inputNodes = _item_el.getElementsByTagName("FG-COLOR-PICKER");
  for (i = 0; i < inputNodes.length; i++) {
    currentNode = inputNodes[i];
    if (currentNode.className.indexOf("bg") !== -1) {
      bg_el = currentNode.nextSibling;
    } else if (currentNode.className.indexOf("fg") !== -1) {
      fg_el = currentNode.nextSibling;
    }
  }

  if (JobsClients.isItemID(_item_el.id, STRINGS.CLIENTID_PREFIX)) {
    itemType = TYPES.CONTENT_CLIENTS;
    dataType = TYPES.DATA_CLIENT;
  } else if (JobsClients.isItemID(_item_el.id, STRINGS.JOBID_PREFIX)) {
    itemType = TYPES.CONTENT_JOBS;
    dataType = TYPES.DATA_JOB;
  }
  items = Storage.getObj(itemType);

  color = getStyle(fg_el, "background-color");
  if (color[0] === "r") {
    color = rgbStyleStringToHex(color);
  }
  bgcolor = getStyle(bg_el, "background-color");
  if (bgcolor[0] === "r") {
    bgcolor = rgbStyleStringToHex(bgcolor);
  }

  updated_ob = {
    name: textInput_el.value,
    color: color,
    bgcolor: bgcolor
  };

  items[_item_el.id] = updated_ob;

  Storage.setObj(itemType, items);
  cloneObject(updated_ob, css_ob);
  css_ob.id = _item_el.id;
  css_ob.important = true;
  css_ob.stylesheet = DontDillyDally.customStylesheet;
  css_ob.negator = STRINGS.NOT_INKMODE;
  createCSSFromOb(css_ob);
};

JobsClients.isItemID = function (_id, _idPrefix) {
  var prefix = _id.substr(0, 1),
    // unary + to convert string to number
    UID = +_id.substr(1),
    isID = false,
    isCorrectPrefix = false,
    isValidUID = false;

  if (prefix === _idPrefix) {
    isCorrectPrefix = true;
  }

  // ID storage has changed since previous versions, we need backwards compatibility
  // New style: UID length +1 Because the UID is added to the single-char prefix C(lients) or J(obs)
  // Old/legacy: UID was an incrementing integer
  if (_id.length === DDD.UID_JOBSCLIENTS_LENGTH + 1 || Number.isInteger(UID)) {
    isValidUID = true;
  }
  if (isCorrectPrefix && isValidUID) {
    isID = true;
  }

  return isID;
};

JobsClients.removeItem = function () {
  var matchedItem, items, contentType, msgContentType, usedCount, confirmQuestion;

  if (JobsClients.isItemID(this.id, STRINGS.CLIENTID_PREFIX)) {
    contentType = TYPES.CONTENT_CLIENTS;
    msgContentType = STRINGS.CLIENT_UI;
  } else if (JobsClients.isItemID(this.id, STRINGS.JOBID_PREFIX)) {
    contentType = TYPES.CONTENT_JOBS;
    msgContentType = STRINGS.JOB_UI;
  }

  items = Storage.getObj(contentType);

  matchedItem = Storage.getObj(contentType)[this.id];
  usedCount = matchedItem[STRINGS.CLIENTSORJOBS_USED];
  // replace some special tags (marked with `$`) in the confirm question to customise it
  confirmQuestion = STRINGS.DELETE_JOBORCLIENT_CONFIRM;
  confirmQuestion = confirmQuestion.replace("$ITEM_TYPE", msgContentType);
  confirmQuestion = confirmQuestion.replace("$USED_COUNT", usedCount);
  if (usedCount === 1) {
    confirmQuestion = confirmQuestion.replace("$PLURAL", "");
  } else {
    confirmQuestion = confirmQuestion.replace("$PLURAL", "s");
  }
  if (!usedCount || confirm(confirmQuestion)) {
    delete items[this.id];
    Storage.setObj(contentType, items);
    this.parentNode.removeChild(this);
  }
};

JobsClients.addItemByType = function (_contentType) {
  var ob, id;

  if (_contentType === TYPES.CONTENT_CLIENTS) {
    ob = JobClientItem.getNewClient();
  } else if (_contentType === TYPES.CONTENT_JOBS) {
    ob = JobClientItem.getNewJob();
  }

  id = ob.tmp_id;
  // id becomes the key of the object on creation, so remove the redundant property
  delete ob.tmp_id;

  if (_contentType === TYPES.CONTENT_CLIENTS) {
    JobClientItem.create(id, ob, TYPES.DATA_CLIENT);
    JobClientItem.draw(id, ob, TYPES.CONTENT_CLIENTS);
  } else if (_contentType === TYPES.CONTENT_JOBS) {
    JobClientItem.create(id, ob, TYPES.DATA_JOB);
    JobClientItem.draw(id, ob, TYPES.CONTENT_JOBS);
  }
};

// Rearrange the (clients or jobs) data object, moving a specific
// item to the top of the property list
JobsClients.moveToTopOfList = function (_id, _contentType) {
  var id,
    items = Storage.getObj(_contentType),
    matchedItemData = JSON.parse(JSON.stringify(items[_id])),
    rearrangedItems = {};

  // first, remove the matched item
  delete items[_id];

  // add the matched item to the top of the new list
  rearrangedItems[_id] = matchedItemData;
  rearrangedItems[_id][STRINGS.CLIENTSORJOBS_LASTUSED] = true;
  __("JobsClients.moveToTopOfList::Remembering last selected (" + _contentType + "): " + _id);
  // then add the remaining items from the original list
  for (id in items) {
    if (items.hasOwnProperty(id)) {
      rearrangedItems[id] = items[id];
      delete rearrangedItems[id][STRINGS.CLIENTSORJOBS_LASTUSED];
    }
  }
  Storage.setObj(_contentType, rearrangedItems);
};

JobsClients.updateColorsFromPickers = function (_event) {
  var i,
    inputNodes,
    textInput_el,
    fg_el,
    bg_el,
    grandParent,
    parent = _event.target.parentNode;

  if (parent.parentNode) {
    grandParent = parent.parentNode;
  }

  if (
    parent &&
    grandParent &&
    (grandParent.classList.contains(CLASSNAMES.CLIENTS) ||
      grandParent.classList.contains(CLASSNAMES.JOBS))
  ) {
    inputNodes = parent.getElementsByTagName("INPUT");
    for (i = 0; i < inputNodes.length; i++) {
      textInput_el = inputNodes[i];
      // we don't actually use the color picker element,
      // but instead use its corresponding label
      // as that is the colored part in the UI
      // `nextSibling` gives us this
      bg_el = textInput_el.parentNode.getElementsByClassName(
        "bg " + CLASSNAMES.COLORPICKER
      )[0].nextSibling;
      fg_el = textInput_el.parentNode.getElementsByClassName(
        "fg " + CLASSNAMES.COLORPICKER
      )[0].nextSibling;
    }
    textInput_el.className = "";
    textInput_el.style.color = getStyle(fg_el, "background-color");
    textInput_el.style.backgroundColor = getStyle(bg_el, "background-color");
    JobsClients.updateDataFromEl(parent);
  }
};

// get next available ID for job or client
JobsClients.getNextID = function (_dataType) {
  var prefix;
  if (_dataType === TYPES.DATA_JOB) {
    prefix = STRINGS.JOBID_PREFIX;
  } else if (_dataType === TYPES.DATA_CLIENT) {
    prefix = STRINGS.CLIENTID_PREFIX;
  }
  return prefix + shortID(DDD.UID_JOBSCLIENTS_LENGTH);
};

export {JobsClients};
