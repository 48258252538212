/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  DatePicker:
  Custom Date Picker element
---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as DATETIME from "./DDD/DATETIME.js";
import * as EL_IDS from "./DDD/EL_IDS.js";
import * as LABELS from "./DDD/LABELS.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";

import {Popover} from "./Popover.js";
import {RadioButtonGroup} from "./RadioButtonGroup.js";
import {Select} from "./Select.js";
import {Storage} from "./Storage.js";
import {Worksheet} from "./Worksheet.js";

import {
  __,
  daysInOneIndexedMonth,
  forceBrowserReflow, getElementFromElementOrID, getFormattedDate, manualEvent,
  removeChildFromParent
} from "./utils.js";

class DatePicker extends HTMLElement {
  init(_ob) {
    DatePicker.createInputField(_ob);
  }

  static get monthSelectElement() {
    return DatePicker.el.getElementsByClassName(CLASSNAMES.DATEPICKER_MONTH)[0];
  }

  static get yearSelectElement() {
    return DatePicker.el.getElementsByClassName(CLASSNAMES.DATEPICKER_YEAR)[0];
  }
}

DatePicker.createInputField = function (_ob) {
  var label_el,
    inputField = document.createElement("input"),
    parent_el = (parent_el = getElementFromElementOrID(_ob.parent));

  inputField.classList.add(CLASSNAMES.DATEPICKER_TARGET_INPUT);
  inputField.type = "text";
  inputField.setAttribute("tabindex", 0);
  inputField.readOnly = true;
  if (_ob.id) {
    if (_ob.label) {
      label_el = document.createElement("label");
      label_el.innerHTML = _ob.label;
      parent_el.appendChild(label_el);
      label_el.htmlFor = _ob.id;
    }
    inputField.name = _ob.id;
    inputField.id = _ob.id;
  }
  if (_ob.heading) {
    inputField.heading = _ob.heading;
  }
  parent_el.appendChild(inputField);
  DatePicker.setYearSpanByInputField(inputField);
};

DatePicker.create = function (_ob) {
  DatePicker.el = document.createElement("div");
  DatePicker.el.classList.add(CLASSNAMES.DATEPICKER);
  _ob.parent.appendChild(DatePicker.el);

  DatePicker.input_el = _ob.data.target;

  DatePicker.createMonthSelect();
  DatePicker.createYearSelect();

  DatePicker.daysContainer_el = document.createElement("div");
  DatePicker.el.appendChild(DatePicker.daysContainer_el);

  DatePicker.update();
};

DatePicker.remove = function () {
  if (DatePicker.el) {
    DatePicker.el.innerHTML = "";
    removeChildFromParent(DatePicker.el);
  }
};

DatePicker.createMonthSelect = function (_ob) {
  var month,
    monthSelect = new Select(),
    options = [];

  for (month in DATETIME.MONTH_NAMES) {
    options.push({
      class: CLASSNAMES.BTNNAV,
      name: DATETIME.MONTH_NAMES[month],
      id: "_" + parseInt(month)
    });
  }
  monthSelect.init({
    class: CLASSNAMES.DATEPICKER_MONTH,
    options: options,
    parent: DatePicker.el
  });
};

DatePicker.createYearSelect = function () {
  var year,
    yearSelect = new Select(),
    options = [];

  for (
    year = DatePicker.input_el.startYear;
    year <= DatePicker.input_el.endYear;
    year++
  ) {
    options.push({
      class: [CLASSNAMES.BTNNAV, CLASSNAMES.DATEPICKER_YEAR],
      name: year,
      id: "_" + year
    });
  }
  yearSelect.init({
    class: CLASSNAMES.DATEPICKER_YEAR,
    options: options,
    parent: DatePicker.el
  });
};

DatePicker.setYearSpanByInputField = function (_inputField) {
  var firstAndLastDatesWithEntries = Worksheet.getRangeOfDaysWithEntriesArray(),
    firstEntryDate = firstAndLastDatesWithEntries[0],
    lastEntryDate = firstAndLastDatesWithEntries[1];

  //  Pre-set year span for specific app elements
  if (
    _inputField.id === EL_IDS.REPORT_STARTDATE ||
    _inputField.id === EL_IDS.REPORT_ENDDATE
  ) {
    _inputField.startYear = firstEntryDate.getFullYear();
    // add a year to allow next year to be selected
    _inputField.endYear = lastEntryDate.getFullYear() + 1;
  } else {
    // default
    _inputField.startYear = new Date().getFullYear() - DDD.YEARS_TO_DISPLAY;
    // add a year to allow next year to be selected
    _inputField.endYear = new Date().getFullYear() + 1;
  }

  //   Pre-set date for specific app elements
  if (_inputField.id === EL_IDS.REPORT_STARTDATE) {
    DatePicker.setDate(_inputField, firstEntryDate);
  } else if (_inputField.id === EL_IDS.REPORT_ENDDATE) {
    DatePicker.setDate(_inputField, lastEntryDate);
  } else if (_inputField.id === PREF_IDS.YEAR_STARTDATE) {
    DatePicker.setDate(
      _inputField,
      new Date(Storage.getPref(PREF_IDS.YEAR_STARTDATE))
    );
  } else {
    // default
    DatePicker.setDate(_inputField, new Date());
  }
};

DatePicker.onFocus = function (_event) {
  // _event.target is the input field
  Popover.show({
    title: _event.target.heading,
    button_ar: [
      {
        label: LABELS.CANCEL,
        classList: CLASSNAMES.CANCEL_BUTTON,
        func: DatePicker.hide
      },
      {
        label: LABELS.OK,
        classList: CLASSNAMES.OK_BUTTON,
        func: DatePicker.confirm
      }
    ],
    datePicker: {
      target: _event.target
    }
  });
};

DatePicker.confirm = function () {
  var node,
    dateChosen = new Date(),
    year = DatePicker.yearSelectElement.value.substr(1),
    month = DatePicker.monthSelectElement.value.substr(1),
    day = document.getElementById(EL_IDS.DATEPICKER_DAYS).value;

  console.group("DatePicker.confirm()");

  dateChosen.setFullYear(year);
  dateChosen.setMonth(month);
  dateChosen.setDate(day);

  __("year: " + year);
  __("month: " + month);
  __("day: " + day);
  __("dateChosen: " + dateChosen);

  DatePicker.setDate(DatePicker.input_el, dateChosen);
  DatePicker.hide();

  // find containing form and click it to trigger a read of it
  //node = DatePicker.input_el;
  //while (node.nodeName !== "FG-FORM" && node !== document.body) {
  //  node = node.parentNode;
  //}
  //node.click();
  //console.groupEnd();
};

DatePicker.hide = function () {
  if (DatePicker.input_el) {
    DatePicker.input_el.blur();
  }
  Popover.hide();
};

DatePicker.update = function () {
  var date = new Date(DatePicker.input_el.dateShortISO);

  DatePicker.yearSelectElement.selectedIndex =
    date.getFullYear() - DatePicker.input_el.startYear;
  DatePicker.monthSelectElement.selectedIndex = date.getMonth();

  manualEvent(DatePicker.yearSelectElement, "change");
};

DatePicker.drawDays = function () {
  var thisDay,
    i,
    squares,
    blanks,
    selectedDay,
    date_tmp,
    daysInMonth,
    startWeekday,
    endWeekday,
    options = {},
    year = DatePicker.yearSelectElement.value.substr(1),
    month = DatePicker.monthSelectElement.value.substr(1);
  // first day of month
  date_tmp = new Date(year, month, 1, 0, 0, 0, 0);
  // weekday of first day of month
  startWeekday = DatePicker.convertSundayToMonday(date_tmp.getDay());

  daysInMonth = daysInOneIndexedMonth(1 + parseInt(month), year);
  date_tmp.setDate(daysInMonth);

  // weekday of last day of month
  endWeekday = DatePicker.convertSundayToMonday(date_tmp.getDay());

  selectedDay = new Date(DatePicker.input_el.dateShortISO).getDate();

  squares = [];
  if (startWeekday != 0) {
    for (i = 0; i < startWeekday; i++) {
      squares.push(null);
    }
  }
  for (i = 1; i <= daysInMonth; i++) {
    squares.push(i);
  }
  if (endWeekday != 6) {
    blanks = endWeekday == 0 ? 6 : 6 - endWeekday;
    for (i = 0; i < blanks; i++) {
      squares.push(null);
    }
  }

  for (i = 1; i <= squares.length; i++) {
    thisDay = squares[i - 1];
    if (thisDay === null) {
      options["_" + i] = null;
    } else {
      options["_" + i] = {
        label: thisDay,
        value: thisDay
      };
    }
  }

  DatePicker.drawDaysAsRadioButtonGroup({
    id: EL_IDS.DATEPICKER_DAYS,
    parent: DatePicker.daysContainer_el,
    checkIfMatched: "" + selectedDay,
    // `null` is used to denote a void/empty square
    voidIfContent: null,
    voidClass: CLASSNAMES.VOID,
    options: options
  });
};

DatePicker.drawDaysAsRadioButtonGroup = function (_ob) {
  var el;

  removeChildFromParent(document.getElementById(EL_IDS.DATEPICKER_DAYS));
  forceBrowserReflow();

  el = new RadioButtonGroup();
  el.init(_ob);
  if (_ob.selectedDay) {
    el.value = "" + selectedDay;
  }
};

// Date.day (of week) comes zero-indexed with Sunday as day 0
// We need monday to be 0
DatePicker.convertSundayToMonday = function (_index) {
  _index -= 1;
  if (_index < 0) {
    _index = 6;
  }
  return _index;
};

DatePicker.getPickerAncestor = function (_el) {
  while (!_el.classList.contains(CLASSNAMES.DATEPICKER) && _el !== document.body) {
    _el = _el.parentNode;
  }
  return _el;
};

DatePicker.setDate = function (_el, _date) {
  _el.dateShortISO = _date.getShortISO();
  _el.value = getFormattedDate(
    _date,
    DDD.PREF_OPTS[PREF_IDS.DATE_FORMAT][Storage.getPref(PREF_IDS.DATE_FORMAT)].label
  );
  manualEvent(_el, 'change');
};

customElements.define("fg-date-picker", DatePicker);
export {DatePicker};
