/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

export var BACKSPACE = 8;
export var TAB = 9;
export var ENTER = 13;
export var SHIFT = 16;
export var CTRL = 17;
export var ALT = 18;
export var PAUSE_BREAK = 19;
export var CAPS_LOCK = 20;
export var ESCAPE = 27;
export var PAGE_UP = 33;
export var SPACE = 32;
export var PAGE_DOWN = 34;
export var END = 35;
export var HOME = 36;
export var ARROW_LEFT = 37;
export var ARROW_UP = 38;
export var ARROW_RIGHT = 39;
export var ARROW_DOWN = 40;
export var PRINT_SCREEN = 44;
export var INSERT = 45;
export var DELETE = 46;
