/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  WebDAV:
  Handles data synchronisation with a WebDAV server

---------------------------------------------------------
*/

import { DDD } from "./DDD/CONST.js";
import * as PREF_IDS from "./DDD/PREF_IDS.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TIMERS from "./DDD/TIMERS.js";

import { UserData } from "./UserData.js";
import { Storage } from "./Storage.js";
import { Popover } from "./Popover.js";

import { __ } from "./utils.js";

class WebDAV {}

WebDAV.FILENAME_LATEST = "ddd-sync-latest.json";

WebDAV.ACTIONS = {
  GET_LATESTFROMSERVER: "getLatestFromServer",
  CACHE_LATESTFROMSERVER: "cacheLatestFromServer",
  PUT_CURRENTLOCAL_AS_PREVIOUS: "putCurrentLocalAsPrevious",
  MERGE_LATESTFROMSERVER_WITH_CURRENTLOCAL:
    "mergeLatestFromServerWithCurrentLocal",
  PUT_CURRENTLOCAL_AS_LATESTONSERVER: "putCurrentLocalAsLatestOnServer",
  CONFIRM_LATESTONSERVER_SAVED: "confirmLatestOnServerSaved",
};

WebDAV.ACTION_AR = [
  WebDAV.ACTIONS.GET_LATESTFROMSERVER,
  WebDAV.ACTIONS.CACHE_LATESTFROMSERVER,
  WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_PREVIOUS,
  WebDAV.ACTIONS.MERGE_LATESTFROMSERVER_WITH_CURRENTLOCAL,
  WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_LATESTONSERVER,
  WebDAV.ACTIONS.CONFIRM_LATESTONSERVER_SAVED,
];

WebDAV.DESCRIPTIONS = {
  [WebDAV.ACTIONS.GET_LATESTFROMSERVER]: "Get 'latest' from server",
  [WebDAV.ACTIONS.CACHE_LATESTFROMSERVER]: "Cache 'latest' locally",
  [WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_PREVIOUS]:
    "Put local data to server (as 'previous')",
  [WebDAV.ACTIONS.MERGE_LATESTFROMSERVER_WITH_CURRENTLOCAL]:
    "Merge 'latest' from server with local",
  [WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_LATESTONSERVER]:
    "Put local on server as 'latest'",
  [WebDAV.ACTIONS.CONFIRM_LATESTONSERVER_SAVED]: "Success",
};

// TODO needs error handling for when settings are broken
WebDAV.startSync = function () {
  WebDAV.currentActionIndex = 0;
  WebDAV.statusMessage = "";
  Popover.show({
    title: STRINGS.WEBDAV_SYNC_POPOVER_TITLE,
  });
  WebDAV.sendNextRequest();
};

WebDAV.sendNextRequest = function () {
  const currentAction = WebDAV.ACTION_AR[WebDAV.currentActionIndex];

  let filename,
    method,
    exportDataObject = UserData.getExportDataObject(),
    bodyData = JSON.stringify(exportDataObject.data, null, "\t");

  __(
    "[" +
      (WebDAV.currentActionIndex + 1) +
      "/" +
      WebDAV.ACTION_AR.length +
      "] WebDAV.sendNextRequest - " +
      currentAction,
    DDD.LOG_FORMAT.USERDATA
  );

  WebDAV.statusMessage += "<br>" + WebDAV.DESCRIPTIONS[currentAction];
  Popover.setMessage(WebDAV.statusMessage);

  switch (currentAction) {
    case WebDAV.ACTIONS.GET_LATESTFROMSERVER:
      method = "GET";
      filename = WebDAV.FILENAME_LATEST;
      break;
    case WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_PREVIOUS:
      method = "PUT";
      filename = exportDataObject.filenameDated;
      __("\tfilename: " + filename, DDD.LOG_FORMAT.USERDATA);
      break;
    case WebDAV.ACTIONS.PUT_CURRENTLOCAL_AS_LATESTONSERVER:
      method = "PUT";
      filename = WebDAV.FILENAME_LATEST;
      break;
    default:
      break;
  }

  WebDAV.currentActionIndex++;

  setTimeout(
    WebDAV.requestToServer,
    TIMERS.WEBDAV_SYNC_STEP_DELAY_MS,
    method,
    currentAction === WebDAV.ACTIONS.GET_LATESTFROMSERVER ? "" : bodyData,
    filename
  );
};

WebDAV.requestToServer = function (_method, _body, _filename) {
  WebDAV.Request = new XMLHttpRequest();
  WebDAV.Request.onload = WebDAV.onLoad;
  WebDAV.Request.onerror = WebDAV.onError;
  WebDAV.Request.open(_method, WebDAV.getURLFromFilename(_filename));
  // Must be called after `open()` and before `send()`
  WebDAV.Request.setRequestHeader("Authorization", WebDAV.getBasicAuthHeader());
  WebDAV.Request.send(_body);
};

WebDAV.getBasicAuthHeader = function () {
  return (
    "Basic " +
    btoa(
      Storage.getPref(PREF_IDS.WEBDAV_USER) +
        ":" +
        Storage.getPref(PREF_IDS.WEBDAV_PASS)
    )
  );
};

WebDAV.getURLFromFilename = function (_filename) {
  return Storage.getPref(PREF_IDS.WEBDAV_ADDRESS) + "/" + _filename;
};

WebDAV.onLoad = function () {
  const currentAction = WebDAV.ACTION_AR[WebDAV.currentActionIndex];
  __(
    "[" +
      (WebDAV.currentActionIndex + 1) +
      "/" +
      WebDAV.ACTION_AR.length +
      "] WebDAV.onLoad - " +
      currentAction,
    DDD.LOG_FORMAT.USERDATA
  );

  switch (currentAction) {
    case WebDAV.ACTIONS.CACHE_LATESTFROMSERVER:
      if (WebDAV.Request.status === 200) {
        WebDAV.latestDataFromServer = JSON.parse(WebDAV.Request.response);
        WebDAV.statusMessage += "<br>Status: 200 (OK)";
      } else if (WebDAV.Request.status === 404) {
        WebDAV.latestDataFromServer = null;
        WebDAV.statusMessage += "<br>Status: 404 (missing file)";
        __(
          "NO LATEST FILE ON SERVER. NEW SYNC SETTINGS?",
          DDD.LOG_FORMAT.ERROR
        );
      }
      break;
    case WebDAV.ACTIONS.MERGE_LATESTFROMSERVER_WITH_CURRENTLOCAL:
      // 201 created
      if (WebDAV.Request.status === 201) {
        WebDAV.statusMessage += "<br>Status: 201 (file created)";
        if (WebDAV.latestDataFromServer) {
          // Ensure this happens as a full merge rather than using import prefs
          // (2nd param boolean)
          UserData.import(WebDAV.latestDataFromServer, true);
        }
      }
      break;
    case WebDAV.ACTIONS.CONFIRM_LATESTONSERVER_SAVED:
      if (WebDAV.Request.status === 204) {
        __(
          "Merged data saved back to server as latest",
          DDD.LOG_FORMAT.USERDATA
        );
      }
      break;
    default:
      break;
  }

  if (currentAction !== WebDAV.ACTIONS.CONFIRM_LATESTONSERVER_SAVED) {
    WebDAV.statusMessage += "<br>" + WebDAV.DESCRIPTIONS[currentAction];
    Popover.setMessage(WebDAV.statusMessage);
    WebDAV.currentActionIndex++;
    setTimeout(WebDAV.sendNextRequest, TIMERS.WEBDAV_SYNC_STEP_DELAY_MS);
  } else {
    // Sequence has finished
    Popover.hide();
    UserData.initialiseAndRefreshPage();
  }
};

WebDAV.onError = function () {
  __("WebDAV.onError()", DDD.LOG_FORMAT.ERROR);
  __("- status: " + WebDAV.Request.status, DDD.LOG_FORMAT.ERROR);
  __("- response: " + WebDAV.Request.response, DDD.LOG_FORMAT.ERROR);
};

export { WebDAV };
