/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
  PopoverButtons:
  The main group of buttons (eg 'OK', 'Cancel') for the Popover
*/

import {DDD} from "./DDD/CONST.js";

import {Button} from "./Button.js";
import {Popover} from "./Popover.js";
import {registerEventHandler, removeChildFromParent} from "./utils.js";

class PopoverButtons {}

PopoverButtons.create = function (_ob) {
  var i,
    btn_el,
    btnData_ob,
    btnClassList,
    btnDefaultClassList = [DDD.CLASSNAMES.BTNNAV, DDD.CLASSNAMES.TEXT_BUTTON];

  // Container for all buttons
  PopoverButtons.el = document.createElement("div");
  PopoverButtons.el.classList.add(DDD.CLASSNAMES.POPOVER_BUTTON_CONTAINER);
  _ob.parent.appendChild(PopoverButtons.el);

  for (i = 0; i < _ob.btn_ar.length; i++) {
    btnData_ob = _ob.btn_ar[i];
    btnClassList = btnDefaultClassList;
    if (btnData_ob.classList) {
      btnClassList = btnDefaultClassList.concat(btnData_ob.classList);
    }
    btn_el = new Button();
    btn_el.init({
      class: btnClassList,
      label: btnData_ob.label,
      disabled: btnData_ob.disabled,
      parent: PopoverButtons.el
    });
    registerEventHandler(btn_el, "mousedown", btnData_ob.func);
    registerEventHandler(btn_el, "mousedown", Popover.hide);
  }
};

PopoverButtons.remove = function () {
  if (PopoverButtons.el) {
    PopoverButtons.el.innerHTML = "";
    removeChildFromParent(PopoverButtons.el);
  }
};

PopoverButtons.enableAll = function () {
  var i,
    btns = PopoverButtons.el.getElementsByTagName("FG-BUTTON");

  for (i = 0; i < btns.length; i++) {
    btns[i].removeAttribute("disabled");
  }
};

export {PopoverButtons};
