/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Storage:
  Manage all stored data

---------------------------------------------------------
*/

import {DDD} from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";
import * as STRINGS from "./DDD/STRINGS.js";
import * as TIMERS from "./DDD/TIMERS.js";
import * as TYPES from "./DDD/TYPES.js";

import {AppUI} from "./AppUI.js";
import {Elements} from "./Elements.js";
import {Timers} from "./Timers.js";
import {UserData} from "./UserData.js";
import {manualEvent, __} from "./utils.js";

class Storage {}

Storage.storageIsPossible = function () {
  if (typeof Storage === "undefined") {
    __(STRINGS.STORAGE_UNSUPPORTED, DDD.LOG_FORMAT.ERROR);
  } else {
    return true;
  }
};

Storage.setObj = function (_category, _ob) {
  if (_ob) {
    localStorage.setItem(
      DDD.APP_ID + STRINGS.STORAGE_OBJECTPREFIX + _category,
      JSON.stringify(_ob)
    );
    Timers.clearByID(TIMERS.ANIM_DEBOUNCE_ID);
    Timers.setByID(
      TIMERS.ANIM_DEBOUNCE_ID,
      function () {
        manualEvent(document, "requestAnimation", {
          el: Elements.workingIndicator,
          animClass: CLASSNAMES.ANIM_WORKING
        });
      },
      TIMERS.ANIM_DEBOUNCE_MS
    );
  } else {
    __("Storage.setObj(): " + _category + " EMPTY", DDD.LOG_FORMAT.ERROR);
  }
};

Storage.getObj = function (_category) {
  return JSON.parse(
    localStorage.getItem(DDD.APP_ID + STRINGS.STORAGE_OBJECTPREFIX + _category)
  );
};

Storage.getPref = function (_prefID) {
  return Storage.getObj(TYPES.CONTENT_PREFS)[_prefID];
};

Storage.setPref = function (_prefID, _value) {
  var ob = Storage.getObj(TYPES.CONTENT_PREFS);

  //for (keyCurrent in ob) {
  // if (keyCurrent === _prefID) {
  ob[_prefID] = _value;
  //  break;
  //}
  // }
  Storage.setObj(TYPES.CONTENT_PREFS, ob);
};

Storage.initialiseData = function () {
  console.group("Storage.initialiseData()");

  UserData.createMissing();
  AppUI.buildJobsClientsStylesheet();

  UserData.setUnspecifiedPrefsToDefaults();

  console.groupEnd();
};

export {Storage};
