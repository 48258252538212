/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
  NumberInput:
  - Special input field for numbers
  - Used in worksheet
  - Can be a TIME or a CASH type
  - Contains two Spinner elements to control its big/small units
    - hours/mins
    - pounds/pennies (or similar based on currency)

*/

import {DDD} from "./DDD/CONST.js";
import {Spinner} from "./Spinner.js";
import {
  hoursAndMinutesArrayToMinutes,
  getElementFromElementOrID,
  manualEvent,
  minutesToHoursAndMinutesArray,
  padString,
  prettyTimeFromArray,
  __
} from "./utils.js";

class NumberInput extends HTMLElement {
  init(_ob) {
    var parent_el = getElementFromElementOrID(_ob.parent);

    if (_ob.id) {
      this.id = _ob.id;
    }
    parent_el.appendChild(this);

    if (_ob.class) {
      this.classList.add(_ob.class);
    }

    this.keyboardInputField = document.createElement("input");
    this.keyboardInputField.setAttribute("type", "text");
    this.keyboardInputField.setAttribute("tabindex", 0);
    this.appendChild(this.keyboardInputField);

    this.entry_el = _ob.entry_el;
    this.entryType = _ob.entryType || DDD.DEFAULTS.ENTRY_TYPE;

    if (this.entryType !== DDD.TYPES.ITEM_DAYLENGTH) {
      this.unitBig_el = new Spinner();
      this.unitBig_el.init({
        class: DDD.CLASSNAMES.SPINNER_UNITBIG,
        parent: this,
        unitSize: DDD.TYPES.UNIT_BIG
      });
    }

    this.unitSmall_el = new Spinner();
    this.unitSmall_el.init({
      class: DDD.CLASSNAMES.SPINNER_UNITSMALL,
      parent: this,
      unitSize: DDD.TYPES.UNIT_SMALL
    });

    this.unitSmallStep = _ob.unitSmallStep;

    if (_ob.value) {
      this.value = _ob.value;
    } else {
      this.reset();
    }
  }

  reset() {
    this.value = 0;
    this.classList.add(DDD.CLASSNAMES.PLACEHOLDER);
  }

  onSpinnerChange(_event) {
    var remainder,
      direction = 0 + _event.detail.direction,
      numValue = parseInt(this.value);
    // direction will be either 1 or -1
    if (this._entryType_ === DDD.TYPES.ITEM_TIME) {
      if (_event.detail.unitSize === DDD.TYPES.UNIT_BIG) {
        numValue += 60 * direction;
      } else {
        numValue += direction * this.unitSmallStep;
      }
      // round to this.unitSmallStep
      remainder = numValue % this.unitSmallStep;
      //__("remainder: " + remainder);
      if (remainder) {
        if (direction === 1) {
          numValue -= remainder;
          numValue -= this.unitSmallStep;
        } else {
          numValue += this.unitSmallStep - remainder;
        }
      }
    } else if (this._entryType_ === DDD.TYPES.ITEM_MONEY) {
      if (_event.detail.unitSize === DDD.TYPES.UNIT_BIG) {
        numValue += 100 * direction;
      } else {
        numValue += direction;
      }
    } else if (this._entryType_ === DDD.TYPES.ITEM_DAYLENGTH) {
      numValue += direction;
    }
    this.value = numValue;
    manualEvent(this, "change");
  }

  convertKeyboardInput(_val) {
    __("NumberInput::convertKeyboardInput()");
    __("\t_val: " + _val);
    __("\tthis._entryType_: " + this._entryType_);
    var returnVal, hoursMins_ar;
    // if is TIME
    if (this._entryType_ === DDD.TYPES.ITEM_TIME) {
      // if value contains ':' or '.' or '-'
      // split into hours/mins array
      if (_val.indexOf(":") !== -1) {
        hoursMins_ar = _val.split(":");
      } else if (_val.indexOf(".") !== -1) {
        hoursMins_ar = _val.split(".");
      } else if (_val.indexOf("-") !== -1) {
        hoursMins_ar = _val.split("-");
      } else {
        // if value is two or fewer characters long
        if (_val.length <= 2) {
          // value is minutes
          hoursMins_ar = [0, parseInt(_val)];
        } else {
          // value is more than two characters long
          // last two characters are minutes
          // remaining characters are hours
          hoursMins_ar = [];
          hoursMins_ar[0] = _val.substr(0, _val.length - 2);
          hoursMins_ar[1] = _val.substr(-2);
        }
      }
      returnVal = hoursAndMinutesArrayToMinutes(hoursMins_ar);
    } else if (this._entryType_ === DDD.TYPES.ITEM_MONEY) {
      // value is pounds and pence so multiply by 100
      // parseInt is essential here as JS was returning strange results eg:
      // 78.26 * 100 = 7826.0000001
      // -78.26 * 100 = -7826.0000001
      // this is due to known problems with floating point multiplication in JS
      returnVal = parseInt(_val * 100);
      __("\treturnVal: " + returnVal);
    } else if (this._entryType_ === DDD.TYPES.ITEM_DAYLENGTH) {
      returnVal = _val;
    }
    return returnVal;
  }

  onKeyDown(_event) {
    //var numberInput = _event.target;
    var numberInput = _event.target.parentNode;
    __("NumberInput::onKeyDown()");
    if (_event.key === "Enter" || _event.key === "Tab") {
      numberInput.acceptKeyboardInput();
      numberInput.keyboardInputField.blur();
    } else if (_event.key === "ArrowUp") {
      manualEvent(numberInput.unitSmall_el, "change", {
        direction: 1,
        unitSize: numberInput.unitSmallStep
      });
    } else if (_event.key === "ArrowDown") {
      manualEvent(numberInput.unitSmall_el, "change", {
        direction: -1,
        unitSize: numberInput.unitSmallStep
      });
    } else if (_event.key === "Escape") {
      numberInput.keyboardInputField.blur();
    }
  }

  acceptKeyboardInput() {
    __("NumberInput::acceptKeyboardInput()");
    __("\tinputfield value: " + this.keyboardInputField.value);
    var convertedVal = this.convertKeyboardInput(this.keyboardInputField.value);
    __("\tconverted value: " + convertedVal);
    if (!Number.isNaN(convertedVal)) {
      this.value = convertedVal;
    } else {
      this.value = 0;
    }
    manualEvent(this, "change");
  }

  get entryType() {
    return this._entryType_;
  }
  set entryType(value) {
    this._entryType_ = value;
  }

  get value() {
    return this.getAttribute("value");
  }
  set value(_val) {
    var bigUnitVal, smallUnitVal, prettyValue;
    if (this._entryType_ === DDD.TYPES.ITEM_TIME) {
      if (_val < 0) {
        _val = 0;
      }
      prettyValue = prettyTimeFromArray(minutesToHoursAndMinutesArray(_val));
    } else if (this._entryType_ === DDD.TYPES.ITEM_MONEY) {
      if (_val < 0) {
        bigUnitVal = Math.ceil(_val / 100);
        smallUnitVal = 0 - (_val - bigUnitVal * 100);
        if (bigUnitVal === 0) {
          prettyValue = "-" + bigUnitVal;
        } else {
          prettyValue = bigUnitVal;
        }
        prettyValue += DDD.STRINGS.SEPARATOR_CASH;
        prettyValue += padString("" + smallUnitVal, "00");
        this.entry_el.classList.add(DDD.CLASSNAMES.NEGATIVE);
        this.entry_el.classList.remove(DDD.CLASSNAMES.POSITIVE);
      } else if (_val > 0) {
        bigUnitVal = Math.floor(_val / 100);
        smallUnitVal = _val - bigUnitVal * 100;
        prettyValue = bigUnitVal;
        prettyValue += DDD.STRINGS.SEPARATOR_CASH;
        prettyValue += padString("" + smallUnitVal, "00");
        this.entry_el.classList.add(DDD.CLASSNAMES.POSITIVE);
        this.entry_el.classList.remove(DDD.CLASSNAMES.NEGATIVE);
      } else {
        bigUnitVal = Math.floor(_val / 100);
        smallUnitVal = _val - bigUnitVal * 100;
        prettyValue = bigUnitVal;
        prettyValue += DDD.STRINGS.SEPARATOR_CASH;
        prettyValue += padString("" + smallUnitVal, "00");
        this.entry_el.classList.remove(DDD.CLASSNAMES.NEGATIVE);
        this.entry_el.classList.remove(DDD.CLASSNAMES.POSITIVE);
      }
    } else if (this._entryType_ === DDD.TYPES.ITEM_DAYLENGTH) {
      if (_val < DDD.DAYLENGTH_HOURS_MIN) {
        _val = DDD.DAYLENGTH_HOURS_MIN;
      } else if (_val > DDD.DAYLENGTH_HOURS_MAX) {
        _val = DDD.DAYLENGTH_HOURS_MAX;
      }
      prettyValue = _val;
    }
    this.keyboardInputField.value = prettyValue;
    this.setAttribute("value", _val);
    if (parseInt(this.value, 10) !== 0) {
      this.classList.remove(DDD.CLASSNAMES.PLACEHOLDER);
    } else {
      this.classList.add(DDD.CLASSNAMES.PLACEHOLDER);
    }
  }
}

customElements.define("fg-number-input", NumberInput);
export {NumberInput};
