/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
  Popover:
  A customisable message box with a title, message and button/s
*/

import { DDD } from "./DDD/CONST.js";

import * as CLASSNAMES from "./DDD/CLASSNAMES.js";

import { PopoverButtons } from "./PopoverButtons.js";
import { DatePicker } from "./DatePicker.js";
import { MultiChoiceForm } from "./MultiChoiceForm.js";
import { HelpDemo } from "./HelpDemo.js";

import { __, removeChildFromParent } from "./utils.js";

class Popover {}

Popover.show = function (_newInfo_ob) {
  console.group("Popover.show()");
  Popover.el.classList.remove(CLASSNAMES.INVISIBLE);
  __("_newInfo_ob: " + JSON.stringify(_newInfo_ob));
  if (_newInfo_ob) {
    // Delete/recreate title and message
    removeChildFromParent(Popover.title_el);
    removeChildFromParent(Popover.message_el);
    Popover.setTitle(_newInfo_ob.title);
    Popover.setMessage(_newInfo_ob.message);

    // Delete/recreate multiple choice form
    MultiChoiceForm.remove();
    if (_newInfo_ob.multiChoiceForm) {
      MultiChoiceForm.create({
        parent: Popover.inner_el,
        data: _newInfo_ob.multiChoiceForm,
      });
    }

    // Delete/recreate date picker
    DatePicker.remove();
    if (_newInfo_ob.datePicker) {
      DatePicker.create({
        parent: Popover.inner_el,
        data: _newInfo_ob.datePicker,
      });
    }

    // Delete/recreate help demo vid
    HelpDemo.remove();
    if (_newInfo_ob.helpDemo) {
      HelpDemo.create({
        parent: Popover.inner_el,
        data: _newInfo_ob.helpDemo,
      });
    }

    // Delete/recreate main (eg ok/cancel) buttons
    PopoverButtons.remove();
    if (_newInfo_ob.button_ar) {
      PopoverButtons.create({
        parent: Popover.inner_el,
        btn_ar: _newInfo_ob.button_ar,
      });
    }

    // Multiple choice forms have to have a selection made before
    // the ok button can be enabled
    if (MultiChoiceForm.choiceHasBeenMade) {
      PopoverButtons.enableAll();
    }
  }
  console.groupEnd();
};

Popover.hide = function () {
  if (Popover.el) {
    Popover.el.classList.add(CLASSNAMES.INVISIBLE);
  }
};

Popover.setTitle = function (_str) {
  if (_str) {
    Popover.title_el = document.createElement("h2");
    Popover.inner_el.appendChild(Popover.title_el);
    Popover.title_el.innerHTML = _str;
  }
};

Popover.setMessage = function (_str) {
  if (_str) {
    removeChildFromParent(Popover.message_el);
    Popover.message_el = document.createElement("div");
    Popover.inner_el.appendChild(Popover.message_el);
    Popover.message_el.innerHTML = _str;
    //document.offsetHeight;
  }
};

Popover.onWrapperClick = function (_event) {
  if (_event.target.classList.contains(CLASSNAMES.POPOVER_CONTAINER)) {
    Popover.hide();
  }
};

Popover.onPageLoadStarted = function () {
  Popover.hide();
};

Popover.init = function (_ob) {
  Popover.el = document.createElement("div");
  Popover.el.classList.add(CLASSNAMES.POPOVER_CONTAINER);
  _ob.parent_el.appendChild(Popover.el);

  Popover.inner_el = document.createElement("div");
  Popover.inner_el.classList.add(CLASSNAMES.POPOVER);
  Popover.el.appendChild(Popover.inner_el);

  Popover.hide = Popover.hide.bind(Popover);
  Popover.hide();

  return Popover;
};

export { Popover };
