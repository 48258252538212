/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

import * as CLASSNAMES from "./CLASSNAMES.js";
import * as EL_IDS from "./EL_IDS.js";
import * as GUI_TYPES from "./GUI_TYPES.js";
import * as HELPITEM_IDS from "./HELPITEM_IDS.js";
import * as PREF_IDS from "./PREF_IDS.js";
import * as STRINGS from "./STRINGS.js";
import * as TYPES from "./TYPES.js";

import {HELPITEM_DATA} from "./HELPITEM_DATA.js";
import {PREF_OPTS} from "./PREF_OPTS.js";

export const GUIDATA_CONFIG = [
  {
    type: GUI_TYPES.FORM,
    id: EL_IDS.CONFIGFORM,
    class: CLASSNAMES.FORMMAIN,
    parent: "main",
    hidden: false
  },

  {
    type: GUI_TYPES.COL,
    id: "config-col-worksheet",
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.CONFIGFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: "config-col-worksheet",
    text: "Worksheet"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.TIMESPAN,
    label: PREF_OPTS.INTROS[PREF_IDS.TIMESPAN],
    parent: "config-col-worksheet",
    options: PREF_OPTS[PREF_IDS.TIMESPAN]
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-totals",
    parent: "config-col-worksheet"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-totals",
    text: "Show totals"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.SHOW_WEEKTOTALS,
    label: PREF_OPTS.INTROS[PREF_IDS.SHOW_WEEKTOTALS],
    parent: "config-group-totals"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.SHOW_MONTHTOTALS,
    label: PREF_OPTS.INTROS[PREF_IDS.SHOW_MONTHTOTALS],
    parent: "config-group-totals"
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-preselect-lastused",
    parent: "config-col-worksheet"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-preselect-lastused",
    text: "When adding a new entry"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.PRESELECT_LASTUSED,
    label: PREF_OPTS.INTROS[PREF_IDS.PRESELECT_LASTUSED],
    parent: "config-group-preselect-lastused"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.ADD_ENTRY_BEFOREORAFTER,
    label: PREF_OPTS.INTROS[PREF_IDS.ADD_ENTRY_BEFOREORAFTER],
    parent: "config-col-worksheet",
    options: PREF_OPTS[PREF_IDS.ADD_ENTRY_BEFOREORAFTER]
  },

  {
    type: GUI_TYPES.COL,
    id: "config-col-visuals",
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.CONFIGFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: "config-col-visuals",
    text: "Appearance"
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-anim",
    parent: "config-col-visuals"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-anim",
    text: "Enable animations"
  },
  {
    type: GUI_TYPES.CHECKBOX,
    id: PREF_IDS.ANIMATION_ENABLED,
    label: PREF_OPTS.INTROS[PREF_IDS.ANIMATION_ENABLED],
    parent: "config-group-anim"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.COLORSCHEME,
    label: PREF_OPTS.INTROS[PREF_IDS.COLORSCHEME],
    parent: "config-col-visuals",
    options: PREF_OPTS[PREF_IDS.COLORSCHEME]
  },

  {
    type: GUI_TYPES.COL,
    id: "config-col-dates",
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.CONFIGFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: "config-col-dates",
    text: "Dates and times"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.DATE_FORMAT,
    label: PREF_OPTS.INTROS[PREF_IDS.DATE_FORMAT],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.DATE_FORMAT]
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-daylength",
    parent: "config-col-dates"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-daylength",
    text: PREF_OPTS.INTROS[PREF_IDS.DAY_LENGTH_HOURS]
  },
  {
    type: GUI_TYPES.NUMBER_INPUT,
    id: PREF_IDS.DAY_LENGTH_HOURS,
    parent: "config-group-daylength",
    entryType: TYPES.ITEM_DAYLENGTH
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.DAY_INCREMENTS,
    label: PREF_OPTS.INTROS[PREF_IDS.DAY_INCREMENTS],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.DAY_INCREMENTS]
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.DAY_ROUNDING_TYPE,
    label: PREF_OPTS.INTROS[PREF_IDS.DAY_ROUNDING_TYPE],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.DAY_ROUNDING_TYPE]
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-startdate",
    parent: "config-col-dates"
  },
  {
    type: GUI_TYPES.DATEPICKER,
    id: PREF_IDS.YEAR_STARTDATE,
    heading: STRINGS.YEAR_STARTDATE,
    label: PREF_OPTS.INTROS[PREF_IDS.YEAR_STARTDATE],
    parent: "config-group-startdate"
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.WEEK_STARTDAY,
    label: PREF_OPTS.INTROS[PREF_IDS.WEEK_STARTDAY],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.WEEK_STARTDAY]
  },
  {
    type: GUI_TYPES.RADIOBTN,
    id: PREF_IDS.MINUTE_INCREMENTS,
    label: PREF_OPTS.INTROS[PREF_IDS.MINUTE_INCREMENTS],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.MINUTE_INCREMENTS]
  },
  {
    type: GUI_TYPES.CHECKBOXGROUP,
    id: PREF_IDS.WEEKEND_DAYS,
    label: PREF_OPTS.INTROS[PREF_IDS.WEEKEND_DAYS],
    parent: "config-col-dates",
    options: PREF_OPTS[PREF_IDS.WEEKEND_DAYS]
  },


  
  {
    type: GUI_TYPES.COL,
    id: "config-col-export",
    class: CLASSNAMES.SHEET,
    parent: EL_IDS.CONFIGFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: "config-col-export",
    text: "Export data"
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-mail-address",
    parent: "config-col-export"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-mail-address",
    text: PREF_OPTS.INTROS[PREF_IDS.MAILDATA_ADDRESS]
  },
  {
    type: GUI_TYPES.TEXTINPUT,
    id: PREF_IDS.MAILDATA_ADDRESS,
    parent: "config-group-mail-address"
  },


  
  {
    type: GUI_TYPES.COL,
    id: "config-col-sync",
    class: [ CLASSNAMES.SHEET, CLASSNAMES.ATTENTION ],
    parent: "config-col-export",
    //parent: EL_IDS.CONFIGFORM
  },
  {
    type: GUI_TYPES.H1,
    parent: "config-col-sync",
    text: "WebDAV sync"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-col-sync",
    text: "!! WARNING !! THIS INFO INCLUDING YOUR PASSWORD IS STORED IN PLAIN TEXT IN YOUR BROWSER LOCALSTORAGE"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-col-sync",
    text: "You need to set up CORS on your WebDAV server to allow 'dontdillydally.app'. Eg on NextCloud this can be done via the 'WebAppPassword' app."
  },
  {
    type: GUI_TYPES.SPAN,
    id: "config-group-webdav",
    parent: "config-col-sync"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-webdav",
    text: PREF_OPTS.INTROS[PREF_IDS.WEBDAV_ADDRESS]
  },
  {
    type: GUI_TYPES.TEXTINPUT,
    id: PREF_IDS.WEBDAV_ADDRESS,
    parent: "config-group-webdav"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-webdav",
    text: PREF_OPTS.INTROS[PREF_IDS.WEBDAV_USER]
  },
  {
    type: GUI_TYPES.TEXTINPUT,
    id: PREF_IDS.WEBDAV_USER,
    parent: "config-group-webdav"
  },
  {
    type: GUI_TYPES.H2,
    parent: "config-group-webdav",
    text: PREF_OPTS.INTROS[PREF_IDS.WEBDAV_PASS]
  },
  {
    type: GUI_TYPES.TEXTINPUT,
    id: PREF_IDS.WEBDAV_PASS,
    parent: "config-group-webdav"
  },



  HELPITEM_DATA[HELPITEM_IDS.CONFIG_MAIN]
];
