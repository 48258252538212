/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  LoadingIndicator:
  Simple loading bar

---------------------------------------------------------
*/

import { __ } from "./utils.js";

import { Elements } from "./Elements.js";
import { DDD } from "./DDD/CONST.js";

class LoadingIndicator extends HTMLElement {}

LoadingIndicator.setPercentage = function (_percentDone) {
  var scaledPercent = (100 - _percentDone) / LoadingIndicator.CSSWIDTH_SCALE;
  Elements.loadingIndicator.getElementsByTagName("EM")[0].style.paddingRight =
    scaledPercent + "%";
};

LoadingIndicator.show = function () {
  Elements.loadingIndicator.classList.remove(DDD.CLASSNAMES.HIDDEN);
};

LoadingIndicator.hide = function () {
  Elements.loadingIndicator.classList.add(DDD.CLASSNAMES.HIDDEN);
};

LoadingIndicator.onPageLoaded = function () {
  LoadingIndicator.hide();
};

LoadingIndicator.onPageLoadStarted = function () {
  LoadingIndicator.setPercentage(0);
  LoadingIndicator.show();
};

LoadingIndicator.onUpdate = function (_event) {
  LoadingIndicator.setPercentage(_event.detail.percentDone);
};

LoadingIndicator.CSSWIDTH_SCALE = 2; // eg. 2 if 'full' width should be 50% (100/2)

export { LoadingIndicator };
