/* SPDX-License-Identifier: (GPL-3.0-only) */
/* Copyright © 2022 Mark Mayes */

/*
---------------------------------------------------------
  Timers:
  Manage `setTimeout` timers

---------------------------------------------------------
*/

import {__} from './utils.js';

class Timers {}

Timers.setByID = function(_id, ..._timeoutArgs) {
  //__("Timers::setByID()" + _id);
  //__("_timeoutArgs: " + _timeoutArgs);
  Timers[_id] = setTimeout(..._timeoutArgs);
};

Timers.clearByID = function(_id) {
  //__("Timers::clearByID()" + _id);
  clearTimeout(Timers[_id]);
};

export {Timers};
